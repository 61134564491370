import React from "react";
import "./slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import diamondRingImg from "../../images/diamond-ring-image.jpeg";
import diamondRingImg2 from "../../images/diamond-ring-image2.jpeg";
import diamondRingImg3 from "../../images/diamond-ring-image3.jpeg";
import diamondRingImg4 from "../../images/diamond-ring-image4.jpeg";
import { HashLink } from "react-router-hash-link";

const Slider = () => {
  return (
    <div>
      <div className="my-3">
        <div className="container mx-auto">
          <div className="">
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              loop={true}
              slidesPerView={3}
              spaceBetween={15}
              speed={300}
              centeredSlides={true}
              mousewheel
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              breakpoints={{
                1440: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
                767: {
                  slidesPerView: 2,
                },
                425: {
                  slidesPerView: 1,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
              className="swiper-wrapper"
            >
              <SwiperSlide className="">
                <img
                  src={diamondRingImg}
                  className="border border-1 rounded-3"
                  alt=""
                />
              </SwiperSlide>

              <SwiperSlide className="">
                <img
                  src={diamondRingImg2}
                  className="border border-1 rounded-3"
                  alt=""
                />
              </SwiperSlide>

              <SwiperSlide className="">
                <img
                  src={diamondRingImg3}
                  className="border border-1 rounded-3"
                  alt=""
                />
              </SwiperSlide>

              <SwiperSlide className="">
                <img
                  src={diamondRingImg4}
                  className="border border-1 rounded-3"
                  alt=""
                />
              </SwiperSlide>

              <SwiperSlide className="">
                <img
                  src={diamondRingImg}
                  className="border border-1 rounded-3"
                  alt=""
                />
              </SwiperSlide>

              <SwiperSlide className="">
                <img
                  src={diamondRingImg2}
                  className="border border-1 rounded-3"
                  alt=""
                />
              </SwiperSlide>

              <SwiperSlide className="">
                <img
                  src={diamondRingImg3}
                  className="border border-1 rounded-3"
                  alt=""
                />
              </SwiperSlide>

              <SwiperSlide className="">
                <img
                  src={diamondRingImg4}
                  className="border border-1 rounded-3"
                  alt=""
                />
              </SwiperSlide>
            </Swiper>
          </div>

          <div className="order2 text-center mt-3">
            <HashLink smooth to="#orderDelivery">
              <button className="button border rounded text-center py-2 px-4 fs-2 fw-bold">
                অর্ডার করতে চাই
              </button>
            </HashLink>
          </div>

          <div className="sizeBall mt-3 border rounded-4 py-3 px-4">
            <h2 className="text-center">
              অ্যাডজাস্টেবল সাইজ, ছোট বড় করে সব আঙ্গুলে পরা যাবে।
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
