import { useState } from "react";
import axios from "axios";
import * as yup from "yup";
import { Formik, Form as FormikForm } from "formik";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";
import { useApiContext } from "../../context/ApiContext";
import PurchaseProductForm from "./PurchaseProductForm";

const initialValues = {
  purchase_date: "",
  supplier: "",
  total_amount: "",
};

const schema = yup.object().shape({
  purchase_date: yup.string().required("Purchase Date is a required field!"),
  supplier: yup.string().required("Supplier is a required field!"),
  // total_amount: yup.string().required("Total Amount is a required field!"),
});

const validate = (values) => {
  let errors = {};

  return errors;
};

const PurchaseAdd = () => {
  const { unpaginate_supplier, unpaginate_product } = useApiContext();
  const [productList, setProductList] = useState([
    {
      productId: null,
      bdtRate: 0,
      quantity: 0,
      linePrice: 0,
    },
  ]);

  const [message, setMessage] = useState();
  const navigate = useNavigate();

  const totalAmount = productList.reduce(
    (total, product) => total + product.linePrice,
    0
  );

  const AddPurchaseFunc = async (values) => {
    let formfield = new FormData();

    // Convert purchase_details array to a JSON string
    values.purchase_details = JSON.parse(JSON.stringify(productList));
    values.total_amount = totalAmount;

    // Append individual fields
    formfield.append("purchase_date", values.purchase_date);
    formfield.append("supplier", values.supplier);

    // Append purchase_details as separate items in FormData
    values.purchase_details.forEach((detail, index) => {
      formfield.append(
        `purchase_details[${index}][product]`,
        Number(detail.productId)
      );
      formfield.append(`purchase_details[${index}][bdtRate]`, detail.bdtRate);
      formfield.append(`purchase_details[${index}][quantity]`, detail.quantity);
      formfield.append(
        `purchase_details[${index}][linePrice]`,
        detail.linePrice
      );
    });

    // Append individual fields
    formfield.append("total_amount", values.total_amount);

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PURCHASE_URL}`,
      headers: {
        "Content-Type": "application/json; charset=UTF-8; text/plain",
      },
      data: formfield,
    })
      .then((response) => {
        setMessage(response.success, "Purchase is successfuly created...");
        navigate("/purchase");
        window.location.reload(false);
      })
      .catch((error) => {
        setMessage(error.message, "Error");
      });
  };

  const submitPurchaseForm = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      AddPurchaseFunc(values);
      setSubmitting(false);
      // resetForm();
    } catch (error) {
      setErrors({ error: error.message });
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                  <h4 className="mb-sm-0 text-dark">Add Purchase</h4>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={submitPurchaseForm}
                validate={validate}
              >
                {({
                  handleSubmit,
                  handleChange,
                  isSubmitting,
                  values,
                  errors,
                  touched,
                }) => (
                  <FormikForm noValidate onSubmit={(e) => handleSubmit(e)}>
                    <div className="card overflow-hidden">
                      <div className="card-body card-body z-1 position-relative">
                        <div className="row">
                          <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                              <h4 className="mb-sm-0">Purchase Details</h4>
                            </div>
                          </div>
                        </div>

                        <div className="row g-3">
                          <Form.Group className="form-outline mb-4 col-lg-6">
                            <Form.Label>
                              Purchase Date<span>*</span>
                            </Form.Label>
                            <InputGroup hasValidation>
                              {/* <InputGroup.Text>@</InputGroup.Text> */}
                              <Form.Control
                                type="date"
                                name="purchase_date"
                                id="purchase_date"
                                value={values.purchase_date}
                                onChange={handleChange}
                                isInvalid={
                                  !!touched.purchase_date &&
                                  !!errors.purchase_date
                                }
                                isValid={
                                  touched.purchase_date && !errors.purchase_date
                                }
                                className="form-control"
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.purchase_date}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>

                          <Form.Group className="form-outline mb-4 col-lg-6">
                            <Form.Label>
                              Supplier<span>*</span>
                            </Form.Label>
                            <InputGroup hasValidation>
                              {/* <InputGroup.Text>@</InputGroup.Text> */}
                              <Form.Select
                                name="supplier"
                                id="supplier"
                                value={values.supplier}
                                onChange={handleChange}
                                isInvalid={
                                  !!touched.supplier && !!errors.supplier
                                }
                                isValid={touched.supplier && !errors.supplier}
                                className="form-control"
                              >
                                <option value="">Select</option>
                                {unpaginate_supplier &&
                                  unpaginate_supplier.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.supplier}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <PurchaseProductForm
                      productList={productList}
                      setProductList={setProductList}
                      unpaginate_product={unpaginate_product}
                    />

                    <div className="row d-flex align-items-center justify-content-end">
                      <Form.Group className="form-outline mb-4 col-lg-3">
                        <Form.Label>
                          Total Amount<span>*</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          {/* <InputGroup.Text>@</InputGroup.Text> */}
                          <Form.Control
                            type="text"
                            name="total_amount"
                            id="total_amount"
                            value={totalAmount}
                            onChange={handleChange}
                            isInvalid={
                              !!touched.total_amount && !!errors.total_amount
                            }
                            isValid={
                              touched.total_amount && !errors.total_amount
                            }
                            readOnly
                            className="form-control"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.total_amount}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </div>

                    <div className="hstack gap-2 flex-wrap justify-content-end d-print-none my-4">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn btn-success"
                      >
                        <i className="ri-printer-line align-bottom me-1"></i>
                        Save
                      </button>

                      {/* message  */}
                      {message && (
                        <h2 className="text-center m-5">{message}</h2>
                      )}
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseAdd;
