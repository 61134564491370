import React from "react";
import "./phone.css";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const Phone = () => {
  return (
    <>
      <div className="mb-3">
        <div className="container mx-auto">
          <div className="d-flex flex-column justify-content-between align-items-center">
            <div className="text-center fw-bold mb-3">
              <h2 className="fw-bold fs-1">প্রয়োজনে কল করুন</h2>
            </div>

            <div className="phone fw-bold d-flex  justify-content-center align-items-center mb-3">
              <div className="">
                <FaPhoneAlt className="phone-icon" />
              </div>
              <div className="">
                <Link to="tel:01760405645">
                  <h2 className="phone-text text-white">01760405645</h2>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Phone;
