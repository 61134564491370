import { useApiContext } from "../../context/ApiContext";
import CustomerTable from "./CustomerTable";

const Customers = () => {
  const { customer } = useApiContext();

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <div className="mx-3 my-2 page-title-box d-sm-flex align-items-center justify-content-start">
                  <h4 className="mb-sm-0">Customer</h4>
                </div>
              </div>

              <div className="col-6">
                <div className="d-flex mx-3 my-2  align-items-center justify-content-end">
                  <button
                    className="btn btn-secondary mb-4"
                    data-bs-toggle="modal"
                    data-bs-target="#CourierAddModal"
                    disabled
                  >
                    <i className="bi bi-plus-circle align-baseline me-1"></i>{" "}
                    Add Customer
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <CustomerTable customer={customer} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;
