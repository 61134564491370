import { Link } from "react-router-dom";
import DeleteBtnModal from "../../components/DeleteBtnModal";
import { useApiContext } from "../../context/ApiContext";
import PurchaseTable from "./PurchaseTable";

const Purchase = () => {
  const { purchase } = useApiContext();

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <div className="mx-3 my-2 page-title-box d-sm-flex align-items-center justify-content-start">
                  <h4 className="mb-sm-0">Purchase</h4>
                </div>
              </div>

              <div className="col-6">
                <div className="d-flex mx-3 my-2  align-items-center justify-content-end">
                  <Link to="/purchase-add">
                    <button className="btn btn-secondary mb-4">
                      <i className="bi bi-plus-circle align-baseline me-1"></i>{" "}
                      Add Purchase
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <PurchaseTable purchase={purchase} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DeleteBtnModal id={`PurchaseDeleteModal`} />
    </>
  );
};

export default Purchase;
