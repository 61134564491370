const CartReducer = (state, action) => {
  if (action.type === "ADD_TO_CART") {
    let { id, amount, product } = action.payload;

    // tackle the existing product
    let existingProduct = state.cart.find((curItem) => curItem.id === id);

    if (existingProduct) {
      let updatedProduct = state.cart.map((curElem) => {
        if (curElem.id === id) {
          let newAmount = curElem.amount + amount;

          if (newAmount >= curElem.max) {
            newAmount = curElem.max;
          }

          return {
            ...curElem,
            amount: newAmount,
          };
        } else {
          return {
            ...curElem,
          };
        }
      });

      return {
        ...state,
        cart: updatedProduct,
      };
    } else {
      let cartProduct = {
        id: id,
        name: product.name,
        amount: amount,
        image: product.image,
        price: Number(product.selling_price),
        max: Number(product.initial_stock),
        discount: Number(product.discount),
      };

      return {
        ...state,
        cart: [...state.cart, cartProduct],
      };
    }
  }

  if (action.type === "SET_DECREMENT") {
    let updatedProduct = state.cart.map((curElem) => {
      if (curElem.id === action.payload) {
        let decAmount = curElem.amount - 1;

        if (decAmount <= 1) {
          decAmount = 1;
        }

        return {
          ...curElem,
          amount: decAmount,
        };
      } else {
        return curElem;
      }
    });
    return {
      ...state,
      cart: updatedProduct,
    };
  }

  if (action.type === "SET_INCREMENT") {
    let updatedProduct = state.cart.map((curElem) => {
      if (curElem.id === action.payload) {
        let incAmount = curElem.amount + 1;

        if (incAmount >= curElem.max) {
          incAmount = curElem.max;
        }

        return {
          ...curElem,
          amount: incAmount,
        };
      } else {
        return curElem;
      }
    });
    return {
      ...state,
      cart: updatedProduct,
    };
  }

  if (action.type === "CART_TOTAL_ITEM") {
    let updatedItemVal = state.cart.reduce((initialVal, curElem) => {
      let { amount } = curElem;
      initialVal = initialVal + amount;
      return initialVal;
    }, 0);

    return {
      ...state,
      total_item: updatedItemVal,
    };
  }

  if (action.type === "CART_TOTAL_PRICE") {
    let updatedItemPrice = state.cart.reduce((initialVal, curElem) => {
      let { price, amount } = curElem;
      initialVal = initialVal + price * amount;
      return initialVal;
    }, 0);

    return {
      ...state,
      total_price: updatedItemPrice,
    };
  }

  if (action.type === "CART_DISCOUNT") {
    let updatedDiscount = state.cart.reduce((initialVal, curElem) => {
      let { discount, amount } = curElem;
      initialVal = initialVal + discount * amount;
      return initialVal;
    }, 0);

    return {
      ...state,
      total_discount: updatedDiscount,
    };
  }

  if (action.type === "REMOVE_ITEM") {
    let updatedCart = state.cart.filter(
      (curElem) => curElem.id !== action.payload
    );

    return {
      ...state,
      cart: updatedCart,
    };
  }

  if (action.type === "CLEAR_CART") {
    return {
      ...state,
      cart: [],
    };
  }

  // order
  if (action.type === "ADD_TO_ORDER") {
    let { id, amount, product } = action.payload;
    // Always create a new order object with the current item
    let orderProduct = {
      id: id,
      name: product.name,
      amount: amount,
      image: product.image,
      price: Number(product.selling_price),
      max: Number(product.initial_stock),
      discount: Number(product.discount),
    };
    return {
      ...state,
      order: orderProduct,
    };
  }

  if (action.type === "ORDER_TOTAL_PRICE") {
    let totalItemPrice = 0;
    const { price, amount } = state.order;
    totalItemPrice += price * amount;
    return {
      ...state,
      order_total_price: totalItemPrice,
    };
  }

  if (action.type === "ORDER_DISCOUNT") {
    let totalDiscount = 0;
    const { discount, amount } = state.order;
    totalDiscount += discount * amount;
    return {
      ...state,
      order_total_discount: totalDiscount,
    };
  }

  if (action.type === "CLEAR_ORDER") {
    // Clear the order data
    return {
      ...state,
      order: {},
    };
  }

  return state;
};

export default CartReducer;
