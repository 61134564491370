import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { throttle } from "lodash";
import { useApiContext } from "../../context/ApiContext";
import jsPDF from "jspdf";

const OrderTable = ({ order, updateOrder }) => {
  const { unpaginate_courier } = useApiContext();
  const [courierList, setCourierList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [search, setSearch] = useState("");
  const [filterOrder, setFilterOrder] = useState("");

  useEffect(() => {
    setCourierList(unpaginate_courier);
  }, [unpaginate_courier]);

  useEffect(() => {
    const result =
      order &&
      order.filter((data) => {
        const nameMatch = data.order_no
          .toLowerCase()
          .includes(search.toLowerCase());
        const idMatch = String(data.id).includes(search.toLowerCase());
        return nameMatch || idMatch;
      });
    setFilterOrder(result);
  }, [order, search]);

  const handleSelectedRowsChange = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const colums = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Order Number",
      selector: (row) => (
        <p style={{ whiteSpace: "pre-line" }}>{row.order_no}</p>
      ),
      sortable: true,
    },
    {
      name: "Customer Info",
      selector: (row) => (
        <div className="p-2">
          <h5 style={{ whiteSpace: "pre-line" }}>{row.c_name}</h5>
          <h6 style={{ whiteSpace: "pre-line" }}>{row.c_number}</h6>
          <p style={{ whiteSpace: "pre-line" }}>{row.c_address}</p>
        </div>
      ),
    },
    {
      name: "Products Info",
      selector: (row) =>
        row.order_details &&
        row.order_details.map((p, index) => {
          return (
            <div
              className="d-flex flex-column justify-content-start align-items-center"
              key={index}
            >
              <img
                src={
                  p.product.image &&
                  `${process.env.REACT_APP_BASE_URL}${p.product.image.replace(
                    "/server/",
                    ""
                  )}`
                }
                alt="p-img"
                height="50"
                width="50"
                style={{ borderRadius: "50%", margin: "10px" }}
              />
              <p style={{ whiteSpace: "pre-line" }}>{p.product.name}</p>
            </div>
          );
        }),
    },

    {
      name: "Amount",
      selector: (row) => (
        <div className="">
          <p
            style={{
              fontSize: "10px",
              fontWeight: "800",
              whiteSpace: "pre-line",
            }}
          >
            Sub Total : {row.total_amount}
          </p>
          <p
            style={{
              fontSize: "10px",
              fontWeight: "800",
              whiteSpace: "pre-line",
            }}
          >
            Discount : {row.discount}
          </p>
          <p
            style={{
              fontSize: "10px",
              fontWeight: "800",
              whiteSpace: "pre-line",
            }}
          >
            Payable Amount : {row.payable_amount}
          </p>
          <p
            style={{
              fontSize: "10px",
              fontWeight: "800",
              whiteSpace: "pre-line",
            }}
          >
            Paid Amount : {row.paid_amount}
          </p>
          <p
            style={{
              fontSize: "10px",
              fontWeight: "800",
              whiteSpace: "pre-line",
            }}
          >
            Due Amount : {row.due_amount}
          </p>
          <p
            style={{
              fontSize: "10px",
              fontWeight: "800",
              whiteSpace: "pre-line",
            }}
          >
            Delivery Charge : {row.delivery_charge}
          </p>
          <p
            style={{
              fontSize: "10px",
              fontWeight: "800",
              whiteSpace: "pre-line",
            }}
          >
            Total : {row.grand_total_amount}
          </p>
        </div>
      ),
    },
    {
      name: "Activities",
      selector: (row) => (
        <div className="p-2">
          <p
            style={{
              fontSize: "10px",
              fontWeight: "600",
              whiteSpace: "pre-line",
            }}
          >
            Date : {row.order_date}
          </p>
          <p
            style={{
              fontSize: "11px",
              fontWeight: "600",
              whiteSpace: "pre-line",
            }}
          >
            Created By : {}
          </p>
          <p
            style={{
              fontSize: "11px",
              fontWeight: "600",
              whiteSpace: "pre-line",
            }}
          >
            Approved By : {}
          </p>
          <p
            style={{
              fontSize: "12px",
              border: "1px solid black",
              padding: "5px",
              backgroundColor: "black",
              color: "white",
              borderRadius: "10px",
              whiteSpace: "pre-line",
            }}
          >
            Courier : {row.courier && row.courier.name}
          </p>
          <p
            style={{
              fontSize: "12px",
              border: "1px solid black",
              padding: "5px",
              backgroundColor: "black",
              color: "white",
              borderRadius: "10px",
              whiteSpace: "pre-line",
            }}
          >
            Status : {row.delivery_status}
          </p>
          <p
            style={{
              fontSize: "10px",
              border: "1px solid black",
              padding: "5px",
              backgroundColor: "black",
              color: "white",
              borderRadius: "10px",
              whiteSpace: "pre-line",
            }}
          >
            Type : {row.delivery_type && row.delivery_type.name}
          </p>
        </div>
      ),
    },
    {
      name: "Note",
      selector: (row) =>
        row.note ? (
          <div style={{ whiteSpace: "pre-line" }}>{row.note}</div>
        ) : (
          "-"
        ),
    },
    {
      name: "Action",
      selector: (row) => (
        <ul className="d-flex gap-2 list-unstyled mb-0">
          <li>
            <Link
              to={`/order-overview/${row.id}`}
              className="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn"
            >
              <i className="ph-eye"></i>
            </Link>
          </li>
          <li>
            <Link
              to="#OrderUpdateModal"
              data-bs-toggle="modal"
              className="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn"
              onClick={() => updateOrder(row.id)}
            >
              <i className="ph-pencil"></i>
            </Link>
          </li>
          <li>
            <Link
              to="#OrderDeleteModal"
              data-bs-toggle="modal"
              className="btn btn-subtle-danger btn-icon btn-sm remove-item-btn"
            >
              <i className="ph-trash"></i>
            </Link>
          </li>
        </ul>
      ),
    },
  ];

  // print order invoice
  const printInvoice = () => {
    const invoiceContents = generateInvoiceContent(selectedRows);
    const combinedInvoiceContent = invoiceContents.join("");

    const printWindow = window.open("", "_blank");
    if (printWindow) {
      printWindow.document.write(combinedInvoiceContent);
      printWindow.document.close();
      printWindow.print();
    } else {
      console.error(
        "Unable to open print window. Please check your pop-up blocker settings."
      );
    }
  };

  const handlePrintInvoice = throttle(() => {
    printInvoice();
  }, 1000);

  const generateInvoiceContent = (selectedRows) => {
    // CSS styling
    const style = `
      <style>
        .invoice {
          padding: 15px;
          font-family: Arial, sans-serif;
        }
        .details-container {
          display: flex;
          justify-content: space-evenly;
          padding : 15px;
          border : 1px solid black;
        }
        .details-column {
          flex: 1;
        }
        .order-info-container{
          margin-top: 50px;
          border : 1px solid black;
          display: flex;
          flex-direction : column;
          justify-content: center;
          align-items : center;
        }
        .order-info{
          display: flex;
          justify-content: space-between;
          align-items : center;
          gap : 25px;
        }
        .order-info-details{
          display: flex;
          flex-direction : column;
          justify-content: center;
          align-items : center;
        }
        .order-details{
          margin-top : 50px;
          margin-bottom : 50px;
        }
        .order-details table {
          width: 100%;
          border-collapse: collapse;
        }
        .order-details th, .order-details td {
          border: 1px solid #ccc;
          padding: 10px;
          text-align: center;
        }
      </style>
    `;

    let invoiceContents = [];

    selectedRows.forEach((rowItem, index) => {
      let invoiceContent = "";

      // Construct HTML content for the current invoice
      invoiceContent += `<div class="invoice">`;

      // Parent div for company and customer details
      invoiceContent += `<div class="details-container">`;

      // Customer Details
      invoiceContent += `<div class="details-column">`;
      invoiceContent += `<h2>Customer Details</h2>`;
      invoiceContent += `<p><strong>Customer Name:</strong> ${rowItem.c_name}</p>`;
      invoiceContent += `<p><strong>Customer Address:</strong> ${rowItem.c_address}</p>`;
      invoiceContent += `<p><strong>Phone:</strong> ${rowItem.c_number}</p>`;
      invoiceContent += `</div>`;

      // Company Details
      invoiceContent += `<div class="details-column">`;
      invoiceContent += `<h2>Company Details</h2>`;
      invoiceContent += `<p><strong>Company Name:</strong> Your Company Name</p>`;
      invoiceContent += `<p><strong>Address:</strong> Your Company Address</p>`;
      invoiceContent += `<p><strong>Phone:</strong> Your Company Phone</p>`;
      invoiceContent += `</div>`;

      // Close details-container div
      invoiceContent += `</div>`;

      // Order Information
      invoiceContent += `<div class="order-info-container">`;
      invoiceContent += `<h2>Order Information</h2>`;
      invoiceContent += `<div class="order-info">`;

      invoiceContent += `<div class="order-info-details">`;
      invoiceContent += `<p><strong>Order Number</strong></p>`;
      invoiceContent += `<p>${rowItem.order_no}</p>`;
      invoiceContent += `</div>`;

      invoiceContent += `<div class="order-info-details">`;
      invoiceContent += `<p><strong>Order Date</strong></p>`;
      invoiceContent += `<p>${rowItem.order_date}</p>`;
      invoiceContent += `</div>`;

      invoiceContent += `<div class="order-info-details">`;
      invoiceContent += `<p><strong>Payment Method</strong></p>`;
      invoiceContent += `<p>${rowItem.payment_method}</p>`;
      invoiceContent += `</div>`;

      invoiceContent += `<div class="order-info-details">`;
      invoiceContent += `<p><strong>Delivery Status</strong></p>`;
      invoiceContent += `<p>${rowItem.delivery_status}</p>`;
      invoiceContent += `</div>`;

      invoiceContent += `<div class="order-info-details">`;
      invoiceContent += `<p><strong>Delivery Type</strong></p>`;
      invoiceContent += `<p>${
        rowItem.delivery_type && rowItem.delivery_type.name
      }</p>`;
      invoiceContent += `</div>`;
      invoiceContent += `</div>`;
      invoiceContent += `</div>`;

      // Order Details
      invoiceContent += `<div class="order-details">`;
      invoiceContent += `<h2>Order Details</h2>`;
      invoiceContent += `<table>`;
      invoiceContent += `<thead><tr><th>Product Name</th><th>Price</th><th>Quantity</th><th>Total</th></tr></thead>`;
      invoiceContent += `<tbody>`;

      rowItem.order_details.forEach((orderDetail) => {
        invoiceContent += `<tr>`;
        invoiceContent += `<td>${orderDetail.product.name}</td>`;
        invoiceContent += `<td>${orderDetail.bdtRate}</td>`;
        invoiceContent += `<td>${orderDetail.quantity}</td>`;
        invoiceContent += `<td>${orderDetail.linePrice}</td>`;
        invoiceContent += `</tr>`;
      });

      invoiceContent += `</tbody>`;
      invoiceContent += `</table>`;
      invoiceContent += `</div>`;

      // Payment Information
      invoiceContent += `<div class="order-info-container">`;
      invoiceContent += `<h2>Payment Information</h2>`;
      invoiceContent += `<div class="order-info">`;

      invoiceContent += `<div class="order-info-details">`;
      invoiceContent += `<p><strong>Sub Total</strong></p>`;
      invoiceContent += `<p>${rowItem.total_amount}</p>`;
      invoiceContent += `</div>`;

      invoiceContent += `<div class="order-info-details">`;
      invoiceContent += `<p><strong>Discount</strong></p>`;
      invoiceContent += `<p>${rowItem.discount}</p>`;
      invoiceContent += `</div>`;

      invoiceContent += `<div class="order-info-details">`;
      invoiceContent += `<p><strong>Payable</strong></p>`;
      invoiceContent += `<p>${rowItem.payable_amount}</p>`;
      invoiceContent += `</div>`;

      invoiceContent += `<div class="order-info-details">`;
      invoiceContent += `<p><strong>Paid</strong></p>`;
      invoiceContent += `<p>${rowItem.paid_amount}</p>`;
      invoiceContent += `</div>`;

      invoiceContent += `<div class="order-info-details">`;
      invoiceContent += `<p><strong>Due</strong></p>`;
      invoiceContent += `<p>${rowItem.due_amount}</p>`;
      invoiceContent += `</div>`;

      invoiceContent += `<div class="order-info-details">`;
      invoiceContent += `<p><strong>D Charge</strong></p>`;
      invoiceContent += `<p>${rowItem.delivery_charge}</p>`;
      invoiceContent += `</div>`;

      invoiceContent += `<div class="order-info-details">`;
      invoiceContent += `<p><strong>Total</strong></p>`;
      invoiceContent += `<p>${rowItem.grand_total_amount}</p>`;
      invoiceContent += `</div>`;

      invoiceContent += `</div>`;

      invoiceContent += `</div>`; // Close invoice div

      // Add page break before each invoice except the first one
      if (index !== 0) {
        invoiceContent =
          `<div style="page-break-before: always;"></div>` + invoiceContent;
      }

      // Push the HTML content of the current invoice into the array
      invoiceContents.push(invoiceContent);
    });

    // Return the combined style and invoice contents
    return [style, ...invoiceContents];
  };

  // download cousier csv
  const generateCourierCSVContent = (selectedCourier) => {
    if (!selectedRows || selectedRows.length === 0 || !selectedCourier) {
      console.error("No selected rows or courier to generate CSV content.");
      return "";
    }

    // Determine the CSV content based on the selected courier
    if (selectedCourier.id === 1) {
      return generateCSVType1(selectedCourier);
    } else if (selectedCourier.id === 2) {
      return generateCSVType2(selectedCourier);
    } else {
      console.error("Unsupported courier type for CSV generation.");
      return "";
    }
  };

  const generateCSVType1 = (selectedCourier) => {
    let csvContent =
      "Invoice,Name,Address,Phone,Amount,Note,Contact Name,Contact Phone\n";

    selectedRows.forEach((row) => {
      if (row.courier && row.courier.id === selectedCourier.id) {
        csvContent += `${row.order_no},${row.customer && row.customer.name},"${
          row.customer && row.customer.address.replaceAll(",", " ")
        }",${row.customer && row.customer.email},${row.total_amount},${
          row.note
        },Naturiko,01777777777\n`;
      }
    });

    return csvContent;
  };

  const generateCSVType2 = (selectedCourier) => {
    let csvContent =
      "ItemType,StoreName,MerchanOrderId,RecipientName,RecipientPhone,RecipientCity,RecipientZone,RecipientArea,RecipientAddress,AmountToCollect,ItemQuantity,ItemWeight,ItemDesc,SpecialInstruction\n";

    selectedRows.forEach((row) => {
      if (row.courier && row.courier.id === selectedCourier.id) {
        csvContent += `ItemType,Naturiko,${row.order_no},${
          row.customer && row.customer.name
        },${
          row.customer && row.customer.email
        },RecipientCity,RecipientZone,RecipientArea,${
          row.customer && row.customer.address.replaceAll(",", " ")
        },${row.total_amount},ItemQuantity,ItemWeight,ItemDesc,${row.note}\n`;
      }
    });

    return csvContent;
  };

  const downloadCourierCSV = async (selectedCourier) => {
    // Generate CSV content based on the selected courier
    const csvContent = generateCourierCSVContent(selectedCourier);

    if (!csvContent) {
      console.error("No CSV content to download.");
      return;
    }

    // Create blob and download CSV file
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `Courier-${selectedCourier.name}-${new Date().toLocaleDateString(
        "en-GB"
      )}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCourierDropdownClick = async (courier) => {
    // Download CSV file directly with the selected courier
    await downloadCourierCSV(courier);
  };

  // pdf for picklist
  const generatePicklistContent = (row) => {
    // Create a new instance of jsPDF
    const pdf = new jsPDF();

    // Set background color for header
    pdf.setFillColor("#4CAF50");
    pdf.rect(10, 10, 190, 15, "F");

    // Header Text
    pdf.setTextColor("#FFFFFF");
    pdf.setFontSize(20);
    pdf.text("Order Picklist", 70, 20);

    // Add content to the PDF document with styling
    pdf.setTextColor("#333333");
    pdf.setFontSize(16);
    pdf.text("Order No:", 20, 40);
    pdf.setFont("helvetica", "bold");
    pdf.text(`${row.order_no}`, 70, 40);
    pdf.setFont("helvetica", "normal");
    pdf.text("Name:", 20, 50);
    pdf.setFont("helvetica", "bold");
    pdf.text(`${row.c_name}`, 70, 50);
    pdf.setFont("helvetica", "normal");
    pdf.text("Address:", 20, 60);
    pdf.setFont("helvetica", "italic");
    pdf.text(`${row.c_address}`, 70, 60);
    pdf.setFont("helvetica", "normal");
    pdf.text("Phone:", 20, 70);
    pdf.setFont("helvetica", "bolditalic");
    pdf.text(`${row.c_number}`, 70, 70);
    pdf.setFont("helvetica", "normal");
    pdf.text("Payment Method:", 20, 80);
    pdf.setFont("helvetica", "bold");
    pdf.text(`${row.payment_method}`, 70, 80);
    pdf.setFont("helvetica", "normal");
    pdf.text("Note:", 20, 90);
    pdf.setFont("helvetica", "bold");
    pdf.text(`${row.note ? row.note : "-"}`, 70, 90);

    // Return the PDF document
    return pdf;
  };

  const downloadPDF = () => {
    selectedRows.forEach((row) => {
      const pdf = generatePicklistContent(row);
      pdf.save(`Picklist-${row.order_no}.pdf`);
    });
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between mb-2 mobile">
        <div className="mb-2 mb-md-0">
          <input
            type="text"
            placeholder="Search here"
            className="w-100 form-control mb-2"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="btn-group flex-column flex-md-row">
          <button
            className="btn btn-primary mb-2 mb-md-0 mx-2"
            onClick={handlePrintInvoice}
            disabled={selectedRows.length === 0}
          >
            Print Invoice
          </button>

          <button
            className="btn btn-primary mb-2 mb-md-0 mx-2"
            onClick={downloadPDF}
            disabled={selectedRows.length === 0}
          >
            Picklist
          </button>

          <button
            className="btn btn-primary dropdown-toggle  mb-2 mb-md-0 mx-2"
            type="button"
            id="courierDropdownButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            disabled={selectedRows.length === 0}
          >
            Download Courier CSV
          </button>
          <ul
            className="dropdown-menu w-100"
            aria-labelledby="courierDropdownButton"
          >
            {courierList.map((courier) => (
              <li key={courier.id}>
                <button
                  className="dropdown-item text-danger"
                  onClick={() => handleCourierDropdownClick(courier)}
                  type="button"
                >
                  {courier.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <DataTable
        columns={colums}
        data={filterOrder}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="450px"
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        subHeader
        subHeaderComponent={null}
        subHeaderAlign="left"
        onSelectedRowsChange={handleSelectedRowsChange}
      />
    </>
  );
};

export default OrderTable;
