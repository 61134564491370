import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import BarcodeGenerator from "../../components/BarcodeGenerator";

const PurchaseOverview = () => {
  const [item, setItem] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const loadProducts = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PURCHASE_URL}${id}/`
      );
      setItem(data);
    };

    loadProducts();
  }, [id]);

  // for time
  const dateString = item.created_at;
  const dateObject = new Date(dateString);
  const timeString = dateObject.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Purchase Overview</h4>
                </div>
              </div>

              <div className=" d-flex align-items-center mt-0  justify-content-end col-6 mb-4">
                <div className="flex-shrink-0 ">
                  <a href="##" className="btn btn-primary">
                    <i className="ph-download-simple align-middle me-1"></i>{" "}
                    Invoice
                  </a>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body d-flex align-items-center flex-wrap gap-3">
                    <div className="flex-grow-1">
                      <p className="text-muted mb-2">Purchase Number</p>
                      <h6 className="fs-md mb-0">{item.purchase_no}</h6>
                    </div>

                    <div className="flex-shrink-0 text-end">
                      <h6 className="fs-md mb-2">
                        {item.purchase_date}
                        <i className="bi bi-calendar4-event align-baseline ms-1"></i>
                      </h6>

                      <p className="text-muted mb-0">
                        {timeString}
                        <i className="bi bi-clock align-baseline ms-1"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-6 col-md-6">
                <div className="card border-bottom border-2 border-light">
                  <div className="card-body d-flex gap-3">
                    <div className="flex-grow-1">
                      <h6 className="card-title mb-3">Supplier Info</h6>
                      <p className="fw-medium fs-md mb-1">
                        {item.supplier && item.supplier.name}
                      </p>
                      <p className="text-muted mb-1">
                        {item.supplier && item.supplier.phone}
                      </p>
                      <p className="text-muted mb-0">
                        {item.supplier && item.supplier.address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-6 col-md-6">
                <div className="card border-bottom border-2 border-light">
                  <div className="card-body d-flex gap-3">
                    <div className="flex-grow-1">
                      <h6 className="card-title mb-3">Payment Info</h6>
                      <p className="fw-medium fs-md mb-1">
                        Purchase Number: <b>{item.purchase_no}</b>
                      </p>
                      <p className="text-muted mb-1">
                        Invoice Number: <b>{item.invoice_no}</b>
                      </p>
                      <p className="text-muted mb-0">
                        {/* Payment Method: <b>{item.payment_method}</b> */}
                        &nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-9">
                <div className="card">
                  <div className="card-header d-flex align-items-center gap-3">
                    <h6 className="card-title mb-0 flex-grow-1">
                      Product Items
                    </h6>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-borderless">
                        <thead className="table-active">
                          <tr>
                            <th>Products</th>
                            <th>Item Price</th>
                            <th>Quantity</th>
                            <th className="text-end">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.purchase_details &&
                            item.purchase_details.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <div className="product-item d-flex align-items-center gap-2">
                                      <div className="avatar-sm flex-shrink-0">
                                        <div className="avatar-title bg-light rounded">
                                          <img
                                            src={
                                              item.product.image &&
                                              `${
                                                process.env.REACT_APP_BASE_URL
                                              }${item.product.image.replace(
                                                "/server/",
                                                ""
                                              )}`
                                            }
                                            alt={item.product.name}
                                            className="avatar-xs"
                                          />
                                        </div>
                                      </div>

                                      <div className="flex-grow-1">
                                        <h6 className="fs-md">
                                          {item.product.name}
                                        </h6>

                                        <p className="text-muted mb-0">
                                          #{item.product.id}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.bdtRate}</td>
                                  <td>{item.quantity}</td>
                                  <td className="fw-medium text-end">
                                    {item.linePrice}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="row gy-3">
                      <div className="col-sm-6 col-lg-6 col-xl-3">
                        <div className="text-center border border-dashed p-3 rounded">
                          <p className="text-muted mb-2">Purchase No</p>
                          <h6 className="fs-md mb-0">{item.purchase_no}</h6>
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-6 col-xl-3">
                        <div className="text-center border border-dashed p-3 rounded">
                          <p className="text-muted mb-2">Purchase Date</p>
                          <h6 className="fs-md mb-0">{item.purchase_date}</h6>
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-6 col-xl-3">
                        <div className="text-center border border-dashed p-3 rounded">
                          <p className="text-muted mb-2">Purchase Time</p>
                          <h6 className="fs-md mb-0">{timeString}</h6>
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-6 col-xl-3">
                        <div className="text-center border border-dashed p-3 rounded">
                          <p className="text-muted mb-2">Purchase Amount</p>
                          <h6 className="fs-md mb-0">{item.total_amount}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3">
                <div className="row">
                  <div className="col-xxl-12 col-lg-6">
                    <div className="card">
                      <div className="card-header">
                        <h6 className="card-title mb-0">Total Order Amount</h6>
                      </div>
                      <div className="card-body pt-4">
                        <div className="table-responsive table-card">
                          <table className="table table-borderless mb-0">
                            <tbody>
                              <tr>
                                <td>Sub Total :</td>
                                <td className="text-end">
                                  {item.total_amount}
                                </td>
                              </tr>
                              <tr>
                                <td>Discount :</td>
                                <td className="text-end">{}</td>
                              </tr>
                              <tr>
                                <td>Payable Amount :</td>
                                <td className="text-end">{}</td>
                              </tr>
                              <tr>
                                <td>Paid Amount :</td>
                                <td className="text-end">{}</td>
                              </tr>
                              <tr>
                                <td>Due Amount :</td>
                                <td className="text-end">{}</td>
                              </tr>
                              <tr className="border-top border-top-dashed">
                                <th scope="row">Total (BDT) :</th>
                                <th className="text-end">
                                  {item.total_amount}
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-12 col-lg-6">
                    <div className="card">
                      <div className="card-body border-top border-dashed">
                        <div>
                          <h6 className="text-muted float-end mb-0">
                            {item.purchase_no}
                          </h6>

                          <p className="text-muted mb-2 mb-md-0">
                            Scan barcode to track
                          </p>

                          <div className="text-center p-3 pb-0">
                            <BarcodeGenerator value={item.purchase_no} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseOverview;
