/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";

const InvoiceList = () => {
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Invoice List</h4>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row">
              <div className="col-xl-7">
                <div className="card">
                  <div className="card-body">
                    <div className="row g-4">
                      <div className="col-lg-3 col-sm-6">
                        <div className="border-end-sm">
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-primary border border-primary-subtle rounded-circle">
                                <i className="bi bi-file-earmark-text"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Total Invoices</p>
                            </div>
                          </div>
                          <h3 className="mb-0">
                            <span className="counter-value" data-target="8956">
                              0
                            </span>
                            <small className="text-success fs-xs fw-normal ms-1">
                              <i className="bi bi-arrow-up align-baseline"></i>{" "}
                              12.09%
                            </small>
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div className="border-end-lg">
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-success border border-success-subtle rounded-circle">
                                <i className="bi bi-patch-check-fill"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Paid Invoices</p>
                            </div>
                          </div>
                          <h3 className="mb-0">
                            <span className="counter-value" data-target="4519">
                              0
                            </span>
                            <small className="text-success fs-xs fw-normal ms-1">
                              <i className="bi bi-arrow-up align-baseline"></i>{" "}
                              6.57%
                            </small>
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div className="border-end-sm">
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-warning border border-warning-subtle rounded-circle">
                                <i className="bi bi-clock-history"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Pending Invoices
                              </p>
                            </div>
                          </div>
                          <h3 className="mb-0">
                            <span className="counter-value" data-target="2648">
                              0
                            </span>
                            <small className="text-success fs-xs fw-normal ms-1">
                              <i className="bi bi-arrow-up align-baseline"></i>{" "}
                              4.07%
                            </small>
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div>
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-danger border border-danger-subtle rounded-circle">
                                <i className="bi bi-file-earmark-text"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Overdue Invoices
                              </p>
                            </div>
                          </div>
                          <h3 className="mb-0">
                            <span className="counter-value" data-target="871">
                              0
                            </span>
                            <small className="text-danger fs-xs fw-normal ms-1">
                              <i className="bi bi-arrow-down align-baseline"></i>{" "}
                              3.49%
                            </small>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--end card--> */}
              </div>
              {/* <!--end col--> */}
              <div className="col-xl-5">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex mb-4 pb-1">
                      <div className="flex-grow-1">
                        <h6 className="card-title">
                          Total Receivables{" "}
                          <i
                            className="bi bi-exclamation-circle align-baseline ms-1 fs-sm"
                            data-bs-toggle="tooltip"
                            data-bs-title="Once you send an invoice (or bill), it becomes part of your accounts receivable – until it's paid."
                          ></i>
                        </h6>
                        <p className="text-muted mb-0">
                          <b>$985.32k</b> Total unpaid invoices
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <a
                          href="apps-ecommerce-seller-overview.html"
                          className="link-effect"
                        >
                          View Profile
                          <i className="bi bi-arrow-right align-baseline ms-1"></i>
                        </a>
                      </div>
                    </div>
                    <div
                      className="progress"
                      data-bs-toggle="tooltip"
                      data-bs-title="$234.95 Paid Amount"
                    >
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: " 75%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--end row--> */}

            <div className="row" id="invoiceList">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center g-2">
                      <div className="col-lg-3 me-auto">
                        <h6 className="card-title mb-0">Invoices List</h6>
                      </div>
                      {/* <!--end col--> */}
                      <div className="col-xl-2 col-md-3">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search for invoice, date, client or something..."
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>
                      {/* <!--end col--> */}
                      <div className="col-md-auto">
                        <div className="hstack gap-2">
                          <button
                            className="btn btn-subtle-danger d-none"
                            id="remove-actions"
                            onClick="deleteMultiple()"
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </button>
                          <Link to="/invoice-add" className="btn btn-secondary">
                            <i className="bi bi-plus-circle align-baseline me-1"></i>{" "}
                            Add Invoice
                          </Link>
                        </div>
                      </div>
                      {/* <!--end col--> */}
                    </div>
                    {/* <!--end row--> */}
                  </div>
                  <div className="card-body mt-3">
                    <div className="table-responsive table-card">
                      <table className="table table-centered align-middle table-custom-effect table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="checkAll"
                                />
                                <label
                                  className="form-check-label"
                                  for="checkAll"
                                ></label>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="invoice_id"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="customer_name"
                            >
                              Customer Name
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="email"
                            >
                              Phone Number
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="create_date"
                            >
                              Order Date
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="due_date"
                            >
                              Delivery Date
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="amount"
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="status"
                            >
                              Status
                            </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody
                          className="list form-check-all"
                          id="invoice-list-data"
                        ></tbody>
                        {/* <!-- end tbody --> */}
                      </table>
                      {/* <!-- end table --> */}
                      <div className="noresult" style={{ display: " none" }}>
                        <div className="text-center py-4">
                          <i className="ph-magnifying-glass fs-1 text-primary"></i>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 6+ invoice We did not find
                            any invoice for you search.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row align-items-center mt-4 pt-3"
                      id="pagination-element"
                    >
                      <div className="col-sm">
                        <div className="text-muted text-center text-sm-start">
                          Showing <span className="fw-semibold">10</span> of{" "}
                          <span className="fw-semibold">15</span> Results
                        </div>
                      </div>
                      {/* <!--end col--> */}
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <div className="pagination-wrap hstack justify-content-center gap-2">
                          <a
                            className="page-item pagination-prev disabled"
                            href="#"
                          >
                            Previous
                          </a>
                          <ul className="pagination listjs-pagination mb-0"></ul>
                          <a className="page-item pagination-next" href="#">
                            Next
                          </a>
                        </div>
                      </div>
                      {/* <!--end col--> */}
                    </div>
                    {/* <!--end row--> */}
                  </div>
                </div>
              </div>
              {/* <!--end col--> */}
            </div>
            {/* <!--end row--> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceList;
