/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Income = () => {
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                  <h4 className="mb-sm-0">Income</h4>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row">
              <div className="col-lg-12">
                <div className="card" id="agentList">
                  <div className="card-header">
                    <div className="row align-items-center gy-3">
                      <div className="col-lg-3 col-md-6 order-last order-md-first me-auto">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search for agent, email, address or something..."
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>
                      <div className="col-md-auto col-6 text-end">
                        <div className="d-flex flex-wrap align-items-start gap-2">
                          <button
                            className="btn btn-subtle-danger d-none"
                            id="remove-actions"
                            onClick="deleteMultiple()"
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </button>
                          <button
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#addAgent"
                          >
                            <i className="bi bi-person-plus align-baseline me-1"></i>{" "}
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                        <thead className="text-muted table-light">
                          <tr>
                            <th>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="option"
                                  id="checkAll"
                                />
                                <label
                                  className="form-check-label"
                                  for="checkAll"
                                ></label>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="agent_id"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="joining_date"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="agent_Name"
                            >
                              Purpose
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="address"
                            >
                              Cash-In
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="email"
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="contact"
                            >
                              Coment
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="status"
                            >
                              Insert
                            </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="chk_child"
                                />
                                <label className="form-check-label"></label>
                              </div>
                            </td>
                            <td className="agent_id">
                              <a
                                href="apps-real-estate-agent-overview.html"
                                className="fw-medium link-primary"
                              >
                                #TBS01
                              </a>
                            </td>
                            <td className="joining_date">20 March, 2023</td>
                            <td className="agent_Name">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  src="assets/images/users/avatar-2.jpg"
                                  alt=""
                                  className="avatar-xs rounded"
                                />
                                <a
                                  href="apps-real-estate-agent-overview.html"
                                  className="text-reset text-capitalize"
                                >
                                  Scot Sawayn
                                </a>
                              </div>
                            </td>
                            <td className="address">
                              Birmingham, United Kingdom
                            </td>
                            <td className="email">scotsawayn@steex.com</td>
                            <td className="contact">(86) 9985-9220</td>
                            <td className="status">
                              <span className="badge bg-success-subtle text-success">
                                Active
                              </span>
                            </td>
                            <td>
                              <ul className="d-flex gap-2 list-unstyled mb-0">
                                <li>
                                  <a
                                    href="apps-real-estate-agent-overview.html"
                                    className="btn btn-subtle-primary btn-icon btn-sm "
                                  >
                                    <i className="ph-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#addAgent"
                                    data-bs-toggle="modal"
                                    className="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn"
                                  >
                                    <i className="ph-pencil"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#deleteRecordModal"
                                    data-bs-toggle="modal"
                                    className="btn btn-subtle-danger btn-icon btn-sm remove-item-btn"
                                  >
                                    <i className="ph-trash"></i>
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                        {/* <!-- end tbody --> */}
                      </table>
                      {/* <!-- end table --> */}
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center py-4">
                          <i className="ph-magnifying-glass fs-1 text-primary"></i>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ agent We did not find
                            any agent for you search.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row mt-3 align-items-center"
                      id="pagination-element"
                    >
                      <div className="col-sm">
                        <div className="text-muted text-center text-sm-start">
                          Showing <span className="fw-semibold">10</span> of{" "}
                          <span className="fw-semibold">15</span> Results
                        </div>
                      </div>
                      {/* <!--end col--> */}
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <div className="pagination-wrap justify-content-center hstack gap-2">
                          <a
                            className="page-item pagination-prev disabled"
                            href="javascript:void(0)"
                          >
                            Previous
                          </a>
                          <ul className="pagination listjs-pagination mb-0"></ul>
                          <a
                            className="page-item pagination-next"
                            href="javascript:void(0)"
                          >
                            Next
                          </a>
                        </div>
                      </div>
                      {/* <!--end col--> */}
                    </div>
                    {/* <!--end row--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- container-fluid --> */}
        </div>
      </div>
    </>
  );
};

export default Income;
