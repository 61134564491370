import React from "react";
import "./bestGift.css";
import { HashLink } from "react-router-hash-link";

const BestGift = () => {
  return (
    <div className="my-4">
      <div className="container mx-auto">
        <div className="gift text-center">
          <h2 className="fw-semibold fs-2">
            &#129303; প্রিয়জনকে খুশি করার সেরা উপহার!!
          </h2>
        </div>

        {/* ==== order part ==== */}
        <div className="order text-center mt-4">
          <HashLink smooth to="#orderDelivery">
            <button className="button border rounded py-2 px-4 fs-2 fw-bold">
              অর্ডার করতে চাই
            </button>
          </HashLink>
        </div>

        {/* ==== orginal diamond ==== */}
        <div className="diamond border border rounded-4 text-center mt-4">
          <h6 className="py-3 fs-2 fw-bold">
            আপনার প্রিয় মানুষকে খুশি করতে নিয়ে আসলাম (Original Diamond Carat
            Ring) সাথে থাকছে এই অসাধারণ আর্টিফিশিয়াল গোলাপটি।
          </h6>
        </div>
      </div>
    </div>
  );
};

export default BestGift;
