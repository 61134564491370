import * as yup from "yup";

export const initialValues = {
  title: "",
  b_status: "",
  subtitle: "",
  description: "",
  image: "",
  author: "",
  pub_date: "",
};

export const schema = yup.object().shape({
  title: yup.string().required("Blog Title is required!"),
  b_status: yup.string(),
  subtitle: yup.string().required("Blog Subtitle is required!"),
  description: yup.string(),
  image: yup.string(),
  author: yup.string().required("Author is required!"),
  pub_date: yup.string().required("Publication Date is required!"),
});

export const validate = (values) => {
  let errors = {};

  return errors;
};
