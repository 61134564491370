import * as yup from "yup";

export const initialValues = {
  name: "",
  category: "",
  p_status: "",
  is_feature: "",
  unit: "",
  initial_stock: "",
  description: "",
  image: "",
  gal_image1: "",
  gal_image2: "",
  gal_image3: "",
  gal_image4: "",
  gal_image5: "",
  discount: "",
  buying_price: "",
  selling_price: "",
};

export const schema = yup.object().shape({
  name: yup
    .string()
    .max(50, "Product Name contains maximum 50 chracters!")
    .required("Product Name is required!"),
  category: yup.string(),
  p_status: yup.string(),
  is_feature: yup.string(),
  unit: yup.string().required("Unit is required!"),
  initial_stock: yup.string(),
  description: yup.string(),
  image: yup.mixed(),
  gal_image1: yup.mixed(),
  gal_image2: yup.mixed(),
  gal_image3: yup.mixed(),
  gal_image4: yup.mixed(),
  gal_image5: yup.mixed(),
  discount: yup.string(),
  buying_price: yup.string().required("Buying Price is required!"),
  selling_price: yup.string().required("Selling Price is required!"),
});

export const validate = (values) => {
  let errors = {};

  if (values.initial_stock) {
    if (/^[0-9.\b]+$/.test(values.initial_stock) === false) {
      errors.initial_stock = "Only number!";
    }
  }

  if (values.discount) {
    if (/^[0-9.\b]+$/.test(values.discount) === false) {
      errors.discount = "Only number!";
    } else if (/^\d{0,8}(\.\d{0,2})?$/.test(values.discount) === false) {
      errors.discount =
        "Maximum 8 digits before the decimal point & Maximum 2 digits after the decimal point!";
    }
  }

  if (!values.buying_price) {
    errors.buying_price = "Buying Price is required!";
  } else if (/^[0-9.\b]+$/.test(values.buying_price) === false) {
    errors.buying_price = "Only number!";
  } else if (/^\d{0,8}(\.\d{0,2})?$/.test(values.buying_price) === false) {
    errors.buying_price =
      "Maximum 8 digits before the decimal point & Maximum 2 digits after the decimal point!";
  }

  if (!values.selling_price) {
    errors.selling_price = "Selling Price is required!";
  } else if (/^[0-9.\b]+$/.test(values.selling_price) === false) {
    errors.selling_price = "Only number!";
  } else if (/^\d{0,8}(\.\d{0,2})?$/.test(values.selling_price) === false) {
    errors.selling_price =
      "Maximum 8 digits before the decimal point & Maximum 2 digits after the decimal point!";
  }

  return errors;
};
