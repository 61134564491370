import { Link } from "react-router-dom";

const DFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">{currentYear} ©City Gift</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                Design & Develop by{" "}
                <Link
                  style={{ color: "red" }}
                  to="http://www.ekattorit.com/"
                  target="blanck"
                >
                  EKATTOR <span style={{ color: "green" }}>iT</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default DFooter;
