import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";

const DeliveryTypeTable = ({
  unpaginate_delivery_type,
  updateDeliveryType,
}) => {
  const [search, setSearch] = useState("");
  const [filterDeliveryType, setFilterDeliveryType] = useState("");

  const colums = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) => row.time,
    },
    {
      name: "Status",
      selector: (row) => row.d_status,
      cell: (row) => (row.d_status ? "Active" : "Inactive"),
    },

    {
      name: "Action",
      selector: (row) => (
        <ul className="d-flex gap-2 list-unstyled mb-0">
          <li>
            <Link
              to="#DeliveryTypeUpdateModal"
              data-bs-toggle="modal"
              className="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn"
              onClick={() => updateDeliveryType(row.id)}
            >
              <i className="ph-pencil"></i>
            </Link>
          </li>
          <li>
            <Link
              to="#DeliveryTypeDeleteModal"
              data-bs-toggle="modal"
              className="btn btn-subtle-danger btn-icon btn-sm remove-item-btn"
            >
              <i className="ph-trash"></i>
            </Link>
          </li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    const result =
      unpaginate_delivery_type &&
      unpaginate_delivery_type.filter((data) => {
        const nameMatch = data.name
          .toLowerCase()
          .includes(search.toLowerCase());
        const idMatch = String(data.id).includes(search.toLowerCase());
        return nameMatch || idMatch;
      });
    setFilterDeliveryType(result);
  }, [search, unpaginate_delivery_type]);

  return (
    <DataTable
      columns={colums}
      data={filterDeliveryType}
      pagination
      fixedHeader
      fixedHeaderScrollHeight="450px"
      selectableRows
      selectableRowsHighlight
      highlightOnHover
      subHeader
      subHeaderAlign="left"
      subHeaderComponent={
        <input
          type="text"
          placeholder="Search here"
          className="w-100 form-control"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></input>
      }
    />
  );
};

export default DeliveryTypeTable;
