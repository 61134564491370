import React, { useState } from "react";
import "./contact.css";
import { BsFillChatDotsFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { FaPhoneAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
const Contact = () => {
  const [isActive, setIsActive] = useState(false);
  function handleClick() {
    setIsActive(!isActive);
  }

  return (
    <>
      <div className="fixed-button">
        <div>
          {isActive === false && (
            <button onClick={handleClick} className="add-employee-btn">
              <BsFillChatDotsFill className="fs-1 text-white rrrr" />
              <span className="text text-white">
                &nbsp;&nbsp;প্রয়োজনে যোগাযোগ করুন
              </span>
            </button>
          )}
          {isActive === true && (
            <button onClick={handleClick} className="add-employee-cross-btn">
              <ImCross className="fs-1 text-white rrrr" />
              <span className="text text-white fs-3">&nbsp;&nbsp;Hide</span>
            </button>
          )}
        </div>
      </div>
      {isActive === true && (
        <div className="fixed-phone-button">
          <div>
            <Link to="tel:01760405645">
              <button className="add-employee-phone-btn">
                <FaPhoneAlt className="fs-1 text-white rrrr" />
                <span className="text text-white">&nbsp;&nbsp;Phone</span>
              </button>
            </Link>
          </div>
        </div>
      )}
      {isActive === true && (
        <div className="fixed-wp-button">
          <div>
            <Link target="blank" to="https://wa.me/01760405645">
              <button className="add-employee-wp-btn">
                <FaWhatsapp className="fs-1 text-white" />
                <span className="text text-white">&nbsp;&nbsp;WhatsApp</span>
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};
export default Contact;
