import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";

const ProductTable = ({ product }) => {
  const [search, setSearch] = useState("");
  const [filterProducts, setFilterProducts] = useState("");

  const colums = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.p_status,
      cell: (row) => (row.p_status ? "Active" : "Inactive"),
    },
    {
      name: "Unit",
      selector: (row) => row.unit.name,
    },
    {
      name: "Quantity",
      selector: (row) => row.initial_stock,
    },
    {
      name: "Discount",
      selector: (row) => (row.discount ? row.discount : "-"),
    },
    {
      name: "Buying Price",
      selector: (row) => row.buying_price,
    },
    {
      name: "Selling Price",
      selector: (row) => row.selling_price,
    },
    {
      name: "Image",
      selector: (row) => (
        <img
          src={row.image}
          alt="logo"
          height="50"
          width="50"
          style={{ borderRadius: "50%", margin: "10px" }}
        />
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <ul className="d-flex gap-2 list-unstyled mb-0">
          <li>
            <Link
              to={`/product-update/${row.id}`}
              className="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn"
            >
              <i className="ph-pencil"></i>
            </Link>
          </li>
          <li>
            <Link
              to="#ProductDeleteModal"
              data-bs-toggle="modal"
              className="btn btn-subtle-danger btn-icon btn-sm remove-item-btn"
            >
              <i className="ph-trash"></i>
            </Link>
          </li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    const result =
      product &&
      product.filter((data) => {
        const nameMatch = data.name
          .toLowerCase()
          .includes(search.toLowerCase());
        const idMatch = String(data.id).includes(search.toLowerCase());
        return nameMatch || idMatch;
      });
    setFilterProducts(result);
  }, [search, product]);

  return (
    <DataTable
      columns={colums}
      data={filterProducts}
      pagination
      fixedHeader
      fixedHeaderScrollHeight="450px"
      selectableRows
      selectableRowsHighlight
      highlightOnHover
      subHeader
      subHeaderComponent={
        <input
          type="text"
          placeholder="Search here"
          className="w-100 form-control"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></input>
      }
      subHeaderAlign="left"
    />
  );
};

export default ProductTable;
