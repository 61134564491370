import React from "react";

const DHome = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="background-image-container"></div>
      </div>
    </div>
  );
};

export default DHome;
