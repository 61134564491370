import { useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import axios from "axios";
import InputGroup from "react-bootstrap/InputGroup";
import { useApiContext } from "../../context/ApiContext";
import DeleteBtnModal from "../../components/DeleteBtnModal";
import UnitTable from "./UnitTable";

const initialValues = {
  name: "",
  u_status: "",
};

const schema = yup.object().shape({
  name: yup.string().required("Unit Name is a required field!"),
  u_status: yup.string(),
});

const validate = (values) => {
  let errors = {};

  return errors;
};

const Unit = () => {
  const { unit } = useApiContext();
  const [message, setMessage] = useState();
  const [item, setItem] = useState({});

  const AddUnitFunc = async (values) => {
    let formfield = new FormData();

    formfield.append("name", values.name);
    formfield.append("u_status", values.u_status);

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNIT_URL}`,
      data: formfield,
    })
      .then((response) => {
        setMessage(response.success, "Unit is successfuly created...");
        window.location.reload(false);
      })
      .catch((error) => {
        setMessage(error.message, "Error");
      });
  };

  const submitAddUnitForm = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      AddUnitFunc(values);
      setSubmitting(false);
      // resetForm();
    } catch (error) {
      setErrors({ error: error.message });
    }
  };

  const updatedValues = {
    name: item.name ? item.name : "",
    u_status: item.u_status ? item.u_status : "",
  };

  const UpdateUnitFunc = async (values) => {
    let formfield = new FormData();

    formfield.append("name", values.name);
    formfield.append("u_status", values.u_status);

    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNIT_URL}${item.id}/`,
      data: formfield,
    })
      .then((response) => {
        setMessage(response.success, "Unit is successfully updated...");
        window.location.reload(false);
      })
      .catch((error) => {
        setMessage(error.message, "Error");
      });
  };

  const submitUpdateUnitForm = async (values, { setErrors, setSubmitting }) => {
    try {
      UpdateUnitFunc(values);
      setSubmitting(false);
    } catch (error) {
      setErrors({ err: error.message });
    }
  };

  const updateUnit = async (id) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNIT_URL}${id}/`
    );
    setItem(data);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <div className="mx-3 my-2 page-title-box d-sm-flex align-items-center justify-content-start">
                  <h4 className="mb-sm-0">Unit</h4>
                </div>
              </div>

              <div className="col-6">
                <div className="d-flex mx-3 my-2  align-items-center justify-content-end">
                  <button
                    className="btn btn-secondary mb-4"
                    data-bs-toggle="modal"
                    data-bs-target="#UnitAddModal"
                  >
                    <i className="bi bi-plus-circle align-baseline me-1"></i>{" "}
                    Add Unit
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <UnitTable unit={unit} updateUnit={updateUnit} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--Add Modal --> */}
      <div
        className="modal fade"
        id="UnitAddModal"
        tabIndex="-1"
        aria-labelledby="UnitAddModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={submitAddUnitForm}
              validate={validate}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                isSubmitting,
              }) => (
                <FormikForm noValidate onSubmit={(e) => handleSubmit(e)}>
                  <div className="modal-header">
                    <h5 className="modal-title" id="UnitAddModalLabel">
                      Add Unit
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-addAgentModal"
                    ></button>
                  </div>

                  <div className="modal-body">
                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Unit Name<span>*</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          name="name"
                          id="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!touched.name && !!errors.name}
                          isValid={touched.name && !errors.name}
                          className="form-control"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Status<span></span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Select
                          name="u_status"
                          id="u_status"
                          value={values.u_status}
                          onChange={handleChange}
                          isInvalid={!!touched.u_status && !!errors.u_status}
                          isValid={touched.u_status && !errors.u_status}
                          className="form-control"
                        >
                          <option value="">Select</option>
                          <option value={`${true}`}>Active</option>
                          <option value={`${false}`}>Inactive</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.u_status}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </div>

                  <div className="modal-footer">
                    <div className="hstack gap-2 justify-content-end">
                      <button type="reset" className="btn btn-danger">
                        Cancel
                      </button>

                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  {/* message  */}
                  {message && <h2 className="text-center m-5">{message}</h2>}
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      </div>

      {/* update Modal */}
      <div
        className="modal fade"
        id="UnitUpdateModal"
        tabIndex="-1"
        aria-labelledby="UnitUpdateModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <Formik
              enableReinitialize={true}
              initialValues={updatedValues}
              validationSchema={schema}
              onSubmit={submitUpdateUnitForm}
              validate={validate}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                isSubmitting,
              }) => (
                <FormikForm noValidate onSubmit={(e) => handleSubmit(e)}>
                  <div className="modal-header">
                    <h5 className="modal-title" id="UnitUpdateModalLabel">
                      Update Unit
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-addAgentModal"
                    ></button>
                  </div>

                  <div className="modal-body">
                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Unit Name<span>*</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          name="name"
                          id="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!touched.name && !!errors.name}
                          isValid={touched.name && !errors.name}
                          className="form-control"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Status<span></span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Select
                          name="u_status"
                          id="u_status"
                          value={values.u_status}
                          onChange={handleChange}
                          isInvalid={!!touched.u_status && !!errors.u_status}
                          isValid={touched.u_status && !errors.u_status}
                          className="form-control"
                        >
                          <option value="">Select</option>
                          <option value={`${true}`}>Active</option>
                          <option value={`${false}`}>Inactive</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.u_status}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </div>

                  <div className="modal-footer">
                    <div className="hstack gap-2 justify-content-end">
                      <button type="reset" className="btn btn-danger">
                        Cancel
                      </button>

                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        Update
                      </button>
                    </div>
                  </div>

                  {/* message  */}
                  {message && <h2 className="text-center m-5">{message}</h2>}
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <DeleteBtnModal id={`UnitDeleteModal`} />
    </>
  );
};

export default Unit;
