import { useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import axios from "axios";
import InputGroup from "react-bootstrap/InputGroup";
import { useApiContext } from "../../context/ApiContext";
import DeleteBtnModal from "../../components/DeleteBtnModal";
import SupplierTable from "./SupplierTable";

const initialValues = {
  name: "",
  s_status: "",
  address: "",
  phone: "",
  email: "",
  logo: "",
};

const schema = yup.object().shape({
  name: yup.string().required("Supplier Name is a required field!"),
  s_status: yup.string(),
  address: yup.string().required("Address is a required field!"),
  phone: yup.string().required("Phone Number is a required field!"),
  email: yup.string(),
  logo: yup.mixed(),
});

const validate = (values) => {
  let errors = {};

  if (!values.phone) {
    errors.phone = "Mobile Number is required!";
  } else if (/^[0-9\b]+$/.test(values.phone) === false) {
    errors.phone = "Only number!";
  } else if (values.phone.length !== 11) {
    errors.phone = "Mobile Number contains 11 digit!";
  }

  if (values.email) {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email) === false) {
      errors.email = "Invalid Email!";
    }
  }

  return errors;
};

const Supplier = () => {
  const { unpaginate_supplier } = useApiContext();

  const [message, setMessage] = useState();
  const [item, setItem] = useState({});

  const [showLogo, setShowLogo] = useState(null);
  const onLogoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setShowLogo(URL.createObjectURL(event.target.files[0]));
    }
  };

  const AddSupplierFunc = async (values) => {
    let formfield = new FormData();

    formfield.append("name", values.name);
    formfield.append("s_status", values.s_status);
    formfield.append("address", values.address);
    formfield.append("phone", values.phone);
    formfield.append("email", values.email);

    if (values.logo !== null) {
      formfield.append("logo", values.logo);
    }

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNP_SUPPLIER_URL}`,
      data: formfield,
    })
      .then((response) => {
        setMessage(response.success, "Supplier is successfuly created...");
        window.location.reload(false);
      })
      .catch((error) => {
        setMessage(error.message, "Error");
      });
  };

  const submitAddSupplierForm = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      AddSupplierFunc(values);
      setSubmitting(false);
      resetForm();
    } catch (error) {
      setErrors({ error: error.message });
    }
  };

  const updatedValues = {
    name: item.name ? item.name : "",
    s_status: item.s_status ? item.s_status : "",
    address: item.address ? item.address : "",
    phone: item.phone ? item.phone : "",
    email: item.email ? item.email : "",
    logo: item.logo ? item.logo : "",
  };

  const UpdateSupplierFunc = async (values) => {
    let formfield = new FormData();

    formfield.append("name", values.name);
    formfield.append("s_status", values.s_status);
    formfield.append("address", values.address);
    formfield.append("phone", values.phone);
    formfield.append("email", values.email);

    if (values.logo !== item.logo) {
      formfield.append("logo", values.logo);
    }

    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNP_SUPPLIER_URL}${item.id}/`,
      data: formfield,
    })
      .then((response) => {
        setMessage(response.success, "Supplier is successfully updated...");
        window.location.reload(false);
      })
      .catch((error) => {
        setMessage(error.message, "Error");
      });
  };

  const submitUpdateSupplierForm = async (
    values,
    { setErrors, setSubmitting }
  ) => {
    try {
      UpdateSupplierFunc(values);
      setSubmitting(false);
    } catch (error) {
      setErrors({ err: error.message });
    }
  };

  const updateSupplier = async (id) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNP_SUPPLIER_URL}${id}/`
    );
    setItem(data);
    setShowLogo(data.logo);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <div className="mx-3 my-2 page-title-box d-sm-flex align-items-center justify-content-start">
                  <h4 className="mb-sm-0">Supplier</h4>
                </div>
              </div>

              <div className="col-6">
                <div className="d-flex mx-3 my-2  align-items-center justify-content-end">
                  <button
                    className="btn btn-secondary mb-4"
                    data-bs-toggle="modal"
                    data-bs-target="#SupplierAddModal"
                  >
                    <i className="bi bi-plus-circle align-baseline me-1"></i>{" "}
                    Add Supplier
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <SupplierTable
                        unpaginate_supplier={unpaginate_supplier}
                        updateSupplier={updateSupplier}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--Add Modal --> */}
      <div
        className="modal fade"
        id="SupplierAddModal"
        tabIndex="-1"
        aria-labelledby="SupplierAddModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={submitAddSupplierForm}
              validate={validate}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                errors,
                isSubmitting,
              }) => (
                <FormikForm noValidate onSubmit={(e) => handleSubmit(e)}>
                  <div className="modal-header">
                    <h5 className="modal-title" id="SupplierAddModalLabel">
                      Add Supplier
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-addAgentModal"
                    ></button>
                  </div>

                  <div className="modal-body">
                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Supplier Name<span>*</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          name="name"
                          id="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!touched.name && !!errors.name}
                          isValid={touched.name && !errors.name}
                          className="form-control"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Status<span></span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Select
                          name="s_status"
                          id="s_status"
                          value={values.s_status}
                          onChange={handleChange}
                          isInvalid={!!touched.s_status && !!errors.s_status}
                          isValid={touched.s_status && !errors.s_status}
                          className="form-control"
                        >
                          <option value="">Select</option>
                          <option value={`${true}`}>Active</option>
                          <option value={`${false}`}>Inactive</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.s_status}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Address<span>*</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          as="textarea"
                          name="address"
                          id="address"
                          value={values.address}
                          onChange={handleChange}
                          isInvalid={!!touched.address && !!errors.address}
                          isValid={touched.address && !errors.address}
                          className="form-control"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.address}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Phone<span>*</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          name="phone"
                          id="phone"
                          value={values.phone}
                          onChange={handleChange}
                          isInvalid={!!touched.phone && !!errors.phone}
                          isValid={touched.phone && !errors.phone}
                          className="form-control"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Email<span></span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          name="email"
                          id="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!touched.email && !!errors.email}
                          isValid={touched.email && !errors.email}
                          className="form-control"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Logo<span></span>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        name="logo"
                        id="logo"
                        onChange={(event) => {
                          setFieldValue("logo", event.currentTarget.files[0]);
                          onLogoChange(event);
                        }}
                        isInvalid={!!touched.logo && !!errors.logo}
                        isValid={touched.logo && !errors.logo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.logo}
                      </Form.Control.Feedback>

                      {showLogo && (
                        <div>
                          <img
                            alt="img"
                            style={{
                              width: "100px",
                              height: "100px",
                              marginRight: "5px",
                              marginTop: "10px",
                              marginBottom: "5px",
                              borderRadius: "5px",
                            }}
                            src={showLogo}
                          />
                        </div>
                      )}
                    </Form.Group>
                  </div>

                  <div className="modal-footer">
                    <div className="hstack gap-2 justify-content-end">
                      <button type="reset" className="btn btn-danger">
                        Cancel
                      </button>

                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  {/* message  */}
                  {message && <h2 className="text-center m-5">{message}</h2>}
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      </div>

      {/* update Modal */}
      <div
        className="modal fade"
        id="SupplierUpdateModal"
        tabIndex="-1"
        aria-labelledby="SupplierUpdateModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <Formik
              enableReinitialize={true}
              initialValues={updatedValues}
              validationSchema={schema}
              onSubmit={submitUpdateSupplierForm}
              validate={validate}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                errors,
                isSubmitting,
              }) => (
                <FormikForm noValidate onSubmit={(e) => handleSubmit(e)}>
                  <div className="modal-header">
                    <h5 className="modal-title" id="SupplierUpdateModalLabel">
                      Update Supplier
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-addAgentModal"
                    ></button>
                  </div>

                  <div className="modal-body">
                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Supplier Name<span>*</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          name="name"
                          id="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!touched.name && !!errors.name}
                          isValid={touched.name && !errors.name}
                          className="form-control"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Status<span></span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Select
                          name="s_status"
                          id="s_status"
                          value={values.s_status}
                          onChange={handleChange}
                          isInvalid={!!touched.s_status && !!errors.s_status}
                          isValid={touched.s_status && !errors.s_status}
                          className="form-control"
                        >
                          <option value="">Select</option>
                          <option value={`${true}`}>Active</option>
                          <option value={`${false}`}>Inactive</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.s_status}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Address<span>*</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          as="textarea"
                          name="address"
                          id="address"
                          value={values.address}
                          onChange={handleChange}
                          isInvalid={!!touched.address && !!errors.address}
                          isValid={touched.address && !errors.address}
                          className="form-control"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.address}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Phone<span>*</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          name="phone"
                          id="phone"
                          value={values.phone}
                          onChange={handleChange}
                          isInvalid={!!touched.phone && !!errors.phone}
                          isValid={touched.phone && !errors.phone}
                          className="form-control"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Email<span></span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          name="email"
                          id="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!touched.email && !!errors.email}
                          isValid={touched.email && !errors.email}
                          className="form-control"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Logo<span></span>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        name="logo"
                        id="logo"
                        onChange={(event) => {
                          setFieldValue("logo", event.currentTarget.files[0]);
                          onLogoChange(event);
                        }}
                        isInvalid={!!touched.logo && !!errors.logo}
                        isValid={touched.logo && !errors.logo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.logo}
                      </Form.Control.Feedback>

                      {showLogo && (
                        <div>
                          <img
                            alt="img"
                            style={{
                              width: "100px",
                              height: "100px",
                              marginRight: "5px",
                              marginTop: "10px",
                              marginBottom: "5px",
                              borderRadius: "5px",
                            }}
                            src={showLogo}
                          />
                        </div>
                      )}
                    </Form.Group>
                  </div>

                  <div className="modal-footer">
                    <div className="hstack gap-2 justify-content-end">
                      <button type="reset" className="btn btn-danger">
                        Cancel
                      </button>

                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        Update
                      </button>
                    </div>
                  </div>

                  {/* message  */}
                  {message && <h2 className="text-center m-5">{message}</h2>}
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <DeleteBtnModal id={`SupplierDeleteModal`} />
    </>
  );
};

export default Supplier;
