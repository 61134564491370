import { createContext, useContext, useEffect, useReducer } from "react";
import reducer from "../reducer/CartReducer";

const CartContext = createContext();

const getLocalCartData = () => {
  let localCartData = localStorage.getItem("landing2Cart");

  const parsedData = JSON.parse(localCartData);
  if (!Array.isArray(parsedData)) return [];
  return parsedData;
};

const getLocalOrderData = () => {
  let localOrderData = localStorage.getItem("landing2Order");

  const parsedData = JSON.parse(localOrderData);
  if (!parsedData || typeof parsedData !== "object") return {};
  return parsedData;
};

const initialState = {
  cart: getLocalCartData(),
  total_item: "",
  total_price: "",
  total_discount: "",

  order: getLocalOrderData(),
  order_total_price: "",
  order_total_discount: "",
  paid_amount: 0,
};

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // add to cart
  const addToCart = (id, amount, product) => {
    dispatch({ type: "ADD_TO_CART", payload: { id, amount, product } });
  };

  // remove to cart
  const removeItem = (id) => {
    dispatch({ type: "REMOVE_ITEM", payload: id });
  };

  // to clear the cart
  const clearCart = () => {
    dispatch({ type: "CLEAR_CART" });
  };

  // increase & decrease
  const setDecrement = (id) => {
    dispatch({ type: "SET_DECREMENT", payload: id });
  };

  const setIncrement = (id) => {
    dispatch({ type: "SET_INCREMENT", payload: id });
  };

  // to add the data in localstorage
  useEffect(() => {
    dispatch({ type: "CART_TOTAL_ITEM" });
    dispatch({ type: "CART_TOTAL_PRICE" });
    dispatch({ type: "CART_DISCOUNT" });
    localStorage.setItem("landing2Cart", JSON.stringify(state.cart));
  }, [state.cart]);

  // add to order
  const addToOrder = (id, amount, product) => {
    dispatch({ type: "ADD_TO_ORDER", payload: { id, amount, product } });
  };

  const clearOrder = () => {
    dispatch({ type: "CLEAR_ORDER" });
  };

  useEffect(() => {
    dispatch({ type: "ORDER_TOTAL_PRICE" });
    dispatch({ type: "ORDER_DISCOUNT" });
    localStorage.setItem("landing2Order", JSON.stringify(state.order));
  }, [state.order]);

  return (
    <CartContext.Provider
      value={{
        ...state,
        addToCart,
        removeItem,
        clearCart,
        setDecrement,
        setIncrement,
        addToOrder,
        clearOrder,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const useCartContext = () => {
  return useContext(CartContext);
};

export { CartProvider, useCartContext };
