const ApiReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    // Product module
    case "SET_API_CATEGORY":
      return {
        ...state,
        isLoading: false,
        category: action.payload,
        isError: false,
      };

    case "SET_API_UNIT":
      return {
        ...state,
        isLoading: false,
        unit: action.payload,
        isError: false,
      };

    case "SET_API_PRODUCT":
      return {
        ...state,
        isLoading: false,
        product: action.payload,
        isError: false,
      };

    case "SET_API_UNPAGINATE_PRODUCT":
      return {
        ...state,
        isLoading: false,
        unpaginate_product: action.payload,
        isError: false,
      };

    // purchase module
    case "SET_API_PURCHASE":
      return {
        ...state,
        isLoading: false,
        purchase: action.payload,
        isError: false,
      };

    // order module
    case "SET_API_ORDER":
      return {
        ...state,
        isLoading: false,
        order: action.payload,
        isError: false,
      };

    // address module
    case "SET_DISTRICT_API_DATA":
      return {
        ...state,
        isLoading: false,
        district: action.payload,
        isError: false,
      };

    case "SET_UPAZILA_API_DATA":
      return {
        ...state,
        isLoading: false,
        upazila: action.payload,
        isError: false,
      };

    // blog module
    case "SET_API_BLOG":
      return {
        ...state,
        isLoading: false,
        blog: action.payload,
        isError: false,
      };

    // setting module
    case "SET_API_SUPPLIER":
      return {
        ...state,
        isLoading: false,
        supplier: action.payload,
        isError: false,
      };

    case "SET_API_COURIER":
      return {
        ...state,
        isLoading: false,
        courier: action.payload,
        isError: false,
      };

    case "SET_API_MANAGE_BALANCE":
      return {
        ...state,
        isLoading: false,
        manage_balance: action.payload,
        isError: false,
      };

    case "SET_API_DELIVERY_TYPE":
      return {
        ...state,
        isLoading: false,
        delivery_type: action.payload,
        isError: false,
      };

    case "SET_API_UNPAGINATE_SUPPLIER":
      return {
        ...state,
        isLoading: false,
        unpaginate_supplier: action.payload,
        isError: false,
      };

    case "SET_API_UNPAGINATE_COURIER":
      return {
        ...state,
        isLoading: false,
        unpaginate_courier: action.payload,
        isError: false,
      };

    case "SET_API_UNPAGINATE_MANAGE_BALANCE":
      return {
        ...state,
        isLoading: false,
        unpaginate_manage_balance: action.payload,
        isError: false,
      };

    case "SET_API_UNPAGINATE_DELIVERY_TYPE":
      return {
        ...state,
        isLoading: false,
        unpaginate_delivery_type: action.payload,
        isError: false,
      };

    case "SET_API_CUSTOMER":
      return {
        ...state,
        isLoading: false,
        customer: action.payload,
        isError: false,
      };

    // For current user
    case "SET_CURRENT_USER_API":
      return {
        ...state,
        isLoading: false,
        current_user: action.payload,
        isError: false,
      };

    case "API_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};
export default ApiReducer;
