import React from "react";
import "./agrimOrder.css";
import { IoCallSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

const AgrimOrder = () => {
  return (
    <div className="my-3">
      <div className="container mx-auto">
        <div className="agrimOrder border rounded-4 py-3 px-4 fw-bold">
          <h2 className="text-center">কোন প্রকার অগ্রিম ছাড়াই অর্ডার করুন!</h2>
        </div>

        <div
          style={{ backgroundColor: "#FB9C00" }}
          className="contact border border-1 py-3 rounded-3"
        >
          <Link to="tel:01760405645">
            <h5 className="fs-4 fw-semibold text-dark">
              <IoCallSharp className="fs-3 fw-semibold" /> কল বা Whatapp করুন:
              01760405645
            </h5>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AgrimOrder;
