import React from "react";
import "./header.css";

const Header = () => {
  return (
    <div className="header py-3">
      <div className="container mx-auto">
        <div className="text-center">
          <h1 className="fs-1 fw-bold">40% DISCOUNT</h1>
        </div>
      </div>
    </div>
  );
};

export default Header;
