/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const InvoiceAdd = () => {
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Create Invoice</h4>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row justify-content-center">
              <div className="col-xxl-9">
                <form
                  className="needs-validation"
                  novalidate
                  id="invoice_form"
                  autocomplete="off"
                >
                  <div className="card overflow-hidden">
                    <div className="invoice-effect-top position-absolute start-0"></div>
                    <div className="card-body card-body z-1 position-relative">
                      <div className="row">
                        <div className="col-lg-4">
                          <div
                            className="col-4 "
                            style={{
                              border: "2px dashed rgb(167, 167, 167)",
                              padding: "10px",
                              width: "auto",
                              marginLeft: "40px",
                            }}
                          >
                            <p className="text-muted text-uppercase">
                              Billing Address
                            </p>
                            <h6 className="fs-md" id="name">
                              <span>Name: </span>Jennifer Mayert
                            </h6>
                            <h6 className="fs-md" id="mobileNo">
                              <span>Mobile: </span>012478965
                            </h6>
                            <p className="text-muted mb-1" id="address-line-1">
                              <span>Address:</span>6382 Cerromar Cir,
                              Orangevale, California, US.
                            </p>
                            <p className="text-muted mb-1" id="invoice">
                              <span>Invoice No: </span>2437
                            </p>
                            <p className="text-muted mb-1" id="courier">
                              <span>Courier: </span>SteadFast Courier
                            </p>
                          </div>
                        </div>
                        {/* <!--end col--> */}
                        <div className="col-lg-4 ms-auto">
                          <div className="mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="registrationNumber"
                              maxlength="12"
                              placeholder="Legal Registration No"
                              required
                            />
                            <div className="invalid-feedback">
                              Please enter a registration no, Ex., 012345678912
                            </div>
                          </div>
                          <div className="mb-2">
                            <input
                              type="email"
                              className="form-control"
                              id="companyEmail"
                              placeholder="Email Address"
                              required
                            />
                            <div className="invalid-feedback">
                              Please enter a valid email, Ex., example@gamil.com
                            </div>
                          </div>
                          <div className="mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="companyWebsite"
                              placeholder="Website"
                              required
                            />
                            <div className="invalid-feedback">
                              Please enter a website, Ex., www.example.com
                            </div>
                          </div>
                          <div className="mb-2">
                            <input
                              type="text"
                              className="form-control"
                              data-plugin="cleave-phone"
                              id="compnayContactno"
                              placeholder="Contact No"
                              required
                            />
                            <div className="invalid-feedback">
                              Please enter a contact number
                            </div>
                          </div>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              id="date-field"
                              placeholder="Create Date"
                            />
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <img
                                src="assets/images/logo1.jpg"
                                className="card-logo"
                                alt="logo light"
                                height="50"
                              />
                            </div>
                          </div>
                          {/* <!--end col--> */}
                          <div className="col-6">
                            <p className="text-muted mb-1" id="address-line-1">
                              Rebu Vila, House-1081,Khilbarirtek, Vatara,
                              Dhaka-1212{" "}
                            </p>
                            <p className="text-muted mb-1">
                              <span id="phone-no">www.naturiko.com</span>
                            </p>
                            <p className="text-muted mb-1">
                              <span>Hotline:</span>
                              <span id="phone-no">
                                01329283334, 01329283336
                              </span>
                            </p>
                            <p className="fs-md">
                              <span>Date:</span>
                              <span>06/096/2024</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <!--end row--> */}
                      <div className="mt-4">
                        <div className="row g-3">
                          <div className="col-lg-3 col-sm-6">
                            <label className="form-label" for="invoicenoInput">
                              Invoice No
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="invoicenoInput"
                              placeholder="Invoice No"
                              value="#TBS24301915"
                              readonly="readonly"
                            />
                          </div>
                          {/* <!--end col--> */}
                          <div className="col-lg-3 col-sm-6">
                            <div>
                              <label
                                className="form-label"
                                for="due-date-field"
                              >
                                Date
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="due-date-field"
                                placeholder="Select due date"
                              />
                            </div>
                          </div>
                          {/* <!--end col--> */}
                          <div className="col-lg-3 col-sm-6">
                            <label
                              className="form-label"
                              for="choices-payment-status"
                            >
                              Payment Status
                            </label>
                            <select
                              className="form-control"
                              data-choices
                              data-choices-search-false
                              id="choices-payment-status"
                              required
                            >
                              <option value="">Select Status</option>
                              <option value="Paid">Paid</option>
                              <option value="Pending">Pending</option>
                              <option value="Unpaid">Unpaid</option>
                              <option value="Refund">Refund</option>
                            </select>
                          </div>
                          {/* <!--end col--> */}
                          <div className="col-lg-3 col-sm-6">
                            <div>
                              <label
                                className="form-label"
                                for="totalamountInput"
                              >
                                Total Amount
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="totalamountInput"
                                placeholder="$0.00"
                                readonly
                              />
                            </div>
                          </div>
                          {/* <!--end col--> */}
                        </div>
                        {/* <!--end row--> */}
                      </div>
                      <div className="mt-4 pt-2">
                        <div className="row">
                          <div className="col-lg-4 col-sm-6">
                            <div>
                              <label
                                for="billingName"
                                className="form-label text-muted text-uppercase fw-semibold"
                              >
                                Billing Address
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control"
                                id="billingName"
                                placeholder="Full Name"
                                required
                              />
                              <div className="invalid-feedback">
                                Please enter a full name
                              </div>
                            </div>
                            <div className="mb-2">
                              <textarea
                                className="form-control"
                                id="billingAddress"
                                rows="3"
                                placeholder="Address"
                                required
                              ></textarea>
                              <div className="invalid-feedback">
                                Please enter a address
                              </div>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control"
                                data-plugin="cleave-phone"
                                id="billingPhoneno"
                                placeholder="(123)456-7890"
                                required
                              />
                              <div className="invalid-feedback">
                                Please enter a phone number
                              </div>
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="billingTaxno"
                                placeholder="Tax Number"
                                required
                              />
                              <div className="invalid-feedback">
                                Please enter a tax number
                              </div>
                            </div>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="same"
                                name="same"
                                onchange="billingFunction()"
                              />
                              <label className="form-check-label" for="same">
                                Will your Billing and Shipping address same?
                              </label>
                            </div>
                          </div>
                          {/* <!--end col--> */}
                          <div className="col-sm-6 ms-auto">
                            <div className="row">
                              <div className="col-lg-8">
                                <div>
                                  <label
                                    for="shippingName"
                                    className="form-label text-muted text-uppercase fw-semibold"
                                  >
                                    Shipping Address
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="shippingName"
                                    placeholder="Full Name"
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please enter a full name
                                  </div>
                                </div>
                                <div className="mb-2">
                                  <textarea
                                    className="form-control"
                                    id="shippingAddress"
                                    rows="3"
                                    placeholder="Address"
                                    required
                                  ></textarea>
                                  <div className="invalid-feedback">
                                    Please enter a address
                                  </div>
                                </div>
                                <div className="mb-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    data-plugin="cleave-phone"
                                    id="shippingPhoneno"
                                    placeholder="(123)456-7890"
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please enter a phone number
                                  </div>
                                </div>
                                <div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="shippingTaxno"
                                    placeholder="Tax Number"
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please enter a tax number
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!--end col--> */}
                        </div>
                      </div>
                      {/* <!--end row--> */}
                      <div className="table-responsive mt-4">
                        <table className="invoice-table table table-borderless table-nowrap mb-0">
                          <thead className="align-middle">
                            <tr className="table-light">
                              <th scope="col" style={{ width: " 50px" }}>
                                #
                              </th>
                              <th scope="col">Product Details</th>
                              <th scope="col" style={{ width: " 120px" }}>
                                <div className="d-flex currency-select input-light align-items-center">
                                  Rate
                                  <select
                                    className="form-selectborder-0 bg-light"
                                    data-choices
                                    data-choices-search-false
                                    id="choices-payment-currency"
                                    onchange="otherPayment()"
                                  >
                                    <option value="$">($)</option>
                                    <option value="£">(£)</option>
                                    <option value="₹">(₹)</option>
                                    <option value="€">(€)</option>
                                  </select>
                                </div>
                              </th>
                              <th scope="col" style={{ width: " 120px" }}>
                                Quantity
                              </th>
                              <th
                                scope="col"
                                className="text-end"
                                style={{ width: " 150px" }}
                              >
                                Amount
                              </th>
                              <th
                                scope="col"
                                className="text-end"
                                style={{ width: " 105px" }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody id="newlink">
                            <tr id="1" className="product-elem">
                              <th scope="row" className="product-id">
                                1
                              </th>
                              <td className="text-start">
                                <div className="mb-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="productName-1"
                                    placeholder="Product Name"
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    Please enter a product name
                                  </div>
                                </div>
                                <textarea
                                  className="form-control"
                                  id="productDetails-1"
                                  rows="2"
                                  placeholder="Product Details"
                                ></textarea>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control product-price"
                                  id="productRate-1"
                                  step="0.01"
                                  placeholder="0.00"
                                  required
                                />
                                <div className="invalid-feedback">
                                  Please enter a rate
                                </div>
                              </td>
                              <td>
                                <div className="input-step">
                                  <button type="button" className="minus-btn">
                                    –
                                  </button>
                                  <input
                                    type="number"
                                    className="invoice-product-quantity"
                                    id="product-qty-1"
                                    value="0"
                                    readonly
                                  />
                                  <button type="button" className="plus-btn">
                                    +
                                  </button>
                                </div>
                              </td>
                              <td className="text-end">
                                <div>
                                  <input
                                    type="text"
                                    className="form-control invoice-product-line-price"
                                    id="productPrice-1"
                                    placeholder="$0.00"
                                    readonly
                                  />
                                </div>
                              </td>
                              <td className="product-removal">
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-danger"
                                >
                                  Delete
                                </a>
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr id="newForm" style={{ display: " none" }}>
                              <td className="d-none" colspan="5">
                                <p>Add New Form</p>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="5">
                                <a
                                  href="javascript:new_link()"
                                  id="add-item"
                                  className="btn btn-subtle-secondary fw-medium"
                                >
                                  <i className="ri-add-fill me-1 align-bottom"></i>{" "}
                                  Add Item
                                </a>
                              </td>
                            </tr>
                            <tr className="border-top border-top-dashed mt-2">
                              <td colspan="3"></td>
                              <td colspan="2" className="p-0">
                                <table className="table table-borderless table-sm table-nowrap align-middle mb-0">
                                  <tbody>
                                    <tr>
                                      <th scope="row">Sub Total</th>
                                      <td style={{ width: "150px" }}>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="cart-subtotal"
                                          placeholder="$0.00"
                                          readonly
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Estimated Tax (18%)</th>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="cart-tax"
                                          placeholder="$0.00"
                                          readonly
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        Discount{" "}
                                        <small className="text-muted">
                                          (STEEX30)
                                        </small>
                                      </th>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="cart-discount"
                                          placeholder="$0.00"
                                          readonly
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Shipping Charge</th>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="cart-shipping"
                                          placeholder="$0.00"
                                          readonly
                                        />
                                      </td>
                                    </tr>
                                    <tr className="border-top border-top-dashed">
                                      <th scope="row">Total Amount</th>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="cart-total"
                                          placeholder="$0.00"
                                          readonly
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                {/* <!--end table--> */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/* <!--end table--> */}
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-4">
                          <div className="mb-2">
                            <label
                              for="choices-payment-type"
                              className="form-label text-muted text-uppercase fw-semibold"
                            >
                              Payment Details
                            </label>
                            <select
                              className="form-control"
                              data-choices
                              data-choices-search-false
                              data-choices-removeItem
                              id="choices-payment-type"
                            >
                              <option value="">Payment Method</option>
                              <option value="Mastercard">Mastercard</option>
                              <option value="Credit Card">Credit Card</option>
                              <option value="Visa">Visa</option>
                              <option value="Paypal">Paypal</option>
                            </select>
                          </div>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              id="cardholderName"
                              placeholder="Card Holder Name"
                            />
                          </div>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              id="cardNumber"
                              placeholder="xxxx xxxx xxxx xxxx"
                            />
                          </div>
                          <div>
                            <input
                              className="form-control"
                              type="text"
                              id="amountTotalPay"
                              placeholder="$0.00"
                              readonly
                            />
                          </div>
                        </div>
                        {/* <!--end col--> */}
                      </div>
                      {/* <!--end row--> */}
                      <div className="mt-4 mb-4">
                        <label
                          for="exampleFormControlTextarea1"
                          className="form-label text-muted text-uppercase fw-semibold"
                        >
                          NOTES
                        </label>
                        <textarea
                          className="form-control alert alert-danger"
                          id="exampleFormControlTextarea1"
                          placeholder="Notes"
                          rows="2"
                          required
                        >
                          All accounts are to be paid within 7 days from receipt
                          of invoice. To be paid by cheque or credit card or
                          direct payment online. If account is not paid within 7
                          days the credits details supplied as confirmation of
                          work undertaken will be charged the agreed quoted fee
                          noted above.
                        </textarea>
                      </div>
                      <div>
                        <div className="pt-2">
                          <input
                            type="text"
                            className="form-control"
                            id="successMsg"
                            placeholder="Enter Postal Code"
                            value="Congratulations on your recent purchase! It has been our pleasure to serve you, and we hope we see you again soon."
                            required
                          />
                        </div>
                        <div className="invoice-signature text-center">
                          <div className="mb-3 mt-4">
                            <input
                              id="sign-img-file-input"
                              type="file"
                              className="sign-img-file-input d-none"
                            />
                            <label
                              for="sign-img-file-input"
                              className="d-block"
                              tabIndex="0"
                            >
                              <span
                                className="overflow-hidden mx-auto border border-dashed d-flex align-items-center justify-content-center rounded"
                                style={{ height: "40px", width: "150px" }}
                              >
                                <img
                                  src="assets/images/invoice-signature.svg"
                                  className="card-logo card-sign-image img-fluid"
                                  alt="logo light"
                                />
                              </span>
                            </label>
                          </div>
                          <h6 className="mb-0 mt-3">Authorized Sign</h6>
                        </div>
                      </div>
                    </div>
                    <div
                      className="invoice-effect-top position-absolute end-0"
                      style={{ transform: "rotate(180deg)", bottom: "-80px" }}
                    >
                      <svg
                        version="1.2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 764 182"
                        width="764"
                        height="182"
                      >
                        <title>&lt;Group&gt;</title>
                        <g id="&lt;Group&gt;">
                          <g id="&lt;Group&gt;">
                            <path
                              id="&lt;Path&gt;"
                              style={{ fill: "var(--tb-light)" }}
                              d="m-6.6 177.4c17.5 0.1 35.1 0 52.8-0.4 286.8-6.6 537.6-77.8 700.3-184.6h-753.1z"
                            />
                          </g>
                          <g id="&lt;Group&gt;">
                            <path
                              id="&lt;Path&gt;"
                              style={{ fill: "var(--tb-secondary)" }}
                              d="m-6.6 132.8c43.5 2.1 87.9 2.7 132.9 1.7 246.9-5.6 467.1-59.2 627.4-142.1h-760.3z"
                            />
                          </g>
                          <g id="&lt;Group&gt;" style={{ opacity: " .5" }}>
                            <path
                              id="&lt;Path&gt;"
                              style={{ fill: "var(--tb-primary)" }}
                              d="m-6.6 87.2c73.2 7.4 149.3 10.6 227.3 8.8 206.2-4.7 393.8-42.8 543.5-103.6h-770.8z"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="hstack gap-2 flex-wrap justify-content-end d-print-none my-4">
                    <button type="submit" className="btn btn-success">
                      <i className="ri-printer-line align-bottom me-1"></i> Save
                    </button>
                    <a href="javascript:void(0);" className="btn btn-primary">
                      <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                      Download Invoice
                    </a>
                    <a href="javascript:void(0);" className="btn btn-danger">
                      <i className="ri-send-plane-fill align-bottom me-1"></i>{" "}
                      Send Invoice
                    </a>
                  </div>
                </form>
              </div>
              {/* <!--end col--> */}
            </div>
            {/* <!--end row--> */}
          </div>
          {/* <!-- container-fluid --> */}
        </div>
      </div>
    </>
  );
};

export default InvoiceAdd;
