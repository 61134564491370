import { createContext, useContext, useEffect, useReducer } from "react";
import ApiReducer from "../reducer/ApiReducer";

const AppContext = createContext();

const initialState = {
  isLoading: false,
  isError: false,

  // product module
  category: [],
  unit: [],
  product: [],
  unpaginate_product: [],

  // purchase module
  purchase: [],

  // order module
  order: [],

  // address module
  district: [],
  upazila: [],

  // blog module
  blog: [],

  // setting Module
  supplier: [],
  courier: [],
  manage_balance: [],
  unpaginate_supplier: [],
  unpaginate_courier: [],
  unpaginate_manage_balance: [],
  delivery_type: [],
  unpaginate_delivery_type: [],
  customer: [],

  // For current user
  current_user: {},
};

const ApiContext = ({ children }) => {
  const [state, dispatch] = useReducer(ApiReducer, initialState);

  // const [page, setPage] = useState(1);

  // function handleChange(value) {
  //   setPage(value);
  // }

  // product module
  const CATEGORY_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CATEGORY_URL}`;
  const UNIT_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNIT_URL}`;
  const PRODUCT_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCT_URL}`;
  const UNPAGINATE_PRODUCT_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNP_PRODUCT_URL}`;

  // purchase module
  const PURCHASE_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PURCHASE_URL}`;

  // order module
  const ORDER_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ORDER_URL}`;

  // address module
  const DISTRICT_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DISTRICTS_URL}`;
  const UPAZILA_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPAZILAS_URL}`;

  // blog module
  const BLOG_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BLOG_URL}`;

  // setting Module
  const SUPPLIER_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUPPLIER_URL}`;
  const COURIER_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_COURIER_URL}`;
  const MANAGE_BALANCE_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MANAGE_BALANCE_URL}`;
  const DELIVERY_TYPE_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DELIVERY_TYPE_URL}`;
  const UNPAGINATE_SUPPLIER_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNP_SUPPLIER_URL}`;
  const UNPAGINATE_COURIER_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNP_COURIER_URL}`;
  const UNPAGINATE_MANAGE_BALANCE_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNP_MANAGE_BALANCE_URL}`;
  const UNPAGINATE_DELIVERY_TYPE_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNP_DELIVERY_TYPE_URL}`;
  const CUSTOMER_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CUSTOMER_URL}`;

  // product module
  const getCategory = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({ type: "SET_API_CATEGORY", payload: result });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getUnit = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({
        type: "SET_API_UNIT",
        payload: result,
      });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getProduct = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({ type: "SET_API_PRODUCT", payload: result.results });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getUnpaginateProduct = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({ type: "SET_API_UNPAGINATE_PRODUCT", payload: result });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  // purchase module
  const getPurchase = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({
        type: "SET_API_PURCHASE",
        payload: result,
      });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  // order module
  const getOrder = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({
        type: "SET_API_ORDER",
        payload: result,
      });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  // address module
  const getdistrict = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({ type: "SET_DISTRICT_API_DATA", payload: result });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getupazila = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({ type: "SET_UPAZILA_API_DATA", payload: result });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  // blog module
  const getBlog = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({
        type: "SET_API_BLOG",
        payload: result,
      });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  // setting Module
  const getSupplier = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({ type: "SET_API_SUPPLIER", payload: result.results });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getCourier = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({ type: "SET_API_COURIER", payload: result.results });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getManageBalance = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({ type: "SET_API_MANAGE_BALANCE", payload: result.results });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getDeliveryType = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({ type: "SET_API_DELIVERY_TYPE", payload: result.results });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getUnpaginateSupplier = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({
        type: "SET_API_UNPAGINATE_SUPPLIER",
        payload: result,
      });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getUnpaginateCourier = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({ type: "SET_API_UNPAGINATE_COURIER", payload: result });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getUnpaginateManageBalance = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({ type: "SET_API_UNPAGINATE_MANAGE_BALANCE", payload: result });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getUnpaginateDeliveryType = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({ type: "SET_API_UNPAGINATE_DELIVERY_TYPE", payload: result });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getCustomer = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();
      dispatch({ type: "SET_API_CUSTOMER", payload: result });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  // For current user
  const aT = localStorage.getItem("landing2superuserandstaffAccessToken");
  useEffect(() => {
    if (aT) {
      const fetchCurrentUser = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CURRENT_CUSTOMER_URL}`,
            {
              headers: {
                Authorization: `Bearer ${aT}`,
              },
            }
          );
          const data = await response.json();
          dispatch({ type: "SET_CURRENT_USER_API", payload: data });
        } catch (error) {
          console.error("Error fetching current user:", error);
          dispatch({ type: "API_ERROR" });
        }
      };
      fetchCurrentUser();
    }
  }, [aT]);

  useEffect(() => {
    // product module
    getCategory(CATEGORY_URL);
    getProduct(PRODUCT_URL);
    getUnit(UNIT_URL);
    getUnpaginateProduct(UNPAGINATE_PRODUCT_URL);

    // purchase module
    getPurchase(PURCHASE_URL);

    // order module
    getOrder(ORDER_URL);

    // address module
    getdistrict(DISTRICT_URL);
    getupazila(UPAZILA_URL);

    // blog module
    getBlog(BLOG_URL);

    // setting Module
    getSupplier(SUPPLIER_URL);
    getCourier(COURIER_URL);
    getManageBalance(MANAGE_BALANCE_URL);
    getDeliveryType(DELIVERY_TYPE_URL);
    getUnpaginateSupplier(UNPAGINATE_SUPPLIER_URL);
    getUnpaginateCourier(UNPAGINATE_COURIER_URL);
    getUnpaginateManageBalance(UNPAGINATE_MANAGE_BALANCE_URL);
    getUnpaginateDeliveryType(UNPAGINATE_DELIVERY_TYPE_URL);
    getCustomer(CUSTOMER_URL);
  }, [
    BLOG_URL,
    CATEGORY_URL,
    COURIER_URL,
    CUSTOMER_URL,
    DELIVERY_TYPE_URL,
    DISTRICT_URL,
    MANAGE_BALANCE_URL,
    ORDER_URL,
    PRODUCT_URL,
    PURCHASE_URL,
    SUPPLIER_URL,
    UNIT_URL,
    UNPAGINATE_COURIER_URL,
    UNPAGINATE_DELIVERY_TYPE_URL,
    UNPAGINATE_MANAGE_BALANCE_URL,
    UNPAGINATE_PRODUCT_URL,
    UNPAGINATE_SUPPLIER_URL,
    UPAZILA_URL,
  ]);
  return (
    <AppContext.Provider value={{ ...state }}>{children}</AppContext.Provider>
  );
};
const useApiContext = () => {
  return useContext(AppContext);
};
export { ApiContext, AppContext, useApiContext };
