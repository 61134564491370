const ProductReducer = (state, action) => {
  //   if (action.type === "SET_LOADING") {
  //     return {
  //       ...state,
  //       isLoading: true,
  //     };
  //   }

  //   if (action.type === "API_ERROR") {
  //     return {
  //       ...state,
  //       isLoading: false,
  //       isError: true,
  //     };
  //   }

  switch (action.type) {
    // all products
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case "SET_PRODUCT_API_DATA":
      //featured data
      const featureData = action.payload.filter((curElem) => {
        return curElem.is_feature === true;
      });
      return {
        ...state,
        isLoading: false,
        products: action.payload,
        featureProducts: featureData,
        isError: false,
      };

    case "SET_DISTRICT_API_DATA":
      return {
        ...state,
        isLoading: false,
        district: action.payload,
        isError: false,
      };

    case "SET_UPAZILA_API_DATA":
      return {
        ...state,
        isLoading: false,
        upazila: action.payload,
        isError: false,
      };

    case "SET_BLOG_API_DATA":
      return {
        ...state,
        isLoading: false,
        blog: action.payload,
        isError: false,
      };

    // for Current user
    case "SET_CURRENT_USER_API":
      return {
        ...state,
        isLoading: false,
        c_user: action.payload,
        isError: false,
      };

    case "SET_ORDER_API_DATA":
      return {
        ...state,
        isLoading: false,
        order: action.payload,
        isError: false,
      };

    case "SET_DELIVERY_TYPE_DATA":
      return {
        ...state,
        isLoading: false,
        unpaginate_delivery_type: action.payload,
        isError: false,
      };

    // single product
    case "SET_SINGLE_LOADING":
      return {
        ...state,
        isSingleLoading: true,
        isError: false,
      };

    case "SET_SINGLE_PRODUCT":
      return {
        ...state,
        isSingleLoading: false,
        singleProduct: action.payload,
      };

    case "SET_SINGLE_ERROR":
      return {
        ...state,
        isSingleLoading: false,
        isError: true,
      };

    case "API_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // default
    default:
      return state;
  }

  //   return state;
};

export default ProductReducer;
