// create  a context
// provider
// consumer -> useContext Hook

import { createContext, useContext, useEffect, useReducer } from "react";
import axios from "axios";
import reducer from "../reducer/ProductReducer";

const AppContext = createContext();

const initialState = {
  isLoading: false,
  isError: false,
  products: [],
  featureProducts: [],

  isSingleLoading: false,
  singleProduct: {},
  district: [],
  upazila: [],
  blog: [],
  order: [],
  c_user: {},

  unpaginate_delivery_type: [],
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const Base_URL = process.env.REACT_APP_BASE_URL;
  const Product_URL = process.env.REACT_APP_PRODUCT_URL;
  const District_URL = process.env.REACT_APP_DISTRICTS_URL;
  const Upaliza_URL = process.env.REACT_APP_UPAZILAS_URL;
  const Blog_URL = process.env.REACT_APP_BLOG_URL;
  const Order_URL = process.env.REACT_APP_ORDER_URL;
  const Current_User_URL = process.env.REACT_APP_CURRENT_CUSTOMER_URL;
  const Delivery_Type_URL = process.env.REACT_APP_UNP_DELIVERY_TYPE_URL;

  const Product_API = `${Base_URL}${Product_URL}`;
  const District_API = `${Base_URL}${District_URL}`;
  const Upaliza_API = `${Base_URL}${Upaliza_URL}`;
  const Blog_API = `${Base_URL}${Blog_URL}`;
  const ORDER_API = `${Base_URL}${Order_URL}`;
  const Current_User_API = `${Base_URL}${Current_User_URL}`;
  const Delivery_Type_API = `${Base_URL}${Delivery_Type_URL}`;

  // api call for get all products
  const getproducts = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const res = await axios.get(url);
      const products = await res.data;
      dispatch({ type: "SET_PRODUCT_API_DATA", payload: products.results });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  // api call for district
  const getdistrict = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const res = await axios.get(url);
      const result = await res.data;
      dispatch({ type: "SET_DISTRICT_API_DATA", payload: result });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  // api call for Upazila
  const getupazila = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const res = await axios.get(url);
      const result = await res.data;
      dispatch({ type: "SET_UPAZILA_API_DATA", payload: result });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  // api call for get single products
  const getSingleProduct = async (url) => {
    dispatch({ type: "SET_SINGLE_LOADING" });
    try {
      const res = await axios.get(url);
      const singleProduct = await res.data;
      dispatch({ type: "SET_SINGLE_PRODUCT", payload: singleProduct });
    } catch (error) {
      dispatch({ type: "SET_SINGLE_ERROR" });
    }
  };

  // api call for blog
  const getblog = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const res = await axios.get(url);
      const result = await res.data;
      dispatch({ type: "SET_BLOG_API_DATA", payload: result });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  // order
  const getOrder = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const res = await axios.get(url);
      const result = await res.data;
      dispatch({ type: "SET_ORDER_API_DATA", payload: result });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  // For customer
  const aT = localStorage.getItem("landing2AccessToken");
  useEffect(() => {
    if (aT) {
      const fetchCurrentUser = async () => {
        try {
          const response = await fetch(Current_User_API, {
            headers: {
              Authorization: `Bearer ${aT}`,
            },
          });
          const data = await response.json();
          dispatch({ type: "SET_CURRENT_USER_API", payload: data });
        } catch (error) {
          console.error("Error fetching current user:", error);
          dispatch({ type: "API_ERROR" });
        }
      };
      fetchCurrentUser();
    }
  }, [Current_User_API, aT]);

  // delivery type
  const getdeliveryType = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const res = await axios.get(url);
      const result = await res.data;
      dispatch({ type: "SET_DELIVERY_TYPE_DATA", payload: result });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  useEffect(() => {
    getproducts(Product_API);
    getdistrict(District_API);
    getupazila(Upaliza_API);
    getblog(Blog_API);
    getOrder(ORDER_API);
    getdeliveryType(Delivery_Type_API);
  }, [
    Blog_API,
    Delivery_Type_API,
    District_API,
    ORDER_API,
    Product_API,
    Upaliza_API,
  ]);

  return (
    <AppContext.Provider value={{ ...state, getSingleProduct }}>
      {children}
    </AppContext.Provider>
  );
};

// custom hook
const useProductContext = () => {
  return useContext(AppContext);
};

export { AppProvider, AppContext, useProductContext };
