import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-dark py-2">
      <div className="container text-center text-white">
        <div className="">
          <small className="fs-6">
            &copy;Copyright {currentYear} Window Prime. All Right Reserved.
          </small>
        </div>

        <div className="">
          <Link
            to="https://ekattorit.com/"
            target="blanck"
            className="text-white text-decoration-none fw-semibold fs-4"
          >
            <small>
              Designed & Developed By{" "}
              <span style={{ color: "red" }}>EKATTOR</span>
              <span style={{ color: "green" }}> iT</span>
            </small>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
