import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App2 from "./App2";

import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./context/ProductContext";
import { CartProvider } from "./context/CartContext";
import { ApiContext } from "./dashboard/context/ApiContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ApiContext>
      <AppProvider>
        <CartProvider>
          <App2 />
        </CartProvider>
      </AppProvider>
    </ApiContext>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
