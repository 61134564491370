import React from "react";
import Header from "./header/Header";
import BestGift from "./bestGift/BestGift";
import CardRing from "./cardRing/CardRing";
import Slider from "./slider/Slider";
import AgrimOrder from "./agrimOrder/AgrimOrder";
import OrderForm from "./orderForm/OrderForm";
import Contact from "./contact/Contact";
import Phone from "./phone/Phone";

const Home = () => {
  return (
    <div>
      <Header></Header>
      <BestGift></BestGift>
      <Slider></Slider>
      <CardRing></CardRing>
      <AgrimOrder></AgrimOrder>
      <OrderForm></OrderForm>
      <Phone />
      <Contact></Contact>
    </div>
  );
};

export default Home;
