import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const DHeader = ({ current_user, dhandleLogout, isActive, setIsActive }) => {
  // Define a state variable to manage the class name

  const navigate = useNavigate();

  // Function to toggle the class name
  const toggleClass = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    const className = "vertical-sidebar-enable";

    if (isActive) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }

    return () => {
      document.body.classList.remove(className);
    };
  }, [isActive]);

  return (
    <>
      <header id="page-topbar">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <button
                onClick={toggleClass}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none"
                id="topnav-hamburger-icon"
              >
                <span
                  className={
                    isActive ? "hamburger-icon open" : "hamburger-icon "
                  }
                >
                  `<span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <div className="dropdown ms-sm-3  header-item topbar-user">
              <button
                type="button"
                className="btn shadow-none"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center">
                  <img
                    className="rounded-circle header-profile-user"
                    src={
                      current_user.image
                        ? `${process.env.REACT_APP_BASE_URL}${current_user.image}`
                        : "/img/default_profile_pic.png"
                    }
                    alt="Header Avatar"
                  />

                  <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                      {current_user.name}
                    </span>
                    <span className="d-none d-xl-block ms-1 fs-sm user-name-sub-text">
                      Admin / Staff
                    </span>
                  </span>
                </span>
              </button>

              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header text-success">
                  Welcome {current_user.name}!
                </h6>

                <Link className="dropdown-item" to="">
                  <i className="mdi mdi-account-circle text-muted fs-lg align-middle me-1"></i>{" "}
                  <span className="align-middle text-success">Profile</span>
                </Link>

                <Link className="dropdown-item" to="">
                  <span className="badge bg-success-subtle text-success mt-1 float-end">
                    New
                  </span>
                  <i className="mdi mdi-cog-outline text-muted fs-lg align-middle me-1"></i>{" "}
                  <span className="align-middle text-success">Settings</span>
                </Link>

                {/* <Link className="dropdown-item" to="">
                  <i className="mdi mdi-lock text-muted fs-lg align-middle me-1"></i>{" "}
                  <span className="align-middle">Lock screen</span>
                </Link> */}

                <Link
                  className="dropdown-item"
                  onClick={() => {
                    dhandleLogout();
                    navigate("/");
                    localStorage.removeItem("landing2superuserandstaffAccessToken");
                    localStorage.removeItem("landing2superuserandstaffRefreshToken");
                    window.location.reload(false);
                  }}
                >
                  <i className="mdi mdi-logout text-muted fs-lg align-middle me-1"></i>{" "}
                  <span
                    className="text-success align-middle"
                    data-key="t-logout"
                  >
                    Logout
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default DHeader;
