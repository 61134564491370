import { useState } from "react";
import axios from "axios";
import { Formik, Form as FormikForm } from "formik";
import { useNavigate } from "react-router-dom";
import { initialValues, schema, validate } from "./FormHelpers";
import BlogForm from "./BlogForm";

const BlogAdd = () => {
  const [showImage, setShowImage] = useState(null);
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const [content, setContent] = useState("");

  const AddBlogFunc = async (values) => {
    let formfield = new FormData();

    formfield.append("title", values.title);
    formfield.append("b_status", values.b_status);
    formfield.append("subtitle", values.subtitle);
    formfield.append("description", content);
    formfield.append("author", values.author);
    formfield.append("pub_date", values.pub_date);

    if (values.image) {
      formfield.append("image", values.image);
    }

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BLOG_URL}`,
      data: formfield,
    })
      .then((response) => {
        setMessage(response.success, "Blog is successfuly created...");
        navigate("/blogs");
        window.location.reload(false);
      })
      .catch((error) => {
        setMessage(error.message, "Error");
      });
  };

  const submitBlogForm = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      AddBlogFunc(values);
      setSubmitting(false);
      // resetForm();
      setShowImage(null);
    } catch (error) {
      setErrors({ error: error.message });
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                  <h4 className="mb-sm-0">Add Blog</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={submitBlogForm}
                        validate={validate}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          setFieldValue,
                          isSubmitting,
                          values,
                          errors,
                          touched,
                        }) => (
                          <FormikForm
                            noValidate
                            onSubmit={(e) => handleSubmit(e)}
                          >
                            <BlogForm
                              values={values}
                              handleChange={handleChange}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              showImage={showImage}
                              setShowImage={setShowImage}
                              isSubmitting={isSubmitting}
                              message={message}
                              isAdd
                              content={content}
                              setContent={setContent}
                            />
                          </FormikForm>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogAdd;
