import React from "react";
import "./orderForm.css";
import { useEffect, useState } from "react";
import axios from "axios";
import * as yup from "yup";
import { Formik, Form as FormikForm } from "formik";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";
import { useProductContext } from "../../../context/ProductContext";

const initialValues = {
  delivery_type: "",

  total_amount: "",
  discount: "",
  payable_amount: "",
  paid_amount: "",
  due_amount: "",
  delivery_charge: "",
  grand_total_amount: "",

  c_name: "",
  c_number: "",
  c_address: "",

  note: "",
};

const schema = yup.object().shape({
  delivery_type: yup.string(),

  total_amount: yup.string(),
  discount: yup.string(),
  payable_amount: yup.string(),
  paid_amount: yup.string(),
  due_amount: yup.string(),
  delivery_charge: yup.string(),
  grand_total_amount: yup.string(),

  c_name: yup.string().required("Name is a required field!"),
  c_number: yup.string().required("Mobile number is a required field!"),
  c_address: yup.string().required("Address is a required field!"),

  note: yup.string(),
});

const validate = (values) => {
  let errors = {};

  if (!values.c_number) {
    errors.c_number = "Phone is required!";
  } else if (/^[0-9\b]+$/.test(values.c_number) === false) {
    errors.c_number = "Only number!";
  } else if (values.c_number.length !== 11) {
    errors.c_number = "Mobile Number contains 11 digit!";
  }

  return errors;
};

const OrderForm = () => {
  const Base_URL = process.env.REACT_APP_BASE_URL;
  const Order_URL = process.env.REACT_APP_ORDER_URL;
  const Customer_Order_Post_URL = `${Base_URL}${Order_URL}`;

  const { products: p } = useProductContext();

  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const [product, setProduct] = useState({});
  const [orderState, setOrderState] = useState({});

  useEffect(() => {
    setProduct(p[0]);
  }, [p]);

  const [amount, setamount] = useState(1);

  const setDecrease = () => {
    amount > 1 ? setamount(amount - 1) : setamount(1);
  };

  const setIncrease = () => {
    amount < product.initial_stock
      ? setamount(amount + 1)
      : setamount(product.initial_stock);
  };

  // Calculate totals
  const calculatedTotalAmount = product && product.selling_price * amount;
  const calculatedTotalDiscount = product && product.discount * amount;
  const payableAmount = calculatedTotalAmount - calculatedTotalDiscount;
  const [paidAmount] = useState(0);
  const dueAmount = payableAmount - paidAmount;
  const [deliveryPrice] = useState(0);
  const grandTotalAmount = Number(dueAmount) + Number(deliveryPrice);

  const AddLoginOrderFunc = async (values) => {
    let formfield = new FormData();

    values.total_amount = calculatedTotalAmount;
    values.discount = calculatedTotalDiscount;
    values.payable_amount = payableAmount;
    values.paid_amount = paidAmount;
    values.due_amount = dueAmount;
    values.delivery_charge = deliveryPrice;
    values.grand_total_amount = grandTotalAmount;

    // Append individual fields
    formfield.append("c_name", values.c_name);
    formfield.append("c_number", values.c_number);
    formfield.append("c_address", values.c_address);
    formfield.append("note", values.note);

    formfield.append("payment_method", "Cash On Delivery");
    formfield.append("delivery_type", 1);

    // Append Order_details as separate items in FormData
    formfield.append(`order_details[${0}][product]`, Number(product.id));
    formfield.append(
      `order_details[${0}][bdtRate]`,
      product.selling_price - product.discount
    );
    formfield.append(`order_details[${0}][quantity]`, amount);
    formfield.append(
      `order_details[${0}][linePrice]`,
      (product.selling_price - product.discount) * amount
    );

    // Append individual fields
    formfield.append("total_amount", Number(values.total_amount));
    formfield.append("discount", Number(values.discount));
    formfield.append("payable_amount", Number(values.payable_amount));
    formfield.append("paid_amount", Number(values.paid_amount));
    formfield.append("due_amount", Number(values.due_amount));
    formfield.append("delivery_charge", Number(values.delivery_charge));
    formfield.append("grand_total_amount", Number(values.grand_total_amount));

    await axios({
      method: "POST",
      url: Customer_Order_Post_URL,
      headers: {
        "Content-Type": "application/json; charset=UTF-8; text/plain",
      },
      data: formfield,
    })
      .then((response) => {
        // console.log(response);
        setOrderSubmitted(true);
        setOrderState(response.data);
        // clearCart();
      })
      .catch((error) => {
        console.log(error);
        setMsg(error);
      });
  };

  const submitLoginOrderForm = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      AddLoginOrderFunc(values);
      setSubmitting(false);
      // resetForm();
      handleShowModal();
    } catch (error) {
      setErrors({ error: error.message });
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [orderSubmitted, setOrderSubmitted] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleModalClick = () => {
    handleCloseModal();
    navigate("/"); // assuming you have a `navigate` function from React Router
    window.location.reload(false);
  };

  const renderOrderSummaryModal = () => {
    return (
      <Modal
        show={showModal}
        onHide={() => {
          handleCloseModal();
          handleModalClick();
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Luxury Gift Order Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body onClick={handleModalClick}>
          <div>
            <h5> আপনার অর্ডারটি সফল হয়েছে ✅</h5>
            <h5 className="my-3">
              {" "}
              আমাদের অফিস টাইম সকাল ১০:০০ টা থেকে সন্ধ্যা ৭:০০ টার মধ্যে আপনাকে
              কল দিয়ে অর্ডারটি কনফার্ম করা হবে।
            </h5>
            <h5>ধন্যবাদ।</h5>
            <h6>Thank you. Your order has been received.</h6>
          </div>

          <div
            style={{
              background: "#E0E9E0",
              textAlign: "left",
              padding: "15px",
              margin: "25px 0",
            }}
          >
            <div>
              <p>
                Order Number :{" "}
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {orderState && orderState.order_no}
                </span>
              </p>

              <hr
                style={{
                  borderTop: "1px dashed #000",
                  width: "100%",
                  margin: "10px 0",
                }}
              />
            </div>

            <div>
              <p>
                Date :{" "}
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {orderState && orderState.order_date}
                </span>
              </p>
              <hr
                style={{
                  borderTop: "1px dashed #000",
                  width: "100%",
                  margin: "10px 0",
                }}
              />
            </div>

            <div>
              <p>
                Total :{" "}
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  ৳ {orderState && orderState.grand_total_amount}
                </span>
              </p>
              <hr
                style={{
                  borderTop: "1px dashed #000",
                  width: "100%",
                  margin: "10px 0",
                }}
              />
            </div>

            <div>
              <p>
                Payment Method :{" "}
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {orderState && orderState.payment_method}
                </span>
              </p>
              <hr
                style={{
                  borderTop: "1px dashed #000",
                  width: "100%",
                  margin: "10px 0",
                }}
              />
            </div>
          </div>

          <div>
            <h6>পণ্য হাতে পেয়ে ডেলিভারি ম্যানকে পেমেন্ট করুন </h6>
          </div>

          <div
            style={{
              // background: "#E0E9E0",
              padding: "15px",
              margin: "15px 0",
            }}
          >
            <h4 className="mb-4">Order Details</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "100%" }}>
                <h6>Product</h6>
              </div>
              <div style={{ width: "100%" }}>
                <h6>Total</h6>
              </div>
            </div>

            <div style={{ lineHeight: "0px" }}>
              <hr
                style={{
                  borderTop: "1px dashed #000",
                  width: "100%",
                  margin: "10px 0",
                }}
              />
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "100%" }}>
                  <p>
                    {p &&
                      p
                        .filter(
                          (item) =>
                            item.id === orderState.order_details[0].product
                        )
                        .map((itemm) => {
                          return <b key={itemm.id}>{itemm.name}</b>;
                        })}{" "}
                    &nbsp;&nbsp;x&nbsp;&nbsp;
                    <b>{orderState && orderState.order_details[0].quantity}</b>
                  </p>
                </div>

                <div style={{ width: "100%" }}>
                  <p>৳ {orderState && orderState.order_details[0].linePrice}</p>
                </div>
              </div>

              <div>
                <hr
                  style={{
                    borderTop: "1px dashed #000",
                    width: "100%",
                    margin: " 0 0 4px",
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "100%" }}>
                <p>Subtotal: </p>
              </div>
              <div style={{ width: "100%" }}>
                <p>৳ {orderState && orderState.grand_total_amount}</p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "100%" }}>
                <p>Shipping : </p>
              </div>
              <div style={{ width: "100%" }}>
                <p>Free Shipping</p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "100%" }}>
                <p>Pyment Method : </p>
              </div>

              <div style={{ width: "100%" }}>
                <p>{orderState && orderState.payment_method}</p>
              </div>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "100%" }}>
                  <p>Total : </p>
                </div>
                <div style={{ width: "100%" }}>
                  <p> ৳ {orderState && orderState.grand_total_amount}</p>
                </div>
              </div>

              <div>
                <hr
                  style={{
                    borderTop: "1px dashed #000",
                    width: "100%",
                    margin: " 0 0 4px",
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleCloseModal();
              handleModalClick();
            }}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div
      id="orderDelivery"
      style={{ backgroundColor: "#F7EBFB" }}
      className="border border-3 border-dark rounded-3 my-3"
    >
      <div className="container mx-auto">
        <div className="orderh1">
          <h1 className="text-center fw-bold mt-4">
            অর্ডার করতে নিচের ফর্মটি সঠিক তথ্য দিয়ে পূরণ করুন
          </h1>
        </div>

        {/* ======== Your Products start ======== */}
        <div className="">
          <h6 className="mb-2 fs-3 fw-semibold">Your Products</h6>
          <div
            style={{ width: "50%", backgroundColor: "white" }}
            className="d-flex gap-3 align-items-center border px-4 rounded py-4 hey"
          >
            <div className="">
             
            </div>

            <div className="">
              <img
                src={product && product.image}
                width={80}
                height={80}
                alt=""
              />
            </div>

            <div className="">
              <div className="">
                <p>{product && product.name}</p>
              </div>

              <div className="d-flex justify-content-between align-items-center gap-5">
                <div className="w-50 px-2 py-2 d-flex justify-content-between border align-items-start gap-1">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setDecrease()}
                  >
                    <b>-</b>
                  </span>
                  <p className="mb-0">{amount}</p>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setIncrease()}
                  >
                    <b>+</b>
                  </span>
                </div>

                <div className="tk w-50 mt-2">
                  <h6>৳ {payableAmount}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ======== Your Products start ======== */}

        {/* ======== form & table ======= */}
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          validate={validate}
          onSubmit={submitLoginOrderForm}
        >
          {({
            handleSubmit,
            handleChange,
            isSubmitting,
            values,
            errors,
            touched,
          }) => (
            <FormikForm noValidate onSubmit={(e) => handleSubmit(e)}>
              <div className="mainFrom_table d-flex justify-content-between align-items-start gap-5 mt-5">
                <div className="form w-50">
                  <h2 className="mb-5">Billing Details</h2>

                  <div className="">
                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        আপনার নাম লিখুন
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          name="c_name"
                          id="c_name"
                          value={values.c_name}
                          onChange={handleChange}
                          isInvalid={!!touched.c_name && !!errors.c_name}
                          isValid={touched.c_name && !errors.c_name}
                          className="form-control"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.c_name}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        আপনার মোবাইল নাম্বারটি লিখুন
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          name="c_number"
                          id="c_number"
                          value={values.c_number}
                          onChange={handleChange}
                          isInvalid={!!touched.c_number && !!errors.c_number}
                          isValid={touched.c_number && !errors.c_number}
                          className="form-control"
                          placeholder="এখানে আপনার ১১ ডিজিটের মোবাইল নাম্বার টি লিখুন"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.c_number}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        আপনার ঠিকানা লিখুন
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          name="c_address"
                          id="c_address"
                          value={values.c_address}
                          onChange={handleChange}
                          isInvalid={!!touched.c_address && !!errors.c_address}
                          isValid={touched.c_address && !errors.c_address}
                          className="form-control"
                          placeholder="বাসা নং, রোড নং, জেলা, উপজেলা"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.c_address}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>Note (Optional)</Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Control
                          as="textarea"
                          rows="6"
                          name="note"
                          id="note"
                          value={values.note}
                          onChange={handleChange}
                          isInvalid={!!touched.note && !!errors.note}
                          isValid={touched.note && !errors.note}
                          className="form-control"
                          placeholder="Note"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </div>
                </div>

                {/* ====== table part ====== */}
                <div className="table w-50">
                  <h2 className="mb-5">Your Order</h2>

                  <div className="">
                    <div className="table_row d-flex justify-content-between align-items-center">
                      <div style={{ backgroundColor: "#F7EBFB" }} className="">
                        <h5 className="fs-30">Product</h5>
                      </div>
                      <div style={{ backgroundColor: "#F7EBFB" }} className="">
                        <h5>Subtotal</h5>
                      </div>
                    </div>

                    <hr
                      style={{ borderTop: "1px dashed #000", width: "100%" }}
                    />

                    <div className="table_row d-flex justify-content-between align-items-center">
                      <div style={{ backgroundColor: "#F7EBFB" }} className="">
                        <div className="d-flex justify-content-between align-items-center">
                          <img
                            src={product && product.image}
                            width={60}
                            height={60}
                            alt=""
                          />
                          <p className="mx-2">{product && product.name}</p>
                        </div>
                      </div>
                      <div style={{ backgroundColor: "#F7EBFB" }} className="">
                        <p>
                          × {amount} ৳ {payableAmount}
                        </p>
                      </div>
                    </div>

                    <hr
                      style={{ borderTop: "1px dashed #000", width: "100%" }}
                    />

                    <div className="table_row d-flex justify-content-between align-items-center">
                      <div style={{ backgroundColor: "#F7EBFB" }} className="">
                        <p className="fs-30">Subtotal</p>
                      </div>
                      <div style={{ backgroundColor: "#F7EBFB" }} className="">
                        <p>৳ {payableAmount}</p>
                      </div>
                    </div>

                    {/* <hr/ */}

                    <div className="table_row d-flex justify-content-between align-items-center">
                      <div style={{ backgroundColor: "#F7EBFB" }} className="">
                        <p className="fs-30">Shipping</p>
                      </div>
                      <div style={{ backgroundColor: "#F7EBFB" }} className="">
                        <p>ফ্রি হোম ডেলিভারি</p>
                      </div>
                    </div>

                    <hr
                      style={{ borderTop: "1px dashed #000", width: "100%" }}
                    />

                    <div className="table_row d-flex justify-content-between align-items-center">
                      <div style={{ backgroundColor: "#F7EBFB" }} className="">
                        <h5 className="fs-30">Total</h5>
                      </div>
                      <div style={{ backgroundColor: "#F7EBFB" }} className="">
                        <h5>৳ {grandTotalAmount}</h5>
                      </div>
                    </div>
                  </div>

                  <div style={{ backgroundColor: "white" }} className="p-2">
                    <p className="fs-6 mx-3 my-2">ক্যাশ অন ডেলিভারি </p>

                    <div className="">
                      <p
                        style={{ backgroundColor: "#F7EBFB" }}
                        className="p-3 mx-3 my-2 fs-9 rounded "
                      >
                        পণ্য হাতে পেয়ে ডেলিভারি ম্যানকে পেমেন্ট করুন
                      </p>
                    </div>
                  </div>

                  <div className="my-2 mx-1" style={{ textAlign: "justify" }}>
                    <p>
                      Your personal data will be used to process your order,
                      support your experience throughout this website, and for
                      other purposes described in our privacy policy.
                    </p>
                  </div>

                  <div className="bbbb border border-2 rounded">
                    <button
                      style={{ backgroundColor: "#FF819F" }}
                      className="border w-100 p-2 text-white rounded"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      <i
                        style={{ backgroundColor: "#FF819F" }}
                        className="fa-solid fa-lock  text-white fs-3"
                      ></i>
                      <h5
                        style={{ backgroundColor: "#FF819F" }}
                        className=" text-white fw-semibold"
                      >
                        Place Order&nbsp;&nbsp;৳&nbsp;{grandTotalAmount}
                      </h5>
                    </button>
                  </div>
                </div>
              </div>

              <p
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  color: "red",
                  fontSize: "15px",
                  textTransform: "capitalize",
                }}
              >
                {msg}
              </p>

              {orderSubmitted && renderOrderSummaryModal()}
            </FormikForm>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default OrderForm;
