import { Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import axios from "axios";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

const initialValues = {
  email: "",
  password: "",
};

const schema = yup.object().shape({
  email: yup.string().required("Username is a required field!"),
  password: yup.string().required("Password is a required field!"),
});

const validate = (values) => {
  let errors = {};

  return errors;
};

const SignIn = () => {
  const currentYear = new Date().getFullYear();

  const navigate = useNavigate();
  const [message, setMessage] = useState();

  const AddLoginFunc = async (values) => {
    let formfield = new FormData();

    // Append individual fields
    formfield.append("email", values.email);
    formfield.append("password", values.password);

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUPERUSER_AND_STAFF_URL}`,
      headers: {
        "Content-Type": "application/json; charset=UTF-8; text/plain",
      },
      data: formfield,
    })
      .then((response) => {
        localStorage.setItem(
          "landing2superuserandstaffAccessToken",
          response && response.data.access
        );
        localStorage.setItem(
          "landing2superuserandstaffRefreshToken",
          response && response.data.refresh
        );
        localStorage.removeItem("landing2AccessToken");
        localStorage.removeItem("landing2RefreshToken");
        navigate("/");
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        setMessage(
          error.response.data.non_field_errors ?? error.response.data.error
        );
      });
  };

  const submitOrderForm = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      AddLoginFunc(values);
      setSubmitting(false);
      // resetForm();
    } catch (error) {
      setErrors({ error: error.message });
    }
  };

  return (
    <section className="auth-page-wrapper py-5 position-relative d-flex align-items-center justify-content-center min-vh-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="card mb-0">
              <div className="row g-0 align-items-center">
                <div className="col-xxl-5">
                  <div className="card auth-card bg-secondary h-100 border-0 shadow-none d-none d-sm-block mb-0">
                    <div className="card-body py-5 d-flex justify-content-between flex-column">
                      <div className="text-center">
                        <h3 className="text-white"> CITY GIFT ADMIN PANEL.</h3>
                        {/* <p className="text-white opacity-75 fs-base">
                          It brings together your tasks, projects, timelines,
                          files and more
                        </p> */}
                      </div>

                      <div className="auth-effect-main my-5 position-relative rounded-circle d-flex align-items-center justify-content-center mx-auto">
                        <div className="effect-circle-1 position-relative mx-auto rounded-circle d-flex align-items-center justify-content-center">
                          <div className="effect-circle-2 position-relative mx-auto rounded-circle d-flex align-items-center justify-content-center">
                            <div className="effect-circle-3 mx-auto rounded-circle position-relative text-white fs-4xl d-flex align-items-center justify-content-center">
                              {/* Welcome to{" "} */}
                              <span className="text-primary ms-1">
                                CITY GIFT
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className="auth-user-list list-unstyled">
                          <li>
                            <div className="avatar-sm d-inline-block">
                              <div className="avatar-title bg-white shadow-lg overflow-hidden rounded-circle">
                                <img
                                  src="/assets/images/users/avatar-1.jpg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="avatar-sm d-inline-block">
                              <div className="avatar-title bg-white shadow-lg overflow-hidden rounded-circle">
                                <img
                                  src="/assets/images/users/avatar-2.jpg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="avatar-sm d-inline-block">
                              <div className="avatar-title bg-white shadow-lg overflow-hidden rounded-circle">
                                <img
                                  src="/assets/images/users/avatar-3.jpg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="avatar-sm d-inline-block">
                              <div className="avatar-title bg-white shadow-lg overflow-hidden rounded-circle">
                                <img
                                  src="/assets/images/users/avatar-4.jpg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="avatar-sm d-inline-block">
                              <div className="avatar-title bg-white shadow-lg overflow-hidden rounded-circle">
                                <img
                                  src="/assets/images/users/avatar-5.jpg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="text-center">
                        <p className="text-white opacity-75 mb-0 mt-3">
                          &copy;
                          {currentYear}&nbsp; Crafted with&nbsp;
                          <i className="mdi mdi-heart text-danger"></i> by
                          &nbsp;
                          <Link
                            style={{ color: "red" }}
                            to="http://www.ekattorit.com/"
                          >
                            EKATTOR <span style={{ color: "green" }}>iT</span>
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-6 mx-auto">
                  <div className="card mb-0 border-0 shadow-none mb-0">
                    <div className="card-body p-sm-5 m-lg-4">
                      <div className="text-center mt-5">
                        <h5 className="fs-3xl">Welcome Back</h5>
                        <p className="text-muted">
                          Sign in to continue to CITY GIFT Dashboard.
                        </p>
                      </div>

                      <div className="p-2 mt-5">
                        <Formik
                          initialValues={initialValues}
                          validationSchema={schema}
                          onSubmit={submitOrderForm}
                          validate={validate}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            values,
                            errors,
                            touched,
                          }) => (
                            <FormikForm
                              noValidate
                              onSubmit={(e) => handleSubmit(e)}
                            >
                              <div className="checkout-box">
                                <div className="checkout-Login">
                                  <Form.Group className="form-outline mb-4 ">
                                    <Form.Label>
                                      Username<span>*</span>
                                    </Form.Label>
                                    <InputGroup hasValidation>
                                      <Form.Control
                                        type="text"
                                        name="email"
                                        id="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={
                                          !!touched.email && !!errors.email
                                        }
                                        isValid={touched.email && !errors.email}
                                        className="form-control"
                                        placeholder="Enter username"
                                        required
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Form.Group>

                                  <Form.Group className="form-outline mb-4 ">
                                    <Form.Label>
                                      Password<span>*</span>
                                    </Form.Label>
                                    <InputGroup hasValidation>
                                      <Form.Control
                                        type="password"
                                        name="password"
                                        id="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        isInvalid={
                                          !!touched.password &&
                                          !!errors.password
                                        }
                                        isValid={
                                          touched.password && !errors.password
                                        }
                                        className="form-control"
                                        autoComplete="on"
                                        placeholder="Enter password"
                                        required
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Form.Group>
                                </div>
                              </div>

                              <div className="hstack gap-2 flex-wrap justify-content-center d-print-none my-4">
                                <button
                                  disabled={isSubmitting}
                                  type="submit"
                                  className="btn btn-primary w-100"
                                >
                                  Sign In
                                </button>
                              </div>
                            </FormikForm>
                          )}
                        </Formik>

                        <div>
                          <b>
                            <p className="text-center text-danger text-capitalize">
                              {message}
                            </p>
                          </b>
                        </div>

                        {/* <div className="text-center mt-5">
                          <p className="mb-0">
                            Don't have an account ?{" "}
                            <a
                              href="##"
                              className="fw-semibold text-secondary text-decoration-underline"
                            >
                              SignUp
                            </a>
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-xxl-6 mx-auto">
                  <div className="card mb-0 border-0 shadow-none mb-0">
                    <div className="card-body p-sm-5 m-lg-4">
                      <div className="text-center mt-5">
                        <h5 className="fs-3xl">Welcome Back</h5>
                        <p className="text-muted">
                          Sign in to continue to Naturiko.
                        </p>
                      </div>

                      <div className="p-2 mt-5">
                        <form action="https://themesbrand.com/steex/layouts/index.html">
                          <div className="mb-3">
                            <label for="username" className="form-label">
                              Username <span className="text-danger">*</span>
                            </label>
                            <div className="position-relative ">
                              <input
                                type="text"
                                className="form-control  password-input"
                                id="username"
                                placeholder="Enter username"
                                required
                              />
                            </div>
                          </div>

                          <div className="mb-3">
                            <div className="float-end">
                              <a
                                href="auth-pass-reset.html"
                                className="text-muted"
                              >
                                Forgot password?
                              </a>
                            </div>
                            <label className="form-label" for="password-input">
                              Password <span className="text-danger">*</span>
                            </label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <input
                                type="password"
                                className="form-control pe-5 password-input "
                                placeholder="Enter password"
                                id="password-input"
                                required
                              />
                              <button
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                type="button"
                                id="password-addon"
                              >
                                <i className="ri-eye-fill align-middle"></i>
                              </button>
                            </div>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              for="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-4">
                            <button
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Sign In
                            </button>
                          </div>

                          <div className="mt-4 pt-2 text-center">
                            <div className="signin-other-title position-relative">
                              <h5 className="fs-sm mb-4 title">Sign In with</h5>
                            </div>
                            <div className="pt-2 hstack gap-2 justify-content-center">
                              <button
                                type="button"
                                className="btn btn-subtle-primary btn-icon"
                              >
                                <i className="ri-facebook-fill fs-lg"></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-subtle-danger btn-icon"
                              >
                                <i className="ri-google-fill fs-lg"></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-subtle-dark btn-icon"
                              >
                                <i className="ri-github-fill fs-lg"></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-subtle-info btn-icon"
                              >
                                <i className="ri-twitter-fill fs-lg"></i>
                              </button>
                            </div>
                          </div>
                        </form>

                        <div className="text-center mt-5">
                          <p className="mb-0">
                            Don't have an account ?{" "}
                            <a
                              href="##"
                              className="fw-semibold text-secondary text-decoration-underline"
                            >
                              SignUp
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
