import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";

const UnitTable = ({ unit, updateUnit }) => {
  const [search, setSearch] = useState("");
  const [filterUnits, setFilterUnits] = useState("");

  const colums = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.u_status,
      cell: (row) => (row.u_status ? "Active" : "Inactive"),
    },

    {
      name: "Action",
      selector: (row) => (
        <ul className="d-flex gap-2 list-unstyled mb-0">
          <li>
            <Link
              to="#UnitUpdateModal"
              data-bs-toggle="modal"
              className="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn"
              onClick={() => updateUnit(row.id)}
            >
              <i className="ph-pencil"></i>
            </Link>
          </li>
          <li>
            <Link
              to="#UnitDeleteModal"
              data-bs-toggle="modal"
              className="btn btn-subtle-danger btn-icon btn-sm remove-item-btn"
            >
              <i className="ph-trash"></i>
            </Link>
          </li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    const result =
      unit &&
      unit.filter((data) => {
        const nameMatch = data.name
          .toLowerCase()
          .includes(search.toLowerCase());
        const idMatch = String(data.id).includes(search.toLowerCase());
        return nameMatch || idMatch;
      });
    setFilterUnits(result);
  }, [search, unit]);

  return (
    <DataTable
      columns={colums}
      data={filterUnits}
      pagination
      fixedHeader
      fixedHeaderScrollHeight="450px"
      selectableRows
      selectableRowsHighlight
      highlightOnHover
      subHeader
      subHeaderAlign="left"
      subHeaderComponent={
        <input
          type="text"
          placeholder="Search here"
          className="w-100 form-control"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></input>
      }
    />
  );
};

export default UnitTable;
