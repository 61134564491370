import "./App.css";
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Error from "./Error";
import SignIn from "./dashboard/rootPages/SignIn";
import Top from "./dashboard/components/Top";
import DHome from "./dashboard/pages/Home/DHome";
import ScrollToUp from "./dashboard/components/ScrollToUp";
import Category from "./dashboard/pages/Product/Category";
import Unit from "./dashboard/pages/Product/Unit";
import Products from "./dashboard/pages/Product/Products";
import AddProduct from "./dashboard/pages/Product/AddProduct";
import UpdateProduct from "./dashboard/pages/Product/UpdateProduct";
import Blog from "./dashboard/pages/Blog/Blog";
import BlogAdd from "./dashboard/pages/Blog/BlogAdd";
import BlogUpdate from "./dashboard/pages/Blog/BlogUpdate";
import Orders from "./dashboard/pages/Order/Orders";
import OrderOverview from "./dashboard/pages/Order/OrderOverview";
import OrderAdd from "./dashboard/pages/Order/OrderAdd";
import Purchase from "./dashboard/pages/Purchase/Purchase";
import PurchaseOverview from "./dashboard/pages/Purchase/PurchaseOverview";
import PurchaseAdd from "./dashboard/pages/Purchase/PurchaseAdd";
import Supplier from "./dashboard/pages/Settings/Supplier";
import Customers from "./dashboard/pages/Settings/Customers";
import Courier from "./dashboard/pages/Settings/Courier";
import ManageBalance from "./dashboard/pages/Settings/ManageBalance";
import InvoiceList from "./dashboard/pages/Invoice/InvoiceList";
import InvoiceOverview from "./dashboard/pages/Invoice/InvoiceOverview";
import InvoiceOverview2 from "./dashboard/pages/Invoice/InvoiceOverview2";
import InvoiceAdd from "./dashboard/pages/Invoice/InvoiceAdd";
import Income from "./dashboard/pages/Accounts/Income";
import Expense from "./dashboard/pages/Accounts/Expense";
import FundTransfer from "./dashboard/pages/Accounts/FundTransfer";
import ErrorPage from "./dashboard/ErrorPage";
import DHeader from "./dashboard/components/DHeader";
import DFooter from "./dashboard/components/DFooter";
import DNav from "./dashboard/components/DNav";
import DeliveryType from "./dashboard/pages/Settings/DeliveryType";
import { useApiContext } from "./dashboard/context/ApiContext";
import Home from "./landingPage/pages/Home";
// import { useProductContext } from "./context/ProductContext";
import Footer from "./landingPage/components/footer/Footer";

// const Base_URL = process.env.REACT_APP_BASE_URL;
// const Logout_URL = process.env.REACT_APP_LOGOUT_URL;
// const Logout_API = `${Base_URL}${Logout_URL}`;

function App() {
  const [isActive, setIsActive] = useState(false);

  // const { c_user } = useProductContext();
  // const aT = localStorage.getItem("landing2AccessToken");
  // const rT = localStorage.getItem("landing2RefreshToken");

  // const handleLogout = async () => {
  //   try {
  //     const response = await fetch(Logout_API, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${aT}`,
  //       },
  //       body: JSON.stringify({
  //         refresh_token: rT,
  //       }),
  //     });

  //     const data = await response.json();

  //     console.log("Logout response:", data);
  //   } catch (error) {
  //     console.error("Error logging out:", error);
  //   }
  // };

  const { current_user } = useApiContext();
  const daT = localStorage.getItem("landing2superuserandstaffAccessToken");
  const drT = localStorage.getItem("landing2superuserandstaffRefreshToken");

  const dhandleLogout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGOUT_URL}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${daT}`,
          },
          body: JSON.stringify({
            refresh_token: drT,
          }),
        }
      );
      const data = await response.json();
      console.log("Logout response:", data);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  if (daT) {
    return (
      <BrowserRouter basename="/">
        <Top />

        <div id="layout-wrapper">
          <DNav setIsActive={setIsActive} />

          <div className="vertical-overlay"></div>

          <DHeader
            current_user={current_user}
            dhandleLogout={dhandleLogout}
            isActive={isActive}
            setIsActive={setIsActive}
          />

          <Routes>
            <Route path="/" element={<DHome />} />

            <Route path="/category" element={<Category />} />
            <Route path="/unit" element={<Unit />} />
            <Route path="/products" element={<Products />} />
            <Route path="/product-add" element={<AddProduct />} />
            <Route path="/product-update/:id" element={<UpdateProduct />} />

            <Route path="/blogs" element={<Blog />} />
            <Route path="/blog-add" element={<BlogAdd />} />
            <Route path="/blog-update/:id" element={<BlogUpdate />} />

            <Route path="/orders" element={<Orders />} />
            <Route path="/order-overview/:id" element={<OrderOverview />} />
            <Route path="/order-add" element={<OrderAdd />} />

            <Route path="/purchase" element={<Purchase />} />
            <Route
              path="/purchase-overview/:id"
              element={<PurchaseOverview />}
            />
            <Route path="/purchase-add" element={<PurchaseAdd />} />

            <Route path="/supplier" element={<Supplier />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/courier" element={<Courier />} />
            <Route path="/manage-balance" element={<ManageBalance />} />
            <Route path="/delivery_type" element={<DeliveryType />} />

            <Route path="/income" element={<Income />} />
            <Route path="/expense" element={<Expense />} />
            <Route path="/fund-transfer" element={<FundTransfer />} />

            <Route path="/invoice" element={<InvoiceList />} />
            <Route path="/invoice-overview" element={<InvoiceOverview />} />
            <Route path="/invoice-overview2" element={<InvoiceOverview2 />} />
            <Route path="/invoice-add" element={<InvoiceAdd />} />

            <Route path="*" element={<ErrorPage />} />
          </Routes>

          <DFooter />
        </div>

        <ScrollToUp />
      </BrowserRouter>
    );
  }

  if (!daT) {
    return (
      <BrowserRouter basename="/">
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<SignIn />} />

          <Route path="*" element={<Error />} />
        </Routes>

        <Footer />
        <ScrollToUp bgColor={"#104D29"} />
      </BrowserRouter>
    );
  }
}

export default App;
