import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";

const SupplierTable = ({ unpaginate_supplier, updateSupplier }) => {
  const [search, setSearch] = useState("");
  const [filterSupplier, setFilterSupplier] = useState("");

  const colums = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.s_status,
      cell: (row) => (row.s_status ? "Active" : "Inactive"),
    },
    {
      name: "Address",
      selector: (row) => (
        <p style={{ whiteSpace: "pre-line" }}>{row.address}</p>
      ),
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Email",
      selector: (row) => <p style={{ whiteSpace: "pre-line" }}>{row.email}</p>,
    },
    {
      name: "Logo",
      selector: (row) => (
        <img
          src={row.logo}
          alt="logo"
          height="50"
          width="50"
          style={{ borderRadius: "50%", margin: "10px" }}
        />
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <ul className="d-flex gap-2 list-unstyled mb-0">
          <li>
            <Link
              to="#SupplierUpdateModal"
              data-bs-toggle="modal"
              className="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn"
              onClick={() => updateSupplier(row.id)}
            >
              <i className="ph-pencil"></i>
            </Link>
          </li>
          <li>
            <Link
              to="#SupplierDeleteModal"
              data-bs-toggle="modal"
              className="btn btn-subtle-danger btn-icon btn-sm remove-item-btn"
            >
              <i className="ph-trash"></i>
            </Link>
          </li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    const result =
      unpaginate_supplier &&
      unpaginate_supplier.filter((data) => {
        const nameMatch = data.name
          .toLowerCase()
          .includes(search.toLowerCase());
        const idMatch = String(data.id).includes(search.toLowerCase());
        return nameMatch || idMatch;
      });
    setFilterSupplier(result);
  }, [search, unpaginate_supplier]);

  return (
    <DataTable
      columns={colums}
      data={filterSupplier}
      pagination
      fixedHeader
      fixedHeaderScrollHeight="450px"
      selectableRows
      selectableRowsHighlight
      highlightOnHover
      subHeader
      subHeaderAlign="left"
      subHeaderComponent={
        <input
          type="text"
          placeholder="Search here"
          className="w-100 form-control"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></input>
      }
    />
  );
};

export default SupplierTable;
