import { useEffect } from "react";
import { Link } from "react-router-dom";

const DNav = ({ setIsActive }) => {
  useEffect(() => {
    const className = "vertical-sidebar-enable";
    const handleNavLinkClick = () => {
      document.body.classList.remove(className);
      setIsActive(false);
    };

    const navLinks = document.querySelectorAll(".navlistitems");
    navLinks.forEach((link) => {
      link.addEventListener("click", handleNavLinkClick);
    });

    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("click", handleNavLinkClick);
      });
    };
  }, [setIsActive]);

  return (
    <>
      <div className="app-menu navbar-menu overflow-auto">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src="/img/dash_log.png" alt="logo" height="22" />
            </span>

            <span className="logo-lg">
              <img src="/img/dash_log.png" alt="logo" height="50" />
            </span>
          </Link>

          <button
            type="button"
            className="btn btn-sm p-0 fs-3xl header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>

        <div id="scrollbar">
          <div className="container-fluid">
            <div id="two-column-menu"></div>

            <ul className="navbar-nav" id="navbar-nav">
              {/* menu  */}
              <li className="menu-title">
                <span data-key="t-menu">Menu</span>
              </li>

              <h5 className="navmobileIcon" onClick={() => setIsActive(false)}>
                X
              </h5>

              {/* dashboard  */}
              <li className="nav-item">
                <Link
                  className="nav-link menu-link collapsed navlistitems"
                  to="/"
                  href="#sidebarDashboards"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                >
                  <i className="ph-gauge"></i>
                  <span data-key="t-dashboards">Dashboards</span>
                </Link>
              </li>

              {/* products */}
              <li className="nav-item">
                <Link
                  to="#sidebarProducts"
                  className="nav-link menu-link collapsed"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarProducts"
                >
                  <i className="ph-storefront"></i>
                  <span data-key="t-products">Product</span>
                </Link>

                <div className="collapse menu-dropdown" id="sidebarProducts">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/category"
                        className="nav-link navlistitems"
                        data-key="t-category"
                      >
                        Category
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/unit"
                        className="nav-link navlistitems"
                        data-key="t-unit"
                      >
                        Unit
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/products"
                        className="nav-link navlistitems"
                        data-key="t-products"
                      >
                        List View
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/product-add"
                        className="nav-link navlistitems"
                        data-key="t-create-product"
                      >
                        Create Product
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* orders  */}
              <li className="nav-item">
                <Link
                  to="#sidebarOrder"
                  className="nav-link menu-link collapsed"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarOrder"
                >
                  <i className="ph-storefront"></i>
                  <span data-key="t-order">Order</span>
                </Link>

                <div className="collapse menu-dropdown" id="sidebarOrder">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/orders"
                        className="nav-link navlistitems"
                        data-key="t-orders"
                      >
                        List View
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link
                        to="/order-add"
                        className="nav-link navlistitems"
                        data-key="t-order-overview"
                      >
                        Create Order
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </li>

              {/* purchase  */}
              <li className="nav-item">
                <Link
                  to="#sidebarPurchase"
                  className="nav-link menu-link collapsed"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarPurchase"
                >
                  <i className="ph-shopping-cart"></i>
                  <span data-key="t-purchase">Purchase</span>
                </Link>

                <div className="collapse menu-dropdown" id="sidebarPurchase">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/purchase"
                        className="nav-link navlistitems"
                        data-key="t-purchase-list-view"
                      >
                        List View
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/purchase-add"
                        className="nav-link navlistitems"
                        data-key="t-create-purchase"
                      >
                        Create Purchase
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* invoice  */}
              <li className="nav-item">
                <Link
                  to="#sidebarInvoices"
                  className="nav-link menu-link collapsed navlistitems"
                  // data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarInvoices"
                >
                  <i className="ph-file-text"></i>
                  <span data-key="t-invoices">Invoices</span>
                </Link>

                <div className="collapse menu-dropdown" id="sidebarInvoices">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/invoice"
                        className="nav-link"
                        data-key="t-invoice-list-view"
                      >
                        List View
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/invoice-overview"
                        className="nav-link"
                        data-key="t-invoice-overview"
                      >
                        Overview
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/invoice-overview2"
                        className="nav-link"
                        data-key="t-invoice-overview2"
                      >
                        Overview-2
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/invoice-add"
                        className="nav-link"
                        data-key="t-create-invoice"
                      >
                        Create Invoice
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* accounts  */}
              <li className="nav-item">
                <Link
                  to="#sidebarAccounts"
                  className="nav-link menu-link collapsed navlistitems"
                  // data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarAccounts"
                >
                  <i className="ph-bank"></i>
                  <span data-key="t-accounts">Accounts</span>
                </Link>

                <div className="collapse menu-dropdown" id="sidebarAccounts">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/income"
                        className="nav-link navlistitems"
                        data-key="t-income"
                      >
                        Income
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/expense"
                        className="nav-link navlistitems"
                        data-key="t-expense"
                      >
                        Expense
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/fund-transfer"
                        className="nav-link navlistitems"
                        data-key="t-fund-transfer"
                      >
                        Fund-Transfer
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* settings  */}
              <li className="nav-item">
                <Link
                  to="#sidebarSettings"
                  className="nav-link menu-link collapsed"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarSettings"
                >
                  <i className="ph-gear"></i>
                  <span data-key="t-settings">Settings</span>
                </Link>

                <div className="collapse menu-dropdown" id="sidebarSettings">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/supplier"
                        className="nav-link navlistitems"
                        data-key="t-supplier-list"
                      >
                        Supplier
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/customers"
                        className="nav-link navlistitems"
                        data-key="t-customers"
                      >
                        Customers
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/courier"
                        className="nav-link navlistitems"
                        data-key="t-courier-list"
                      >
                        Courier
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/manage-balance"
                        className="nav-link navlistitems"
                        data-key="t-manage-balance"
                      >
                        Manage-Balance
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/delivery_type"
                        className="nav-link navlistitems"
                        data-key="t-delivery_type"
                      >
                        Delivery Type
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* blogs  */}
              <li className="nav-item">
                <Link
                  to="#sidebarBlog"
                  className="nav-link menu-link collapsed"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarBlog"
                >
                  <i className="ph-storefront"></i>
                  <span data-key="t-blog">Blog</span>
                </Link>

                <div className="collapse menu-dropdown" id="sidebarBlog">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/blogs"
                        className="nav-link navlistitems"
                        data-key="t-blogs"
                      >
                        List View
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/blog-add"
                        className="nav-link navlistitems"
                        data-key="t-blog-add"
                      >
                        Create Blog
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Other  */}
              <li className="menu-title">
                <i className="ri-more-fill"></i>
                <span data-key="t-other">Other</span>
              </li>

              {/* authentication  */}
              <li className="nav-item">
                <Link
                  to="#sidebarAuth"
                  className="nav-link menu-link collapsed navlistitems"
                  // data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarAuth"
                >
                  <i className="ph-user-circle"></i>
                  <span data-key="t-authentication">Authentication</span>
                </Link>

                <div className="collapse menu-dropdown" id="sidebarAuth">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to=""
                        className="nav-link"
                        role="button"
                        data-key="t-signin"
                      >
                        Sign In
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to=""
                        className="nav-link"
                        role="button"
                        data-key="t-signup"
                      >
                        Sign Up
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to=""
                        className="nav-link"
                        role="button"
                        data-key="t-password-reset"
                      >
                        Password Reset
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to=""
                        className="nav-link"
                        role="button"
                        data-key="t-password-create"
                      >
                        Password Create
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to=""
                        className="nav-link"
                        role="button"
                        data-key="t-lock-screen"
                      >
                        Lock Screen
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to=""
                        className="nav-link"
                        role="button"
                        data-key="t-logout"
                      >
                        Logout
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to=""
                        className="nav-link"
                        role="button"
                        data-key="t-success-message"
                      >
                        Success Message
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to=""
                        className="nav-link"
                        role="button"
                        data-key="t-two-step-verification"
                      >
                        Two Step Verification
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="#sidebarErrors"
                        className="nav-link"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="sidebarErrors"
                        data-key="t-errors"
                      >
                        Errors
                      </Link>

                      <div
                        className="collapse menu-dropdown"
                        id="sidebarErrors"
                      >
                        <ul className="nav nav-sm flex-column">
                          <li className="nav-item">
                            <Link
                              to=""
                              className="nav-link"
                              data-key="t-404-error"
                            >
                              404 Error
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link to="" className="nav-link" data-key="t-500">
                              500
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link to="" className="nav-link" data-key="t-503">
                              503
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              to=""
                              className="nav-link"
                              data-key="t-offline-page"
                            >
                              Offline Page
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>

              {/* pages  */}
              <li className="nav-item">
                <Link
                  className="nav-link menu-link collapsed navlistitems"
                  to="#sidebarPages"
                  // data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarPages"
                >
                  <i className="ph-address-book"></i>
                  <span data-key="t-pages">Pages</span>
                </Link>

                <div className="collapse menu-dropdown" id="sidebarPages">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link to="" className="nav-link" data-key="t-starter">
                        Starter
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="" className="nav-link" data-key="t-profile">
                        Profile
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to=""
                        className="nav-link"
                        data-key="t-profile-setting"
                      >
                        Profile Settings
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="" className="nav-link" data-key="t-contacts">
                        Contacts
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="" className="nav-link" data-key="t-timeline">
                        Timeline
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="" className="nav-link" data-key="t-faqs">
                        FAQs
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="" className="nav-link" data-key="t-pricing">
                        Pricing
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="" className="nav-link" data-key="t-maintenance">
                        Maintenance
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="" className="nav-link" data-key="t-coming-soon">
                        Coming Soon
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to=""
                        className="nav-link"
                        data-key="t-privacy-policy"
                      >
                        Privacy Policy
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to=""
                        className="nav-link"
                        data-key="t-term-conditions"
                      >
                        Term & Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          {/* <!-- Sidebar --> */}
        </div>

        <div className="sidebar-background"></div>
      </div>
    </>
  );
};

export default DNav;
