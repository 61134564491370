/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";

const FundTransfer = () => {
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                  <h4 className="mb-sm-0">Fund-Transfer</h4>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row">
              <div className="col-lg-12">
                <div className="card" id="fundList">
                  <div className="card-header">
                    <div className="row align-items-center gy-3">
                      <div className="col-lg-3 col-md-6 order-last order-md-first me-auto">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search for agent, email, address or something..."
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>
                      <div className="col-md-auto col-6 text-end">
                        <div className="d-flex flex-wrap align-items-start gap-2">
                          <button
                            className="btn btn-subtle-danger d-none"
                            id="remove-actions"
                            onClick="deleteMultiple()"
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </button>
                          <button
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#addFund"
                          >
                            <i className="bi bi-person-plus align-baseline me-1"></i>
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                        <thead className="text-muted table-light">
                          <tr>
                            <th>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="option"
                                  id="checkAll"
                                />
                                <label
                                  className="form-check-label"
                                  for="checkAll"
                                ></label>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="fund_transfer_id"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="transfer_date"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="from_account"
                            >
                              From
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="to_account"
                            >
                              To
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="amount"
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="cost"
                            >
                              Cost
                            </th>
                            <th
                              scope="col"
                              className="sort cursor-pointer"
                              data-sort="total_amount"
                            >
                              Total Amount
                            </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="chk_child"
                                />
                                <label className="form-check-label"></label>
                              </div>
                            </td>
                            <td className="fund_transfer_id">
                              <a>TBS01</a>
                            </td>
                            <td className="transfer_date">10 January, 2024</td>
                            <td className="from_account">bkash</td>
                            <td className="to_account">Bank</td>
                            <td className="amount">1000</td>
                            <td className="cost">20</td>
                            <td className="total_amount">
                              <span>1020</span>
                            </td>
                            <td>
                              <ul className="d-flex gap-2 list-unstyled mb-0">
                                <li>
                                  <a
                                    href="apps-real-estate-agent-overview.html"
                                    className="btn btn-subtle-primary btn-icon btn-sm "
                                  >
                                    <i className="ph-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#addAgent"
                                    data-bs-toggle="modal"
                                    className="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn"
                                  >
                                    <i className="ph-pencil"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#deleteRecordModal"
                                    data-bs-toggle="modal"
                                    className="btn btn-subtle-danger btn-icon btn-sm remove-item-btn"
                                  >
                                    <i className="ph-trash"></i>
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                        {/* <!-- end tbody --> */}
                      </table>
                      {/* <!-- end table --> */}
                      <div className="noresult" style={{ display: " none" }}>
                        <div className="text-center py-4">
                          <i className="ph-magnifying-glass fs-1 text-primary"></i>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ agent We did not find
                            any agent for you search.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row mt-3 align-items-center"
                      id="pagination-element"
                    >
                      <div className="col-sm">
                        <div className="text-muted text-center text-sm-start">
                          Showing <span className="fw-semibold">1</span> of{" "}
                          <span className="fw-semibold">1</span> Results
                        </div>
                      </div>
                      {/* <!--end col--> */}
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <div className="pagination-wrap justify-content-center hstack gap-2">
                          <a
                            className="page-item pagination-prev disabled"
                            href="javascript:void(0)"
                          >
                            Previous
                          </a>
                          <ul className="pagination listjs-pagination mb-0"></ul>
                          <a
                            className="page-item pagination-next"
                            href="javascript:void(0)"
                          >
                            Next
                          </a>
                        </div>
                      </div>
                      {/* <!--end col--> */}
                    </div>
                    {/* <!--end row--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- container-fluid --> */}
        </div>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <script>document.write(new Date().getFullYear())</script> ©
                Steex.
              </div>
              <div className="col-sm-6">
                <div className="text-sm-end d-none d-sm-block">
                  Design & Develop by Themesbrand
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default FundTransfer;
