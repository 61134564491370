import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";

const PurchaseTable = ({ purchase }) => {
  const [search, setSearch] = useState("");
  const [filterPurchase, setFilterPurchase] = useState("");

  const colums = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Purchase Number",
      selector: (row) => row.purchase_no,
      sortable: true,
    },
    {
      name: "Invoice Number",
      selector: (row) => row.invoice_no,
      sortable: true,
    },
    {
      name: "Purchase Date",
      selector: (row) => row.purchase_date,
      sortable: true,
    },
    {
      name: "Supplyer name",
      selector: (row) => row.supplier.name,
    },
    {
      name: "Amount",
      selector: (row) => row.total_amount,
    },
    {
      name: "Action",
      selector: (row) => (
        <ul className="d-flex gap-2 list-unstyled mb-0">
          <li>
            <Link
              to={`/purchase-overview/${row.id}`}
              className="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn"
            >
              <i className="ph-eye"></i>
            </Link>
          </li>
          <li>
            <Link
              to="#"
              className="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn"
            >
              <i className="ph-pencil"></i>
            </Link>
          </li>
          <li>
            <Link
              to="#PurchaseDeleteModal"
              data-bs-toggle="modal"
              className="btn btn-subtle-danger btn-icon btn-sm remove-item-btn"
            >
              <i className="ph-trash"></i>
            </Link>
          </li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    const result =
      purchase &&
      purchase.filter((data) => {
        const nameMatch = data.purchase_no
          .toLowerCase()
          .includes(search.toLowerCase());
        const idMatch = String(data.id).includes(search.toLowerCase());
        return nameMatch || idMatch;
      });
    setFilterPurchase(result);
  }, [purchase, search]);

  return (
    <DataTable
      columns={colums}
      data={filterPurchase}
      pagination
      fixedHeader
      fixedHeaderScrollHeight="450px"
      selectableRows
      selectableRowsHighlight
      highlightOnHover
      subHeader
      subHeaderComponent={
        <input
          type="text"
          placeholder="Search here"
          className="w-100 form-control"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></input>
      }
      subHeaderAlign="left"
    />
  );
};

export default PurchaseTable;
