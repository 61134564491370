import JoditEditor from "jodit-react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useApiContext } from "../../context/ApiContext";
import { useRef } from "react";

const ProductForm = ({
  values,
  handleChange,
  touched,
  errors,
  setFieldValue,
  showImage,
  showGalImage,
  setShowImage,
  setShowGalImage,
  limitedFiles,
  setLimitedFiles,
  isSubmitting,
  message,
  isAdd,
  isUpdated,
  content,
  setContent,
}) => {
  const { category, unit } = useApiContext();
  const editor = useRef(null);

  const onGalImageChange = (event) => {
    const selectedFiles = event.target.files;
    const maxFiles = 5;

    // File type validation
    const allowedImageTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/gif",
    ];
    const isValidImage = (file) => allowedImageTypes.includes(file.type);

    // File size validation
    const maxFileSizeInBytes = 5 * 1024 * 1024; // 5MB
    const isFileTooLarge = (file) => file.size > maxFileSizeInBytes;

    const validFilesArray = Array.from(selectedFiles)
      .filter(isValidImage)
      .filter((file) => !isFileTooLarge(file))
      .slice(0, maxFiles);

    // Clear the previous images
    const imageContainer = document.getElementById("image-container");
    imageContainer.innerHTML = "";

    // Optional: If you want to show the first selected image
    setShowGalImage(
      validFilesArray.length > 0
        ? URL.createObjectURL(validFilesArray[0])
        : null
    );

    // Display all selected images
    validFilesArray.forEach((file, index) => {
      const imgContainer = document.createElement("div");
      imgContainer.style.position = "relative";

      const img = document.createElement("img");
      img.src = URL.createObjectURL(file);
      img.alt = `img-${index}`;
      img.style.width = "100px";
      img.style.height = "100px";
      img.style.marginRight = "5px";
      img.style.marginBottom = "5px";
      img.style.borderRadius = "5px";

      const closeButton = document.createElement("p");
      closeButton.innerText = "X";
      closeButton.style.position = "absolute";
      closeButton.style.top = "5px";
      closeButton.style.right = "5px";
      closeButton.style.border = "none";
      closeButton.style.background = "#e74c3c"; // Red background color
      closeButton.style.color = "#fff"; // White text color
      closeButton.style.padding = "5px";
      closeButton.style.borderRadius = "50%";
      closeButton.style.cursor = "pointer";
      closeButton.style.transition = "background 0.3s ease"; // Smooth transition

      // Add hover effect
      closeButton.addEventListener("mouseover", () => {
        closeButton.style.background = "#c0392b"; // Darker red on hover
      });

      closeButton.addEventListener("mouseout", () => {
        closeButton.style.background = "#e74c3c"; // Restore original color on mouseout
      });

      // Attach click event using event delegation
      closeButton.addEventListener("click", (e) => {
        if (e.target === closeButton) {
          handleRemoveImage(index);
        }
      });

      imgContainer.appendChild(img);
      imgContainer.appendChild(closeButton);
      imageContainer.appendChild(imgContainer);
    });

    // Update state with up to 5 selected files
    setLimitedFiles(validFilesArray);
  };

  const handleRemoveImage = (indexToRemove) => {
    const updatedFiles = limitedFiles.filter(
      (_, index) => index !== indexToRemove
    );
    setLimitedFiles(updatedFiles);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setShowImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <>
      <div className="row">
        <Form.Group className="form-outline mb-4 col-lg-12">
          <Form.Label>
            Product Name<span>*</span>
          </Form.Label>
          <InputGroup hasValidation>
            {/* <InputGroup.Text>@</InputGroup.Text> */}
            <Form.Control
              type="text"
              name="name"
              id="name"
              value={values.name}
              onChange={handleChange}
              isInvalid={!!touched.name && !!errors.name}
              isValid={touched.name && !errors.name}
              className="form-control"
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </div>

      <div className="row">
        <Form.Group className="form-outline mb-4 col-lg-4">
          <Form.Label>
            Category<span></span>
          </Form.Label>
          <InputGroup hasValidation>
            {/* <InputGroup.Text>@</InputGroup.Text> */}
            <Form.Select
              name="category"
              id="category"
              value={values.category}
              onChange={handleChange}
              isInvalid={!!touched.category && !!errors.category}
              isValid={touched.category && !errors.category}
              className="form-control"
            >
              <option value="">Select</option>
              {category &&
                category.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.category}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="form-outline mb-4 col-lg-4">
          <Form.Label>
            Status<span></span>
          </Form.Label>
          <InputGroup hasValidation>
            {/* <InputGroup.Text>@</InputGroup.Text> */}
            <Form.Select
              name="p_status"
              id="p_status"
              value={values.p_status}
              onChange={handleChange}
              isInvalid={!!touched.p_status && !!errors.p_status}
              isValid={touched.p_status && !errors.p_status}
              className="form-control"
            >
              <option value="">Select</option>
              <option value={`${true}`}>Active</option>
              <option value={`${false}`}>Inactive</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.p_status}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="form-outline mb-4 col-lg-4">
          <Form.Label>
            Feature<span></span>
          </Form.Label>
          <InputGroup hasValidation>
            {/* <InputGroup.Text>@</InputGroup.Text> */}
            <Form.Select
              name="is_feature"
              id="is_feature"
              value={values.is_feature}
              onChange={handleChange}
              isInvalid={!!touched.is_feature && !!errors.is_feature}
              isValid={touched.is_feature && !errors.is_feature}
              className="form-control"
            >
              <option value="">Select</option>
              <option value={`${true}`}>Active</option>
              <option value={`${false}`}>Inactive</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.is_feature}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </div>

      <div className="row">
        <Form.Group className="form-outline mb-4 col-lg-6">
          <Form.Label>
            Unit<span>*</span>
          </Form.Label>
          <InputGroup hasValidation>
            {/* <InputGroup.Text>@</InputGroup.Text> */}
            <Form.Select
              name="unit"
              id="unit"
              value={values.unit}
              onChange={handleChange}
              isInvalid={!!touched.unit && !!errors.unit}
              isValid={touched.unit && !errors.unit}
              className="form-control"
            >
              <option value="">Select</option>
              {unit &&
                unit.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.unit}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="form-outline mb-4 col-lg-6">
          <Form.Label>
            Initial Stock<span></span>
          </Form.Label>
          <InputGroup hasValidation>
            {/* <InputGroup.Text>@</InputGroup.Text> */}
            <Form.Control
              type="text"
              name="initial_stock"
              id="initial_stock"
              value={values.initial_stock}
              onChange={handleChange}
              isInvalid={!!touched.initial_stock && !!errors.initial_stock}
              isValid={touched.initial_stock && !errors.initial_stock}
              className="form-control"
            />
            <Form.Control.Feedback type="invalid">
              {errors.initial_stock}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </div>

      <div className="row">
        <Form.Group className="form-outline mb-4 col-lg-12">
          <Form.Label>
            Description<span></span>
          </Form.Label>
          <InputGroup hasValidation>
            <JoditEditor
              name="description"
              id="description"
              ref={editor}
              value={content}
              onChange={(newContent) => setContent(newContent)}
            />

            <Form.Control.Feedback type="invalid">
              {errors.description}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </div>

      <div className="row">
        <Form.Group className="form-outline mb-4 col-lg-6">
          <Form.Label>
            Image<span></span>
          </Form.Label>
          <Form.Control
            type="file"
            name="image"
            id="image"
            onChange={(event) => {
              setFieldValue("image", event.currentTarget.files[0]);
              onImageChange(event);
            }}
            isInvalid={!!touched.image && !!errors.image}
            isValid={touched.image && !errors.image}
          />
          <Form.Control.Feedback type="invalid">
            {errors.image}
          </Form.Control.Feedback>

          {showImage && (
            <div
              style={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <img
                alt="img"
                style={{
                  width: "100px",
                  height: "100px",
                  marginRight: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
                src={showImage}
              />
            </div>
          )}
        </Form.Group>

        <Form.Group className="form-outline mb-4 col-lg-6">
          <Form.Label>
            Gallery Images<span></span>
          </Form.Label>
          <Form.Control
            type="file"
            name="gal_image"
            id="gal_image"
            multiple // Allow multiple file selection
            onChange={onGalImageChange}
            isInvalid={!!touched.gal_image && !!errors.gal_image}
            isValid={touched.gal_image && !errors.gal_image}
            disabled={isUpdated}
          />
          <Form.Control.Feedback type="invalid">
            {errors.gal_image}
          </Form.Control.Feedback>

          <div
            id="image-container"
            style={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}
          ></div>
        </Form.Group>
      </div>

      <div className="row">
        <Form.Group className="form-outline mb-4 col-lg-4">
          <Form.Label>
            Buying Price<span>*</span>
          </Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>BDT</InputGroup.Text>
            <Form.Control
              type="text"
              name="buying_price"
              id="buying_price"
              value={values.buying_price}
              onChange={handleChange}
              isInvalid={!!touched.buying_price && !!errors.buying_price}
              isValid={touched.buying_price && !errors.buying_price}
              className="form-control"
            />
            <Form.Control.Feedback type="invalid">
              {errors.buying_price}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="form-outline mb-4 col-lg-4">
          <Form.Label>
            Selling Price<span>*</span>
          </Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>BDT</InputGroup.Text>
            <Form.Control
              type="text"
              name="selling_price"
              id="selling_price"
              value={values.selling_price}
              onChange={handleChange}
              isInvalid={!!touched.selling_price && !!errors.selling_price}
              isValid={touched.selling_price && !errors.selling_price}
              className="form-control"
            />
            <Form.Control.Feedback type="invalid">
              {errors.selling_price}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="form-outline mb-4 col-lg-4">
          <Form.Label>
            Discount<span></span>
          </Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>BDT</InputGroup.Text>
            <Form.Control
              type="text"
              name="discount"
              id="discount"
              value={values.discount}
              onChange={handleChange}
              isInvalid={!!touched.discount && !!errors.discount}
              isValid={touched.discount && !errors.discount}
              className="form-control"
            />
            <Form.Control.Feedback type="invalid">
              {errors.discount}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </div>

      <div className="hstack gap-2 justify-content-end mt-5 mb-2">
        <button type="reset" className="btn btn-danger">
          Cancel
        </button>

        <button
          type="submit"
          className="btn btn-primary"
          disabled={isSubmitting}
        >
          {isAdd && "Add"}
          {isUpdated && "Update"}
        </button>
      </div>

      {/* message  */}
      {message && <h2 className="text-center m-5">{message}</h2>}
    </>
  );
};

export default ProductForm;
