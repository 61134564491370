import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";

const CustomerTable = ({ customer }) => {
  const [search, setSearch] = useState("");
  const [filterCustomer, setFilterCustomer] = useState("");

  const colums = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      cell: (row) => (row.is_active ? "Active" : "Inactive"),
    },
    {
      name: "District",
      selector: (row) => (
        <p style={{ whiteSpace: "pre-line" }}>{row.district}</p>
      ),
    },
    {
      name: "Upazila",
      selector: (row) => (
        <p style={{ whiteSpace: "pre-line" }}>{row.upazila}</p>
      ),
    },
    {
      name: "Address",
      selector: (row) => (
        <p style={{ whiteSpace: "pre-line" }}>{row.address}</p>
      ),
    },
    {
      name: "Phone",
      selector: (row) => row.email,
    },
    {
      name: "Image",
      selector: (row) => (
        <img
          src={row.image}
          alt="logo"
          height="50"
          width="50"
          style={{ borderRadius: "50%", margin: "10px" }}
        />
      ),
    },
  ];

  useEffect(() => {
    const result =
      customer &&
      customer.filter((data) => {
        const nameMatch = data.name
          .toLowerCase()
          .includes(search.toLowerCase());
        const idMatch = String(data.id).includes(search.toLowerCase());
        return nameMatch || idMatch;
      });
    setFilterCustomer(result);
  }, [customer, search]);

  return (
    <DataTable
      columns={colums}
      data={filterCustomer}
      pagination
      fixedHeader
      fixedHeaderScrollHeight="450px"
      selectableRows
      selectableRowsHighlight
      highlightOnHover
      subHeader
      subHeaderAlign="left"
      subHeaderComponent={
        <input
          type="text"
          placeholder="Search here"
          className="w-100 form-control"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></input>
      }
    />
  );
};

export default CustomerTable;
