import React from "react";
import "./cardRing.css";

const CardRing = () => {
  return (
    <div style={{ backgroundColor: "#021D2A" }} className="py-3 my-4">
      <div className="container mx-auto">
        <h2 className="ring text-white text-center">
          ১ টি Diamond Carat Ring With <br></br>
          Artificial Rose
        </h2>
      </div>

      <div className="price">
        <h3 className="text-center">রেগুলার প্রাইজঃ ১০০০ টাকা</h3>
      </div>

      <div className="tk">
        <h5 className="text-center">অফার মূল্য : ৬৫০  টাকা</h5>
      </div>
    </div>
  );
};

export default CardRing;
