import { useEffect, useState } from "react";
import axios from "axios";
import { Formik, Form as FormikForm } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { schema, validate } from "./FormHelpers";
import BlogForm from "./BlogForm";

const BlogUpdate = () => {
  const [item, setItem] = useState({});
  const [showImage, setShowImage] = useState(null);
  const [content, setContent] = useState("");

  const [message, setMessage] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadProducts = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BLOG_URL}${id}/`
      );
      setItem(data);
      setShowImage(data.image);
      setContent(data.description);
    };

    loadProducts();
  }, [id]);

  const updatedValues = {
    title: item.title ? item.title : "",
    b_status: item.b_status ? item.b_status : "",
    subtitle: item.subtitle ? item.subtitle : "",
    description: item.description ? item.description : "",
    image: item.image ? item.image : "",
    author: item.author ? item.author : "",
    pub_date: item.pub_date ? item.pub_date : "",
  };

  const UpdateBlogFunc = async (values) => {
    let formfield = new FormData();

    formfield.append("title", values.title);
    formfield.append("b_status", values.b_status);
    formfield.append("subtitle", values.subtitle);
    formfield.append("description", content);
    formfield.append("author", values.author);
    formfield.append("pub_date", values.pub_date);

    if (values.image !== item.image) {
      formfield.append("image", values.image);
    }

    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BLOG_URL}${id}/`,
      data: formfield,
    })
      .then((response) => {
        setMessage(response.success, "Blog is successfully updated...");
        navigate("/blogs");
        window.location.reload(false);
      })
      .catch((error) => {
        setMessage(error.message, "Error");
      });
  };

  const submitBlogForm = async (values, { setErrors, setSubmitting }) => {
    try {
      UpdateBlogFunc(values);
      setSubmitting(false);
    } catch (error) {
      setErrors({ err: error.message });
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                  <h4 className="mb-sm-0">Update Blog</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <Formik
                        enableReinitialize={true}
                        initialValues={updatedValues}
                        validationSchema={schema}
                        onSubmit={submitBlogForm}
                        validate={validate}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          setFieldValue,
                          isSubmitting,
                          values,
                          errors,
                          touched,
                        }) => (
                          <FormikForm
                            noValidate
                            onSubmit={(e) => handleSubmit(e)}
                          >
                            <BlogForm
                              values={values}
                              handleChange={handleChange}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              showImage={showImage}
                              setShowImage={setShowImage}
                              isSubmitting={isSubmitting}
                              message={message}
                              isUpdated
                              content={content}
                              setContent={setContent}
                            />
                          </FormikForm>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogUpdate;
