import { useState } from "react";
import axios from "axios";
import { Formik, Form as FormikForm } from "formik";
import { useNavigate } from "react-router-dom";
import { initialValues, schema, validate } from "./FormHelpers";
import ProductForm from "./ProductForm";

const AddProduct = () => {
  const [showImage, setShowImage] = useState(null);
  const [showGalImage, setShowGalImage] = useState(null);
  const [limitedFiles, setLimitedFiles] = useState([]);

  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const [content, setContent] = useState("");

  const AddProductFunc = async (values) => {
    let formfield = new FormData();

    formfield.append("name", values.name);
    formfield.append("category", values.category);
    formfield.append("p_status", values.p_status);
    formfield.append("is_feature", values.is_feature);
    formfield.append("unit", values.unit);
    formfield.append("initial_stock", values.initial_stock);
    formfield.append("description", content);
    formfield.append("discount", values.discount);
    formfield.append("buying_price", values.buying_price);
    formfield.append("selling_price", values.selling_price);

    if (values.image) {
      formfield.append("image", values.image);
    }
    if (values.gal_image1) {
      formfield.append("gal_image1", limitedFiles[0]);
    }
    if (values.gal_image2) {
      formfield.append("gal_image2", limitedFiles[1]);
    }
    if (values.gal_image3) {
      formfield.append("gal_image3", limitedFiles[2]);
    }
    if (values.gal_image4) {
      formfield.append("gal_image4", limitedFiles[3]);
    }
    if (values.gal_image5) {
      formfield.append("gal_image5", limitedFiles[4]);
    }

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNP_PRODUCT_URL}`,
      data: formfield,
    })
      .then((response) => {
        setMessage(response.success, "Product is successfuly created...");
        navigate("/products");
        window.location.reload(false);
      })
      .catch((error) => {
        setMessage(error.message, "Error");
      });
  };

  const submitProductForm = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      AddProductFunc(values);
      setSubmitting(false);
      // resetForm();
      setShowImage(null);
      setShowGalImage(null);
    } catch (error) {
      setErrors({ error: error.message });
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                  <h4 className="mb-sm-0 text-dark">Add Product</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={submitProductForm}
                        validate={validate}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          setFieldValue,
                          isSubmitting,
                          values,
                          errors,
                          touched,
                        }) => (
                          <FormikForm
                            noValidate
                            onSubmit={(e) => handleSubmit(e)}
                          >
                            <ProductForm
                              values={values}
                              handleChange={handleChange}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              showImage={showImage}
                              showGalImage={showGalImage}
                              setShowImage={setShowImage}
                              setShowGalImage={setShowGalImage}
                              limitedFiles={limitedFiles}
                              setLimitedFiles={setLimitedFiles}
                              isSubmitting={isSubmitting}
                              message={message}
                              isAdd
                              content={content}
                              setContent={setContent}
                            />
                          </FormikForm>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
