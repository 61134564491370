import React, { useEffect, useState } from "react";

const ScrollToUp = ({ bgColor }) => {
  const [scrolltopdata, setscrolltopdata] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setscrolltopdata("d-block");
      } else {
        setscrolltopdata("d-none");
      }
    });
  }, []);

  const topping = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <button
        onClick={(e) => topping(e)}
        className={`btn btn-dark btn-icon ${scrolltopdata}`}
        id="back-to-top"
        style={{ backgroundColor: bgColor }}
      >
        <i className="bi bi-caret-up fs-3xl"></i>
      </button>
    </>
  );
};

export default ScrollToUp;
