const DeleteBtnModal = ({ id }) => {
  return (
    <>
      <div
        id={id}
        className="modal fade zoomIn"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                id="deleteRecord-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body p-md-5">
              <div className="text-center">
                <div className="text-danger">
                  <i className="bi bi-trash display-4"></i>
                </div>
                <div className="mt-4">
                  <h3 className="mb-2">Are you sure ?</h3>
                  <p className="text-muted fs-lg mx-3 mb-0">
                    Are you sure you want to remove this record ?
                  </p>
                </div>
              </div>

              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button
                  type="button"
                  className="btn w-sm btn-light btn-hover"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn w-sm btn-danger btn-hover"
                  id="delete-record"
                >
                  Yes, Delete It!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteBtnModal;
