import React, { useRef } from "react";
import JoditEditor from "jodit-react";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const BlogForm = ({
  values,
  handleChange,
  touched,
  errors,
  setFieldValue,
  showImage,
  setShowImage,
  isSubmitting,
  message,
  isAdd,
  isUpdated,
  content,
  setContent,
}) => {
  const editor = useRef(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setShowImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <>
      <div className="row">
        <Form.Group className="form-outline mb-4 col-lg-12">
          <Form.Label>
            Blog Title<span>*</span>
          </Form.Label>
          <InputGroup hasValidation>
            {/* <InputGroup.Text>@</InputGroup.Text> */}
            <Form.Control
              type="text"
              name="title"
              id="title"
              value={values.title}
              onChange={handleChange}
              isInvalid={!!touched.title && !!errors.title}
              isValid={touched.title && !errors.title}
              className="form-control"
            />
            <Form.Control.Feedback type="invalid">
              {errors.title}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </div>

      <div className="row">
        <Form.Group className="form-outline mb-4 col-lg-12">
          <Form.Label>
            Blog Subtitle<span>*</span>
          </Form.Label>
          <InputGroup hasValidation>
            {/* <InputGroup.Text>@</InputGroup.Text> */}
            <Form.Control
              type="text"
              name="subtitle"
              id="subtitle"
              value={values.subtitle}
              onChange={handleChange}
              isInvalid={!!touched.subtitle && !!errors.subtitle}
              isValid={touched.subtitle && !errors.subtitle}
              className="form-control"
            />
            <Form.Control.Feedback type="invalid">
              {errors.subtitle}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </div>

      <div className="row">
        <Form.Group className="form-outline mb-4 col-lg-4">
          <Form.Label>
            Status<span></span>
          </Form.Label>
          <InputGroup hasValidation>
            {/* <InputGroup.Text>@</InputGroup.Text> */}
            <Form.Select
              name="b_status"
              id="b_status"
              value={values.b_status}
              onChange={handleChange}
              isInvalid={!!touched.b_status && !!errors.b_status}
              isValid={touched.b_status && !errors.b_status}
              className="form-control"
            >
              <option value="">Select</option>
              <option value={`${true}`}>Active</option>
              <option value={`${false}`}>Inactive</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.b_status}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="form-outline mb-4 col-lg-4">
          <Form.Label>
            Author<span>*</span>
          </Form.Label>
          <InputGroup hasValidation>
            {/* <InputGroup.Text>@</InputGroup.Text> */}
            <Form.Control
              type="text"
              name="author"
              id="author"
              value={values.author}
              onChange={handleChange}
              isInvalid={!!touched.author && !!errors.author}
              isValid={touched.author && !errors.author}
              className="form-control"
            />
            <Form.Control.Feedback type="invalid">
              {errors.author}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="form-outline mb-4 col-lg-4">
          <Form.Label>
            Pulication date<span>*</span>
          </Form.Label>
          <InputGroup hasValidation>
            {/* <InputGroup.Text>@</InputGroup.Text> */}
            <Form.Control
              type="date"
              name="pub_date"
              id="pub_date"
              value={values.pub_date}
              onChange={handleChange}
              isInvalid={!!touched.pub_date && !!errors.pub_date}
              isValid={touched.pub_date && !errors.pub_date}
              className="form-control"
            />
            <Form.Control.Feedback type="invalid">
              {errors.pub_date}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </div>

      <div className="row">
        <Form.Group className="form-outline mb-4 col-lg-6">
          <Form.Label>
            Description<span></span>
          </Form.Label>
          <InputGroup hasValidation>
            <JoditEditor
              name="description"
              id="description"
              ref={editor}
              value={content}
              onChange={(newContent) => setContent(newContent)}
            />

            <Form.Control.Feedback type="invalid">
              {errors.description}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="form-outline mb-4 col-lg-6">
          <Form.Label>
            Image<span></span>
          </Form.Label>
          <Form.Control
            type="file"
            name="image"
            id="image"
            onChange={(event) => {
              setFieldValue("image", event.currentTarget.files[0]);
              onImageChange(event);
            }}
            isInvalid={!!touched.image && !!errors.image}
            isValid={touched.image && !errors.image}
          />
          <Form.Control.Feedback type="invalid">
            {errors.image}
          </Form.Control.Feedback>

          {showImage && (
            <div
              style={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <img
                alt="img"
                style={{
                  width: "100px",
                  height: "100px",
                  marginRight: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
                src={showImage}
              />
            </div>
          )}
        </Form.Group>
      </div>

      <div className="row"></div>

      <div className="hstack gap-2 justify-content-end mt-5 mb-2">
        <button type="reset" className="btn btn-danger">
          Cancel
        </button>

        <button
          type="submit"
          className="btn btn-primary"
          disabled={isSubmitting}
        >
          {isAdd && "Add"}
          {isUpdated && "Update"}
        </button>
      </div>

      {/* message  */}
      {message && <h2 className="text-center m-5">{message}</h2>}
    </>
  );
};

export default BlogForm;
