/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

const InvoiceOverview = () => {
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Invoice Overview</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Invoice</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Invoice Overview
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row justify-content-center">
              <div className="col-xxl-9">
                <div className="hstack gap-2 justify-content-end d-print-none mb-4">
                  <a
                    href="javascript:window.print()"
                    className="btn btn-success"
                  >
                    <i className="ri-printer-line align-bottom me-1"></i> Print
                  </a>
                  <button
                    type="button"
                    id="download-button"
                    className="btn btn-primary"
                  >
                    <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                    Download
                  </button>
                </div>

                <div className="card-body z-1 position-relative">
                  <div className="row">
                    <div className="col-lg-4">
                      <div
                        className="col-4 "
                        style={{
                          border: "2px dashed rgb(167, 167, 167)",
                          padding: "10px",
                          width: "auto",
                          marginLeft: "40px",
                        }}
                      >
                        <h6 className="fs-md" id="name">
                          <span>Name: </span>Jennifer Mayert
                        </h6>
                        <h6 className="fs-md" id="mobileNo">
                          <span>Mobile: </span>012478965
                        </h6>
                        <p className="text-muted mb-1" id="address-line-1">
                          <span>Address: </span>6382 Cerromar Cir, Orangevale,
                          California, US.
                        </p>
                        <p className="text-muted mb-1" id="invoice-no">
                          <span>Invoice No: </span>2437
                        </p>
                        <p className="text-muted mb-1" id="courier">
                          <span>Courier: </span>SteadFast Courier
                        </p>
                      </div>
                    </div>
                    {/* <!--end col--> */}
                    <div className="col-lg-4 ms-auto">
                      <div className="d-flex">
                        <div className="flex-grow-1 mb-2">
                          <img
                            src="assets/images/logo1.jpg"
                            className="card-logo"
                            alt="logo light"
                            height="50"
                          />
                        </div>
                      </div>
                      {/* <!--end col--> */}
                      <div className="col-6">
                        <p className="text-muted mb-1" id="address-line-1">
                          Rebu Vila, House-1081,Khilbarirtek, Vatara, Dhaka-1212{" "}
                        </p>
                        <p className="text-muted mb-1">
                          <span id="mail">www.naturiko.com</span>
                        </p>
                        <p className="text-muted mb-1">
                          <span>Hotline:</span>
                          <span id="phone-no">01329283334, 01329283336</span>
                        </p>
                        <p className="fs-md">
                          <span>Date:</span>
                          <span>06/096/2024</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 pt-4">
                    <div className="row g-3">
                      <div className="col-lg col-6">
                        <p className="text-muted mb-2 text-uppercase">
                          Invoice No
                        </p>
                        <h5 className="fs-md mb-0">
                          #TBS<span id="invoice-no">24301901</span>
                        </h5>
                      </div>
                      {/* <!--end col--> */}
                      <div className="col-lg col-6">
                        <p className="text-muted mb-2 text-uppercase">Date</p>
                        <h5 className="fs-md mb-0">
                          <span id="invoice-date">10 April, 2023</span>
                        </h5>
                      </div>
                      <div className="col-lg col-6">
                        <p className="text-muted mb-2 text-uppercase">
                          Due Date
                        </p>
                        <h5 className="fs-md mb-0">
                          <span id="invoice-due-date">10 April, 2023</span>
                        </h5>
                      </div>
                      {/* <!--end col--> */}
                      <div className="col-lg col-6">
                        <p className="text-muted mb-2 text-uppercase">
                          Payment Status
                        </p>
                        <span
                          className="badge bg-success-subtle text-success fs-xxs"
                          id="payment-status"
                        >
                          Paid
                        </span>
                      </div>
                      {/* <!--end col--> */}
                      <div className="col-lg col-6">
                        <p className="text-muted mb-2 text-uppercase">
                          Total Amount
                        </p>
                        <h5 className="fs-md mb-0">
                          $<span id="total-amount">2,050.18</span>
                        </h5>
                      </div>
                      {/* <!--end col--> */}
                    </div>
                    {/* <!--end row--> */}
                  </div>

                  <div className="table-responsive mt-4">
                    <table className="table table-borderless text-center table-nowrap align-middle mb-0">
                      <thead>
                        <tr className="table-light">
                          <th scope="col" style={{ width: " 50px" }}>
                            #
                          </th>
                          <th scope="col">Product Details</th>
                          <th scope="col">Rate</th>
                          <th scope="col">Quantity</th>
                          <th scope="col" className="text-end">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody id="products-list">
                        <tr>
                          <th scope="row">01</th>
                          <td className="text-start">
                            <span className="fw-medium">Branded T-Shirts</span>
                            <p className="text-muted mb-0">Fashion</p>
                          </td>
                          <td>$161.25</td>
                          <td>03</td>
                          <td className="text-end">$483.75</td>
                        </tr>
                        <tr>
                          <th scope="row">02</th>
                          <td className="text-start">
                            <span className="fw-medium">
                              Fossil gen 5E smart watch
                            </span>
                            <p className="text-muted mb-0">
                              32.5mm (1.28 Inch) TFT Color Touch Display
                            </p>
                          </td>
                          <td>$69.60</td>
                          <td>02</td>
                          <td className="text-end">$139.20</td>
                        </tr>
                        <tr>
                          <th scope="row">03</th>
                          <td className="text-start">
                            <span className="fw-medium">
                              Blive Printed Men Round Neck
                            </span>
                            <p className="text-muted mb-0">Fashion</p>
                          </td>
                          <td>$250.00</td>
                          <td>06</td>
                          <td className="text-end">$1,500.00</td>
                        </tr>
                        <tr>
                          <th scope="row">04</th>
                          <td className="text-start">
                            <span className="fw-medium">
                              Flip-Flops and House Slippers
                            </span>
                            <p className="text-muted mb-0">Footwear</p>
                          </td>
                          <td>$150.00</td>
                          <td>01</td>
                          <td className="text-end">$150.00</td>
                        </tr>
                      </tbody>
                    </table>
                    {/* <!--end table--> */}
                  </div>
                  <div
                    className="border-top border-top-dashed mt-2"
                    id="products-list-total"
                  >
                    <table
                      className="table table-borderless table-nowrap align-middle mb-0 ms-auto"
                      style={{ width: "250px" }}
                    >
                      <tbody>
                        <tr>
                          <td>Sub Total</td>
                          <td className="text-end">$2,272.95</td>
                        </tr>
                        <tr>
                          <td>
                            Estimated Tax{" "}
                            <small className="text-muted">(18%)</small>
                          </td>
                          <td className="text-end">$409.13</td>
                        </tr>
                        <tr>
                          <td>
                            Discount{" "}
                            <small className="text-muted">(STEEX30)</small>
                          </td>
                          <td className="text-end">-$681.88</td>
                        </tr>
                        <tr>
                          <td>Shipping Charge</td>
                          <td className="text-end">$65.00</td>
                        </tr>
                        <tr className="border-top border-top-dashed fs-15">
                          <th scope="row">Total Amount</th>
                          <th className="text-end">$2,050.20</th>
                        </tr>
                      </tbody>
                    </table>
                    {/* <!--end table--> */}
                  </div>

                  <div>
                    <p className="mb-4 pb-2">Thanks for your Order</p>
                  </div>
                </div>

                {/* <!--end card--> */}
              </div>
              {/* <!--end col--> */}
            </div>
          </div>
          {/* <!-- container-fluid --> */}
        </div>
        {/* <!-- End Page-content --> */}
      </div>
    </>
  );
};

export default InvoiceOverview;
