import { Link } from "react-router-dom";
import DeleteBtnModal from "../../components/DeleteBtnModal";
import { useApiContext } from "../../context/ApiContext";
// import { useApiContext } from "../../context/ApiContext";
import OrderTable from "./OrderTable";

import { Formik, Form as FormikForm } from "formik";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import axios from "axios";
import InputGroup from "react-bootstrap/InputGroup";
import { useState } from "react";

const schema = yup.object().shape({
  courier: yup.string(),
  delivery_status: yup.string(),
});

const validate = (values) => {
  let errors = {};

  return errors;
};

const Orders = () => {
  const { order, unpaginate_courier } = useApiContext();

  const [item, setItem] = useState({});
  const [message, setMessage] = useState();

  const updateOrder = async (id) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ORDER_URL}${id}/`
    );
    setItem(data);
  };

  const updatedValues = {
    courier: item.courier ? item.courier.id : "",
    delivery_status: item.delivery_status ? item.delivery_status : "",
  };

  const UpdateOrderFunc = async (values) => {
    let formfield = new FormData();

    formfield.append("courier", values.courier);
    formfield.append("delivery_status", values.delivery_status);

    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ORDER_URL}${item.id}/`,
      data: formfield,
    })
      .then((response) => {
        setMessage(response.success, "Order is successfully updated...");
        window.location.reload(false);
      })
      .catch((error) => {
        setMessage(error.message, "Error");
      });
  };

  const submitUpdateOrderForm = async (
    values,
    { setErrors, setSubmitting }
  ) => {
    try {
      UpdateOrderFunc(values);
      setSubmitting(false);
    } catch (error) {
      setErrors({ err: error.message });
    }
  };

  const newOrder = order && order.filter((o) => o.delivery_status === "New");
  const pendingOrder =
    order && order.filter((o) => o.delivery_status === "Pending");
  const approvedOrder =
    order && order.filter((o) => o.delivery_status === "Approved");
  const packagingOrder =
    order && order.filter((o) => o.delivery_status === "Packaging");
  const shipmentOrder =
    order && order.filter((o) => o.delivery_status === "Shipment");
  const deliveredOrder =
    order && order.filter((o) => o.delivery_status === "Delivered");
  const returnOrder =
    order && order.filter((o) => o.delivery_status === "Return");
  const cancelOrder =
    order && order.filter((o) => o.delivery_status === "Cancel");
  const wholesaleOrder =
    order && order.filter((o) => o.delivery_status === "Wholesale");

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <div className="mx-3 my-2 page-title-box d-sm-flex align-items-center justify-content-start">
                  <h4 className="mb-sm-0">Order</h4>
                </div>
              </div>

              <div className="col-6">
                <div className="d-flex mx-3 my-2  align-items-center justify-content-end">
                  <Link to="/order-add">
                    <button className="btn btn-secondary mb-4">
                      <i className="bi bi-plus-circle align-baseline me-1"></i>{" "}
                      Add Order
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row row-cols-xxl-5 row-cols-lg-5 row-cols-md-2 row-cols-1">
              <div className="col">
                <div className="card border-bottom border-3 card-animate border-primary">
                  <div className="card-body">
                    <h4 className="mb-4">
                      <span
                        className="counter-value text-dark"
                        data-target="5963"
                      >
                        {newOrder.length}
                      </span>
                    </h4>

                    <p className="text-muted fw-medium text-uppercase mb-0">
                      New
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-bottom border-3 card-animate border-warning">
                  <div className="card-body">
                    <h4 className="mb-4">
                      <span
                        className="counter-value text-dark"
                        data-target="4620"
                      >
                        {pendingOrder.length}
                      </span>
                    </h4>

                    <p className="text-muted fw-medium text-uppercase mb-0">
                      Pending
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-bottom border-3 card-animate border-warning">
                  <div className="card-body">
                    <h4 className="mb-4">
                      <span
                        className="counter-value text-dark"
                        data-target="4620"
                      >
                        {approvedOrder.length}
                      </span>
                    </h4>

                    <p className="text-muted fw-medium text-uppercase mb-0">
                      Approved
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-bottom border-3 card-animate border-warning">
                  <div className="card-body">
                    <h4 className="mb-4">
                      <span
                        className="counter-value text-dark"
                        data-target="4620"
                      >
                        {packagingOrder.length}
                      </span>
                    </h4>

                    <p className="text-muted fw-medium text-uppercase mb-0">
                      Packaging
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-bottom border-3 card-animate border-warning">
                  <div className="card-body">
                    <h4 className="mb-4">
                      <span
                        className="counter-value text-dark"
                        data-target="4620"
                      >
                        {shipmentOrder.length}
                      </span>
                    </h4>

                    <p className="text-muted fw-medium text-uppercase mb-0">
                      Shipment
                    </p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card border-bottom border-3 card-animate border-success">
                  <div className="card-body">
                    <h4 className="mb-4">
                      <span
                        className="counter-value text-dark"
                        data-target="8541"
                      >
                        {deliveredOrder.length}
                      </span>
                    </h4>

                    <p className="text-muted fw-medium text-uppercase mb-0">
                      Delivered
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-bottom border-3 card-animate border-warning">
                  <div className="card-body">
                    <h4 className="mb-4">
                      <span
                        className="counter-value text-dark"
                        data-target="4620"
                      >
                        {returnOrder.length}
                      </span>
                    </h4>

                    <p className="text-muted fw-medium text-uppercase mb-0">
                      Return
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-bottom border-3 card-animate border-danger">
                  <div className="card-body">
                    <h4 className="mb-4">
                      <span
                        className="counter-value text-dark"
                        data-target="2314"
                      >
                        {cancelOrder.length}
                      </span>
                    </h4>

                    <p className="text-muted fw-medium text-uppercase mb-0">
                      Cancel
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-bottom border-3 card-animate border-warning">
                  <div className="card-body">
                    <h4 className="mb-4">
                      <span
                        className="counter-value text-dark"
                        data-target="4620"
                      >
                        {wholesaleOrder.length}
                      </span>
                    </h4>

                    <p className="text-muted fw-medium text-uppercase mb-0">
                      Wholesale
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-bottom border-3 card-animate border-secondary">
                  <div className="card-body">
                    <h4 className="mb-4">
                      <span
                        className="counter-value text-dark"
                        data-target="21438"
                      >
                        {order.length}
                      </span>
                    </h4>

                    <p className="text-muted fw-medium text-uppercase mb-0">
                      Total
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <OrderTable order={order} updateOrder={updateOrder} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="OrderUpdateModal"
        tabIndex="-1"
        aria-labelledby="OrderUpdateModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <Formik
              enableReinitialize={true}
              initialValues={updatedValues}
              validationSchema={schema}
              onSubmit={submitUpdateOrderForm}
              validate={validate}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                isSubmitting,
              }) => (
                <FormikForm noValidate onSubmit={(e) => handleSubmit(e)}>
                  <div className="modal-header">
                    <h5 className="modal-title" id="OrderUpdateModalLabel">
                      Update Order
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-addAgentModal"
                    ></button>
                  </div>

                  <div className="modal-body">
                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Courier<span>*</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Select
                          name="courier"
                          id="courier"
                          value={values.courier}
                          onChange={handleChange}
                          isInvalid={!!touched.courier && !!errors.courier}
                          isValid={touched.courier && !errors.courier}
                          className="form-control"
                        >
                          <option value="">Select</option>
                          {unpaginate_courier &&
                            unpaginate_courier.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.payment_method}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="form-outline mb-4">
                      <Form.Label>
                        Delivery Status<span>*</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                        <Form.Select
                          name="delivery_status"
                          id="delivery_status"
                          value={values.delivery_status}
                          onChange={handleChange}
                          isInvalid={
                            !!touched.delivery_status &&
                            !!errors.delivery_status
                          }
                          isValid={
                            touched.delivery_status && !errors.delivery_status
                          }
                          className="form-control"
                        >
                          <option value="">Select</option>
                          <option value="New">New</option>
                          <option value="Pending">Pending</option>
                          <option value="Approved">Approved</option>
                          <option value="Packaging">Packaging</option>
                          <option value="Shipment">Shipment</option>
                          <option value="Delivered">Delivered</option>
                          {/* <option value="Return">Return</option>
                          <option value="Cancel">Cancel</option>
                          <option value="Wholesale">Wholesale</option> */}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.delivery_status}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </div>

                  <div className="modal-footer">
                    <div className="hstack gap-2 justify-content-end">
                      <button type="reset" className="btn btn-danger">
                        Cancel
                      </button>

                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        Update
                      </button>
                    </div>
                  </div>

                  {/* message  */}
                  {message && <h2 className="text-center m-5">{message}</h2>}
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <DeleteBtnModal id={`OrderDeleteModal`} />
    </>
  );
};

export default Orders;
