import { useEffect, useState } from "react";
import axios from "axios";
import { Formik, Form as FormikForm } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { schema, validate } from "./FormHelpers";
import ProductForm from "./ProductForm";

const UpdateProduct = () => {
  const [item, setItem] = useState({});
  const [showImage, setShowImage] = useState(null);
  const [showGalImage, setShowGalImage] = useState(null);
  const [limitedFiles, setLimitedFiles] = useState([]);
  const [content, setContent] = useState("");

  const [message, setMessage] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadProducts = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNP_PRODUCT_URL}${id}/`
      );
      setItem(data);
      setShowImage(data.image);
      setShowGalImage(data.gal_image);
      setContent(data.description);
    };

    loadProducts();
  }, [id]);

  const updatedValues = {
    name: item.name ? item.name : "",
    category: item.category ? item.category : "",
    p_status: item.p_status ? item.p_status : "",
    is_feature: item.is_feature ? item.is_feature : "",
    unit: item.unit ? item.unit : "",
    initial_stock: item.initial_stock ? item.initial_stock : "",
    description: item.description ? item.description : "",

    image: item.image ? item.image : "",
    gal_image1: item.gal_image1 ? item.gal_image1 : "",
    gal_image2: item.gal_image2 ? item.gal_image2 : "",
    gal_image3: item.gal_image3 ? item.gal_image3 : "",
    gal_image4: item.gal_image4 ? item.gal_image4 : "",
    gal_image5: item.gal_image5 ? item.gal_image5 : "",

    discount: item.discount ? item.discount : "",
    buying_price: item.buying_price ? item.buying_price : "",
    selling_price: item.selling_price ? item.selling_price : "",
  };

  const UpdateProductFunc = async (values) => {
    let formfield = new FormData();

    formfield.append("name", values.name);
    formfield.append("category", values.category);
    formfield.append("p_status", values.p_status);
    formfield.append("is_feature", values.is_feature);
    formfield.append("unit", values.unit);
    formfield.append("initial_stock", values.initial_stock);
    formfield.append("description", content);

    formfield.append("discount", values.discount);
    formfield.append("buying_price", values.buying_price);
    formfield.append("selling_price", values.selling_price);

    if (values.image !== item.image) {
      formfield.append("image", values.image);
    }
    // if (values.gal_image1 !== item.gal_image1) {
    //   formfield.append("gal_image1", limitedFiles && limitedFiles[0]);
    // }
    // if (values.gal_image2 !== item.gal_image2) {
    //   formfield.append("gal_image2", limitedFiles && limitedFiles[1]);
    // }
    // if (values.gal_image3 !== item.gal_image3) {
    //   formfield.append("gal_image3", limitedFiles && limitedFiles[2]);
    // }
    // if (values.gal_image4 !== item.gal_image4) {
    //   formfield.append("gal_image4", limitedFiles && limitedFiles[3]);
    // }
    // if (values.gal_image5 !== item.gal_image5) {
    //   formfield.append("gal_image5", limitedFiles && limitedFiles[4]);
    // }

    await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNP_PRODUCT_URL}${id}/`,
      data: formfield,
    })
      .then((response) => {
        setMessage(response.success, "Product is successfully updated...");
        navigate("/products");
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        setMessage(error.message, "Error");
      });
  };

  const submitProductForm = async (values, { setErrors, setSubmitting }) => {
    try {
      UpdateProductFunc(values);
      setSubmitting(false);
    } catch (error) {
      setErrors({ err: error.message });
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                  <h4 className="mb-sm-0 text-dark">Update Product</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <Formik
                        enableReinitialize={true}
                        initialValues={updatedValues}
                        validationSchema={schema}
                        onSubmit={submitProductForm}
                        validate={validate}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          setFieldValue,
                          isSubmitting,
                          values,
                          errors,
                          touched,
                        }) => (
                          <FormikForm
                            noValidate
                            onSubmit={(e) => handleSubmit(e)}
                          >
                            <ProductForm
                              values={values}
                              handleChange={handleChange}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              showImage={showImage}
                              showGalImage={showGalImage}
                              setShowImage={setShowImage}
                              setShowGalImage={setShowGalImage}
                              limitedFiles={limitedFiles}
                              setLimitedFiles={setLimitedFiles}
                              isSubmitting={isSubmitting}
                              message={message}
                              isUpdated
                              content={content}
                              setContent={setContent}
                            />
                          </FormikForm>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProduct;
